
//NOT USING THIS FILE ANYMORE...
export const PICTURE_BUTTONS = {
    photoBrowser: 'photoBrowser',
    pictureFromFile: 'pictureFromFile',
    stockImages: 'stockImages',
    onlinePictures: 'onlinePictures'
}

export const FILE_BUTTONS = {
    newDocument: 'newDocument',
    newFromTemplate: 'newFromTemplate',
    open: 'opne',
    openRecent: 'openRecent',
    close: 'close',
    save: 'save', 
    saveAs: 'saveAs'
}


//PPT
export const  SLIDE_BUTTONS = {
    newSlide: 'newSlide',
    titleSlide: 'titleSlide',
    titleAndContent: 'titleAndContent',
    sectionHeader: 'sectionHeader',
}

export const  SLIDE_LAYOUT_BUTTONS = {
    layoutTitleSlide: 'layoutTitleSlide',
    layoutTitleAndContent: 'layoutTitleAndContent',
    layoutSectionHeader: 'layoutSectionHeader'
}