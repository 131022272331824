import { SKILLSETS, FINAL_STEP } from '../../utils';
import { TABBAR_BUTTONS } from '../../../components/Emulators/Windows/button_names';

const fileRightClickMenu = {
    id: 'RenameFileContextMenu',
    kls: 'q2RightClickMenu',
    buttons: [
        {
            name: 'Open',
            top: -2,
        },
        {
            name: 'NewVideo',
            top: -2,
        },
        {
            name: 'EditWithPhotos',
            top: -2,
        },
        {
            name: 'Paint3D',
            top: -2,
        },
        {
            name: 'DesktopBg',
            top: -2,
        },
        {
            name: 'Edit',
            top: -2,
        },
        {
            name: 'Print',
            top: -2,
        },
        {
            name: 'Skype',
            top: -2,
        },
        {
            name: 'RotateRight',
            top: 0,
        },
        {
            name: 'RotateLeft',
            top: 0,
        },
        {
            name: 'Cast',
            top: 7,
        },
        {
            name: 'Scan',
            top: -2,
        },
        {
            name: 'Share',
            top: -2,
        },
        {
            name: 'OpenWith',
            top: -2,
        },
        {
            name: 'OpenMac',
            top: -2,
        },
        {
            name: 'ShowInFinder',
            top: -2,
        },
        {
            name: 'ShareOn',
            top: -2,
        },
        {
            name: 'Restore',
            top: -2,
        },
        {
            name: 'Send to',
            top: 7,
        },
        {
            name: 'Cut',
            top: 4,
        },
        {
            name: 'Copy',
            top: 0,
        },
        {
            name: 'Shortcut',
            top: 5,
        },
        {
            name: 'Delete',
            top: 0,
        },
        {
            name: 'RightClickRename',
            top: -2,
        },
        {
            name: 'Properties',
            top: -2,
        },
    ]
}

export const q2 = {
    orderid: 2,
    emulator: { type: 'windows', name: 'Microsoft Windows' },
    skillset: SKILLSETS.windows,
    instructions: ['Without opening the image, rename the image "truck" to "recycle"'],
    copyTexts: [
        { label: 'Image name', text: 'recycle' }
    ],
    hasMyComputer: true,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'TruckFileSelected',
                    nextId: 2
                },
                {
                    name: 'RightClickRename',
                    nextId: 3
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q2/t2-start.png',
                    style: { t: 0, l: 0, w: 980, h: 641 }
                },
            ],
            buttons: [
                {
                    name: 'File 1',
                    style: { t: 41, l: 185, w: 114, h: 150 }
                },
                {
                    name: 'File 2',
                    style: { t: 41, l: 312, w: 114, h: 160 }
                },
                {
                    name: 'File 3',
                    style: { t: 41, l: 444, w: 114, h: 150 }
                },
                {
                    name: 'TruckFileSelected',
                    style: { t: 41, l: 573, w: 130, h: 142 },
                    contextMenu: fileRightClickMenu
                },
            ],
        },
        {
            id: 2,
            routes: [
                {
                    name: 'RightClickRename',
                    nextId: 3,
                },
                {
                    name: TABBAR_BUTTONS.rename,
                    nextId: 3
                }
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q2/t2-selected.png',
                    style: { t: 0, l: 0, w: 980, h: 641 }
                },
            ],
            buttons: [
                {
                    name: 'File 1',
                    style: { t: 41, l: 185, w: 114, h: 150 }
                },
                {
                    name: 'File 2',
                    style: { t: 41, l: 312, w: 114, h: 160 }
                },
                {
                    name: 'File 3',
                    style: { t: 41, l: 444, w: 114, h: 150 }
                },
                {
                    name: 'RightClickMenu',
                    style: { t: 41, l: 573, w: 130, h: 142 },
                    contextMenu: fileRightClickMenu
                },
            ],
        },
        {
            id: 3,
            routes: [
                {
                    name: 'recycle',
                    nextId: 4,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q2/t2-form.png',
                    style: { t: 0, l: 0, w: 980, h: 641 }
                },
            ],
            buttons: [
                {
                    name: 'File 1',
                    style: { t: 53, l: 185, w: 114, h: 150 }
                },
                {
                    name: 'File 2',
                    style: { t: 53, l: 312, w: 114, h: 150 }
                },
                {
                    name: 'File 3',
                    style: { t: 53, l: 444, w: 114, h: 150 }
                },
            ],
            form: {
                name: 'recycle',
                type: 'content',
                style: { t: 161, l: 595, w: 140, h: 26 },
                inputs: [
                    {
                        check_value: 'recycle',
                        initial_value: 'truck',
                        name: 'fileName',
                        style: { t: 3, l: 10, w: 69, h: 17 },
                        hasBlur: true
                    },
                ],
            }
        },
        {
            name: FINAL_STEP,
            id: 4,
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q2/t2-result.png',
                    style: { t: 0, l: 0, w: 980, h: 641 }
                }
            ],
        },
    ],
};