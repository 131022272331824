import { SKILLSETS, FINAL_STEP } from '../../utils';
import { BROWSER_BUTTONS } from '../../../components/Emulators/Chrome/button_names';

export const q2 = {
    orderid: 2,
    emulator: { type: 'chrome', name: 'Chrome' },
    skillset: SKILLSETS.chrome,
    instructions: ['Without using the keyboard select the already open tab that displays the Republic Services Customer Support page.'],
    attempts: 2,
    steps: [
        {
            id: 1,
            buttons: [
                {
                    type: 'appbar',
                    name: 'Switch',
                    linkHoverClass:'tabSwitch',
                    style: { t: 12, l: 240, w: 198, h: 22},
                },
                {
                    type: 'appbar',
                    name: 'Close tab 2',
                    style: { t: 12, l: 440, w: 22, h: 22, radius: '50%' },
                },
                {
                    type: 'appbar',
                    name: BROWSER_BUTTONS.newTab,
                    style: { t: 12, l: 476, w: 22, h: 22, radius: '50%' },
                },
        
            ],
            routes: [
                {
                    name: 'Switch',
                    nextId: 2,
                }
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q2/browser-t2-1.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
            ]
        },

        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q2/browser-t2-2.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                }
            ]
        },
    ],
};