import React, { Fragment } from 'react';
import AppBarDropdown from '../../AppBarDropdown';
import BookmarkDropdown from './Components/BookmarkDropdown';
import { useForm } from 'react-hook-form';

import { WINDOW_ITEMS } from './Elements/window_items';
import { MAIN_MENU } from './Elements/main_menu';
import { BOOKMARK_MENU } from './Elements/bookmark_menu';

import AppFrameImg from '../../../images/chrome/chrome_app_frame.jpg';
import ImageFadeIn from 'react-image-fade-in';

import { Menu, Item, useContextMenu, Separator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import cx from 'classnames';
import styles from './Chrome.module.scss';
import { elStyle } from '../../../constants/utils';

const Chrome = ({ step, handleClick, handleForm }) => {

    const onElClick = btn => {
        handleClick(btn);
    }
    
    const { register, handleSubmit } = useForm();

    const { show } = useContextMenu();
    const displayContextMenu = (e, buttonId) => {
        show(e, {
            id: buttonId,
            position: {
                x: e.pageX,
                y: e.pageY - 50,
            },
        });
    }

    return (
        <div className={styles.app}>
            <ImageFadeIn className={styles.appBg} src={AppFrameImg} opacityTransition={0.1} alt='Appframe' />
            <ul className='appbar-links'>
                {WINDOW_ITEMS.map((button, i) => {
                    return (
                        <li key={`window-link-${i}`} className='appbar-link' >
                            <button
                                name={button.name}
                                onClick={() => onElClick(button)}
                                className='img-btn-circle'
                                style={elStyle(button)}
                            ></button>
                        </li>
                    )
                })}
            </ul>

            <ul className='appbar-links'>
                {BOOKMARK_MENU && (
                    <li className='appbar-link' >
                        <BookmarkDropdown
                            item={BOOKMARK_MENU.find(b => b.type === step.bookMarkType) || BOOKMARK_MENU[0]}
                            classNames='img-btn'
                            step={step}
                            onElClick={onElClick}
                            elStyles={elStyle(BOOKMARK_MENU[0])}
                            handleForm={handleForm}
                        />
                    </li>
                )}
                {MAIN_MENU.map((item, i) => {
                    return (
                        <li key={`appbar-link-${i}`} className='appbar-link' >
                            {item.mainButtons ? (
                                <AppBarDropdown
                                    item={item}
                                    classNames='img-btn'
                                    onElClick={onElClick}
                                    styles={elStyle(item)}
                                />
                            ) : <button
                                name={item.name}
                                onClick={() => onElClick(item)}
                                className='img-btn'
                                style={elStyle(item)}
                            ></button>}

                        </li>
                    )
                })}
            </ul>

            {step.buttons && step.buttons.length > 0 && step.buttons.filter(btn => btn.type === 'appbar').map((button, i) => {
                return <button
                    key={`step-buttons-${i}`}
                    name={button.name}
                    onClick={() => onElClick(button)}
                    className={cx('img-btn', styles[button.linkHoverClass])}
                    style={elStyle(button)}
                ></button>
            })}

            <div className={styles.appContentArea}>
                {step.images && step.images.filter(img => img.type === 'content').map((image, i) => (
                    <div key={`start_images-${i}`}>
                        <img style={elStyle(image)} src={image.src} alt='' />
                    </div>
                ))}

                {step.buttons && step.buttons.length > 0 && step.buttons.filter(btn => btn.type === 'content').map((button, i) => {
                    return (
                        <Fragment key={`content-btn-${i}`}>
                            {
                                button.dropdown ? (
                                    <AppBarDropdown
                                        item={button.dropdown}
                                        classNames={cx('img-btn', styles.circleBtn)}
                                        onElClick={onElClick}
                                        styles={elStyle(button.dropdown)}
                                    />
                                ) : <button
                                    name={button.name}
                                    onClick={() => onElClick(button)}
                                    className={cx('abs-link-no-hover', styles[button.linkHoverClass])}
                                    style={elStyle(button)}
                                    {...(button.contextMenu && { onContextMenu: (e) => displayContextMenu(e, button.contextMenu.id) })}
                                ></button>
                            }

                            {button.contextMenu && button.contextMenu.buttons && (
                                <Menu id={button.contextMenu.id}>
                                    {button.contextMenu.buttons.map((button, i) => {
                                        return (
                                            <Fragment key={`ctx-${i}`}>
                                                <Item onClick={() => onElClick(button)}>{button.label}</Item>
                                                {button.showSeparator && <Separator />}
                                            </Fragment>
                                        )
                                    })}
                                </Menu>
                            )}
                        </Fragment>
                    );
                })}
            </div>


            {step.form && step.form.type === 'content' && step.form.inputs && step.form.inputs.length > 0 && (
                <form
                    onSubmit={handleSubmit(data => handleForm(data, step.form))}
                    className='form-area'
                    style={elStyle(step.form)}
                >
                    {step.form.inputs.map((input, i) => (
                        <input
                            key={`form-input-${i}`}
                            type="text"
                            style={elStyle(input)}
                            placeholder={input.placeholder}
                            {...register(input.name)}
                            name={input.name}
                            className='abs-pos'
                            defaultValue={input.initial_value}
                        />
                    ))}
                    {step.form.button && <button type='submit' className={cx('img-btn')}
                        style={elStyle(step.form.button)}></button>}
                </form>
            )}
        </div>
    );
}

export default Chrome;