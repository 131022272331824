import { SKILLSETS, FINAL_STEP } from '../../utils';

export const q3 = {
    orderid: 3,
    emulator: { type: 'windows', name: 'Microsoft Windows' },
    skillset: SKILLSETS.windows,
    instructions: [
        'Without manually dragging the window, Maximize the current folder window so it fills the entire screen (enter fullscreen mode).'
    ],
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'Maximize',
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    kls: 'abs-pos',
                    src: './simulator/windows/q3/t3-start.png',
                    style: { t: 50, l: 83, w: 813, h: 521 }
                }
            ],
            buttons: [
                {
                    name: 'Minimize',
                    style: { t: 54, l: 775, w: 20, h: 20 }
                },
                {
                    name: 'Maximize',
                    style: { t: 54, l: 818, w: 20, h: 20 }
                },
                {
                    name: 'Close',
                    style: { t: 54, l: 862, w: 20, h: 20 }
                },
                {
                    name: 'Expand',
                    style: { t: 79, l: 854, w: 20, h: 20 }
                },
                {
                    name: 'Help',
                    style: { t: 79, l: 875, w: 20, h: 20 }
                },
                {
                    name: 'File',
                    style: { t: 78, l: 83, w: 56, h: 22 }
                },
                {
                    name: 'Home',
                    style: { t: 78, l: 140, w: 52, h: 22 }
                },
                {
                    name: 'Share',
                    style: { t: 78, l: 193, w: 49, h: 22 }
                },
                {
                    name: 'View',
                    style: { t: 78, l: 243, w: 49, h: 22 }
                },
                {
                    name: 'Refresh',
                    style: { t: 106, l: 525, w: 30, h: 26 }
                },
                {
                    name: 'FolderChange',
                    style: { t: 106, l: 495, w: 30, h: 26 }
                },
                {
                    name: 'Back',
                    style: { t: 106, l: 89, w: 30, h: 26 }
                },
                {
                    name: 'Up',
                    style: { t: 106, l: 158, w: 30, h: 26 }
                },
                {
                    name: 'FolderName',
                    style: { t: 106, l: 189, w: 132, h: 26 }
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/shared/folder-fs-start.png',
                    style: { t: 0, l: 0, w: 980, h: 687 }
                }
            ],
        },
    ],
};