import DeleteDropdown from '../../../../images/excel/tabs/menu-cells-delete.png';
import DataFormatsDropdown from '../../../../images/excel/tabs/menu-data-formats.png';
import CurrencyFormatsDropdown from '../../../../images/excel/tabs/menu-currency-formats.png';
import SearchDropdown from '../../../../images/excel/tabs/menu-search.png';

import { HOME_BUTTONS, DELETE_BUTTONS, DATA_FORMAT_BUTTONS, SEARCH_BUTTONS } from '../button_names';

export const home_buttons = [
    {
        name: HOME_BUTTONS.paste,
        style: { t: 7, l: 10, w: 40, h: 60 },
    },
    {
        name: HOME_BUTTONS.copyFormat,
        style: { t: 52, l: 50, w: 22, h: 20 },
    },
    {
        name: HOME_BUTTONS.bold,
        style: { t: 44, l: 100, w: 20, h: 20 },
    },
    {
        name: HOME_BUTTONS.italic,
        style: { t: 44, l: 124, w: 20, h: 20 },
    },
    {
        name: HOME_BUTTONS.underline,
        style: { t: 44, l: 146, w: 20, h: 20 },
    },
    {
        name: HOME_BUTTONS.increaseFont,
        style: { t: 15, l: 258, w: 20, h: 20 },
    },
    {
        name: HOME_BUTTONS.decreaseFont,
        style: { t: 15, l: 280, w: 20, h: 20 },
    },

    {
        name: HOME_BUTTONS.fontColor,
        style: { t: 44, l: 265, w: 30, h: 20 },
    },
    {
        name: HOME_BUTTONS.alignToTop,
        style: { t: 6, l: 314, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.alignToMiddle,
        style: { t: 6, l: 336, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.alignToBottom,
        style: { t: 6, l: 359, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.wrapText,
        style: { t: 6, l: 382, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.alignLeft,
        style: { t: 29, l: 314, w: 22, h: 22 },
    },
    {
        name: HOME_BUTTONS.centerText,
        style: { t: 29, l: 336, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.alignRight,
        style: { t: 29, l: 359, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.mergeCentre,
        style: { t: 29, l: 382, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.insert,
        style: { t: 7, l: 700, w: 70, h: 20 },
    },
    {
        name: HOME_BUTTONS.delete,
        style: { t: 29, l: 700, w: 80, h: 20 },
        dropdown: {
            image: {
                style: { t: 50, l: 700, w: 175, h: 121 },
                src: DeleteDropdown
            },
            buttons: [
                {
                    name: DELETE_BUTTONS.deleteColumn,
                    style: { t: 5, l: 5, w: 165, h: 20 },
                },
                {
                    name: DELETE_BUTTONS.deleteColumn,
                    style: { t: 64, l: 5, w: 165, h: 20 },
                },
                {
                    name: DELETE_BUTTONS.deleteSheet,
                    style: { t: 94, l: 5, w: 165, h: 20 },
                },
            ],
        }
    },
    {
        name: HOME_BUTTONS.format,
        style: { t: 50, l: 700, w: 70, h: 20 },
    },
    {
        name: HOME_BUTTONS.dataFormat,
        style: { t: 8, l: 433, w: 79, h: 20 },
        dropdown: {
            image: {
                style: { t: 28, l: 433, w: 184, h: 539 },
                src: DataFormatsDropdown
            },
            buttons: [
                {
                    name: DATA_FORMAT_BUTTONS.general,
                    style: { t: 4, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.number,
                    style: { t: 50, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.currency,
                    style: { t: 95, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.accounting,
                    style: { t: 143, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.shortDate,
                    style: { t: 190, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.longDate,
                    style: { t: 235, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.time,
                    style: { t: 280, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.percentage,
                    style: { t: 326, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.fraction,
                    style: { t: 372, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.scientific,
                    style: { t: 420, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.text,
                    style: { t: 465, l: 2, w: 181, h: 40 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.moreOpts,
                    style: { t: 513, l: 2, w: 197, h: 22 },
                },
            ]
        }
    },
    {
        name: HOME_BUTTONS.currencyFormat,
        style: { t: 30, l: 433, w: 33, h: 20 },
        dropdown: {
            image: {
                style: { t: 52, l: 433, w: 200, h: 173 },
                src: CurrencyFormatsDropdown
            },
            buttons: [
                {
                    name: DATA_FORMAT_BUTTONS.accounting,
                    style: { t: 4, l: 2, w: 197, h: 22 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.pound,
                    style: { t: 33, l: 2, w: 197, h: 22 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.euro,
                    style: { t: 60, l: 2, w: 197, h: 22 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.yuan,
                    style: { t: 90, l: 2, w: 197, h: 22 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.franc,
                    style: { t: 116, l: 2, w: 197, h: 22 },
                },
                {
                    name: DATA_FORMAT_BUTTONS.moreOpts,
                    style: { t: 147, l: 2, w: 197, h: 22 },
                },
            ],
        }
    },
    {
        name: HOME_BUTTONS.conditionalF,
        style: { t: 7, l: 526, w: 161, h: 20 },
    },
    {
        name: HOME_BUTTONS.formatAsTable,
        style: { t: 29, l: 526, w: 120, h: 20 },
    },
    {
        name: HOME_BUTTONS.cellStyles,
        style: { t: 50, l: 526, w: 92, h: 20 },
    },
    {
        name: HOME_BUTTONS.search,
        style: { t: 28, l: 826, w: 33, h: 20 },
        dropdown: {
            image: {
                style: { t: 48, l: 700, w: 179, h: 316 },
                src: SearchDropdown
            },
            buttons: [
                {
                    name: SEARCH_BUTTONS.find,
                    style: { t: 5, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.replace,
                    style: { t: 32, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.goto,
                    style: { t: 60, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.gotoSpecial,
                    style: { t: 88, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.formulas,
                    style: { t: 118, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.notes,
                    style: { t: 147, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.conditional,
                    style: { t: 174, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.constants,
                    style: { t: 202, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.dataValidation,
                    style: { t: 230, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.selectObjects,
                    style: { t: 261, l: 2, w: 176, h: 22 },
                },
                {
                    name: SEARCH_BUTTONS.selectionPane,
                    style: { t: 290, l: 2, w: 176, h: 22 },
                },
            ],
        }
    }

]