
import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';
import { elStyle } from '../../../../constants/utils';
import { FILE_BUTTONS } from '../button_names';
import styles from '../Word.module.scss';
import SelectFileType from './SelectFileType';
import Dialog from './Dialog';
import { useEffectOnce } from 'react-use';

const FileWindow = ({ fileMenu, setActiveToolbar, onElClick, handleForm, step, fileType, setFileType }) => {

    const [subMenu, setSubMenu] = useState(1);

    const onSideButtonClick = (e, button, i) => {
        if (button.name === FILE_BUTTONS.back) {
            setActiveToolbar(1);
        } else if (button.subMenu) {
            setSubMenu(i);
        } else {
            onElClick(button)
        }
    }

    const { register, handleSubmit } = useForm();

    useEffectOnce(() => {
        preloadImages();
    });

    const preloadImages = () => {
        const menusWithImages = fileMenu.mainButtons && fileMenu.mainButtons.filter(m => m.subMenu && m.subMenu.image);
        let count = 0;
        menusWithImages.forEach(menu => {
            const img = new Image();
            img.src = menu.subMenu.image;
            img.onload = () => {
                count = count + 1;
                if (count === menusWithImages.length) {
                  // console.log('loaded submenu images');
                }
            }
        })
    }

    return (
        <div className='toolbar-content toolbar-content-full-screen'>
            {
                fileMenu.mainButtons && fileMenu.mainButtons.map((button, i) => {
                    return (
                        <Fragment key={`toolbarbtn-${i}`}>
                            <button
                                name={button.name}
                                onClick={(e) => onSideButtonClick(e, button, i)}
                                className={cx('img-btn')}
                                style={elStyle(button)}></button>
                            <div className={cx('sub-menu-window-bg', { 'u-display-none': i !== subMenu })}>
                                {fileMenu.mainButtons[subMenu].subMenu && <img src={fileMenu.mainButtons[subMenu].subMenu.image} alt='' />}
                            </div>
                        </Fragment>
                    )
                })
            }

            {fileMenu.mainButtons[subMenu].subMenu &&
                <div className='xx_sub_menu'>
                    <ul className='file-sub-menu-actions'>
                        {fileMenu.mainButtons[subMenu].name === 'Save As' && step.form && step.form.type === 'fileSaveAs' && (
                            <form
                                onSubmit={handleSubmit(data => handleForm(data, step.form, fileType))}
                                className='form-area'
                                style={elStyle(step.form)}
                                name={step.form.name}
                            >
                                {step.form.inputs.map((input, i) => (
                                    <input
                                        key={`form-input-${i}`}
                                        type="text"
                                        onFocus={(e) => e.target.select()}
                                        style={elStyle(input)}
                                        placeholder={input.placeholder}
                                        name={input.name}
                                        {...register(input.name)}
                                        className='abs-pos'
                                        defaultValue={input.initial_value}
                                    />
                                ))}
                                {step.form.button && <button type='submit' name={step.form.button.name} className={cx('img-btn')}
                                    style={elStyle(step.form.button)}></button>}
                            </form>
                        )}

                        {fileMenu.mainButtons[subMenu].subMenu.buttons && fileMenu.mainButtons[subMenu].subMenu.buttons.map((button, i) => {
                            return (
                                <li key={`sub-menu-btn-${i}`}>
                                    {button.dropdown ?
                                        <SelectFileType
                                            item={button.dropdown}
                                            classNames={cx('img-btn', styles.selectFileType)}
                                            styles={elStyle(button)}
                                            fileType={fileType}
                                            setFileType={setFileType}
                                        /> : button.dialog ?
                                            <Dialog
                                                button={button}
                                                classNames='img-btn'
                                                onElClick={onElClick}
                                                styles={elStyle(button)}
                                                handleForm={handleForm}
                                                step={step}
                                                fileType={fileType}
                                                setFileType={setFileType}
                                            /> :
                                            <button
                                                name={button.name}
                                                onClick={() => onElClick(button)}
                                                className='img-btn'
                                                style={elStyle(button)}></button>
                                    }

                                </li>
                            )
                        })}
                    </ul>
                </div>
            }
        </div>

    )
}

export default FileWindow;