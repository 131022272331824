import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import WINDOW_ITEMS from './Elements/window_items';
import MYCOMPUTER_ITEMS from './Elements/mycomputer_items';
import TABBAR_ITEMS from './Elements/tabbar_items';

import StartMenuDropDown from './Components/StartMenuDropdown';

import AppFrameImg from '../../../images/windows/app_frame.png';
import ImageFadeIn from 'react-image-fade-in';

import { Menu, Item, useContextMenu, Submenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import cx from 'classnames';
import styles from './Windows.module.scss';
import { elStyle } from '../../../constants/utils';

const Windows = ({ step, question, handleClick, handleForm, handleChange }) => {

    const [activeToolbar, setActiveToolbar] = useState(null);

    const onElClick = (btn, trackPrevValues) => {
        handleClick(btn, trackPrevValues);
    }

    const { register, handleSubmit } = useForm();


    const toolBarRef = useRef(null);
    const handleClickOutside = (event) => {
        if (toolBarRef.current && !toolBarRef.current.contains(event.target)) {
            setActiveToolbar(null);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const { show } = useContextMenu();
    const displayContextMenu = (e, buttonId) => {
        show(e, {
            id: buttonId,
            position: {
                x: e.pageX,
                y: e.pageY - 50,
            },
        });
    }

    return (
        <div className={styles.app}>
            <ImageFadeIn className={styles.appBg} src={AppFrameImg} opacityTransition={0.1} alt='Appframe' />
            {question.hasMyComputer && (
                <div ref={toolBarRef} className={styles.winToolbar}>
                    {
                        TABBAR_ITEMS.map((tab, i) => {
                            return (
                                <Fragment key={`tab-${i}`}>
                                    <button
                                        name={tab.name}
                                        onClick={() => { i === 0 ? onElClick(tab) : setActiveToolbar(i === activeToolbar ? null : i) }}
                                        className={cx('img-btn')}
                                        style={elStyle(tab)}
                                    ></button>
                                    <img
                                        key={`toolbar-${i}`}
                                        className={cx([styles.winToolbarBg], { 'u-display-none': i !== activeToolbar })}
                                        src={tab.image}
                                        alt=''
                                    />
                                </Fragment>
                            )
                        })
                    }
                    {activeToolbar &&
                        <div className='toolbar-content'>
                            {
                                TABBAR_ITEMS[activeToolbar].mainButtons && TABBAR_ITEMS[activeToolbar].mainButtons.map((button, i) => {
                                    return (
                                        <button
                                            key={`toolbarbtn-${i}}`}
                                            name={button.name}
                                            onClick={() => { onElClick(button); setActiveToolbar(null) }}
                                            className={button.name === 'FakeButton' ? 'abs-btn-no-hover' : 'img-btn'}
                                            style={elStyle(button)}></button>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            )}

            <ul className={styles.windowsMenu}>
                {WINDOW_ITEMS.map((button, i) => {
                    return (
                        <Fragment key={`windows-btn-${i}}`}>
                            {button.dropdown ? (
                                <StartMenuDropDown
                                    item={button}
                                    classNames='img-btn'
                                    onElClick={onElClick}
                                    styles={elStyle(button)}
                                />
                            ) : <button
                                name={button.name}
                                onClick={() => onElClick(button)}
                                className='img-btn'
                                style={elStyle(button)}></button>
                            }
                        </Fragment>
                    )
                })}
            </ul>

            {question.hasMyComputer && <div>
                {MYCOMPUTER_ITEMS.map((button, i) => {
                    return (
                        <Fragment key={`computer-btn-${i}`}>
                            {button.dropdown ? (
                                <div></div>
                            ) : <button
                                name={button.name}
                                onClick={() => onElClick(button)}
                                className='img-btn'
                                style={elStyle(button)}></button>}
                        </Fragment>
                    )
                })}
            </div>}


            <div className={cx(styles.appContentArea, { [styles.appContentAreaMyComputer]: question.hasMyComputer })}>
                {step.images && step.images.filter(img => img.type === 'content').map((image, i) => (
                    <div key={`start_images-${i}`}>
                        <img className={image.kls} style={elStyle(image)} src={image.src} alt='' />
                    </div>
                ))}

                {step.form && step.form.type === 'content' && step.form.inputs && step.form.inputs.length > 0 && (
                    <form
                        onSubmit={handleSubmit(data => handleForm(data, step.form))}
                        className={cx('form-area', styles.centerFormInput)}
                        style={elStyle(step.form)}
                    >
                        {step.form.inputs.map((input, i) => (
                            <input
                                key={`form-input-${i}`}
                                type="text"
                                style={elStyle(input)}
                                autoFocus
                                placeholder={input.placeholder}
                                {...register(input.name, {
                                    onChange: (e) => input.hasBlur && handleChange(e.target.value),
                                    onBlur: (e) => {
                                        if (input.hasBlur) {
                                            if (!e.relatedTarget || (e.relatedTarget && !e.relatedTarget.classList.contains('jsIgnoreTarget'))) {
                                                handleSubmit(data => handleForm(data, step.form))();
                                            }
                                        }
                                    }
                                })}
                                name={input.name}
                                className='abs-pos'
                                defaultValue={input.initial_value}
                                onKeyDown={(e) => {
                                    if (input.hasBlur && e.keyCode === 13) {
                                        e.target.blur();
                                    }
                                }}
                                onFocus={(e) => {e.target.select()}}
                            />
                        ))}
                    </form>
                )}

                {step.buttons && step.buttons.map((button, i) => {
                    return (
                        <Fragment key={`step-btn-${i}`}>
                            <button
                                name={button.name}
                                onClick={() => onElClick(button)}
                                className={cx('img-btn', [styles[button.kls]])}
                                style={elStyle(button)}
                                {...(button.contextMenu && { onContextMenu: (e) => displayContextMenu(e, button.contextMenu.id) })}
                            ></button>
                            {button.contextMenu && button.contextMenu.buttons && (
                                <Menu className={cx(styles[button.contextMenu.kls])} id={button.contextMenu.id}>
                                    {button.contextMenu.buttons.map((button, i) => {
                                        return (
                                            <Fragment key={`ctx-${i}`}>
                                                {button.submenu ? (
                                                    <Submenu style={{ marginTop: `${button.top}px` }} className={cx(button.subMenuKls)} label=''>
                                                        {button.submenu.map((button, i) => (
                                                            <Item key={`ctx-sub-${i}`} style={{ marginTop: `${button.top}px` }} className='outLookSubItem' onClick={() => onElClick(button)}></Item>
                                                        ))}
                                                    </Submenu>
                                                ) : (
                                                    <Item style={{ marginTop: `${button.top}px` }} className='outLookItem' onClick={() => onElClick(button)}></Item>
                                                )}
                                            </Fragment>
                                        )
                                    })}
                                </Menu>
                            )}
                        </Fragment>
                    )
                })}
            </div>
        </div>
    );
}

export default Windows;