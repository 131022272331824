import { SKILLSETS, FINAL_STEP } from '../../utils';

export const q4 = {
    orderid: 4,
    emulator: { type: 'outlook', name: 'Microsoft Outlook' },
    skillset: SKILLSETS.outlook,
    instructions: [
        'Attach the image file "truck" to the current draft email message.',
        'The file should be attached to the email not displayed inline with the text.',
        'The file can be found in the ‘Pictures’ folder.'
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'BrowseThisPc',
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'fullScreen',
                    src: './simulator/outlook/q4/t4-message.png',
                    style: { t: 0, l: 0, w: 980, h: 727 }
                },
            ],
            buttons: [
                {
                    name: 'AttachDropDown',
                    type: 'fullScreenBtn',
                    dropdown: {
                        style: { t: 66, l: 10, w: 106, h: 30, zIndex: 11 },
                        image: {
                            src: './simulator/outlook/q4/menu-attach-file.png',
                            style: { t: 94, l: 10, w: 274, h: 280 }
                        },
                        mainButtons: [
                            {
                                name: 'PresenterFile',
                                style: { t: 34, l: 0, w: 274, h: 40 },
                            },
                            {
                                name: '2048File',
                                style: { t: 38, l: 0, w: 274, h: 40 },
                            },
                            {
                                name: 'AboutFile',
                                style: { t: 44, l: 0, w: 274, h: 40 },
                            },
                            {
                                name: 'BrowseWebLocations',
                                style: { t: 65, l: 0, w: 274, h: 40 },
                            },
                            {
                                name: 'BrowseThisPc',
                                style: { t: 70, l: 0, w: 274, h: 40 },
                            },
                        ]
                    }
                },
                {
                    name: 'Signature',
                    type: 'fullScreenBtn',
                    style: { t: 66, l: 120, w: 100, h: 30, zIndex: 11 },
                },
                {
                    name: 'Table',
                    type: 'fullScreenBtn',
                    style: { t: 66, l: 230, w: 80, h: 30, zIndex: 11 },
                },
                {
                    name: 'Pictures',
                    type: 'fullScreenBtn',
                    dropdown: {
                        style: { t: 66, l: 321, w: 94, h: 30, zIndex: 11 },
                        image: {
                            src: './simulator/outlook/q4/menu-pictures.png',
                            style: { t: 94, l: 321, w: 144, h: 87 }
                        },
                        mainButtons: [
                            {
                                name: 'Pictures',
                                style: { t: 5, l: 0, w: 143, h: 22 },
                            },
                            {
                                name: 'StockImages',
                                style: { t: 10, l: 0, w: 143, h: 22 },
                            },
                            {
                                name: 'OnlinePictures',
                                style: { t: 15, l: 0, w: 143, h: 22 },
                            },
                        ]
                    }
                },
                {
                    name: 'Shapes',
                    type: 'fullScreenBtn',
                    style: { t: 66, l: 418, w: 85, h: 30, zIndex: 11 },
                },
                {
                    name: 'Icons',
                    type: 'fullScreenBtn',
                    style: { t: 66, l: 510, w: 65, h: 30, zIndex: 11 },
                },
                {
                    name: '3Dmodels',
                    type: 'fullScreenBtn',
                    style: { t: 66, l: 580, w: 115, h: 30, zIndex: 11 },
                },
                {
                    name: 'Link',
                    type: 'fullScreenBtn',
                    style: { t: 66, l: 710, w: 75, h: 30, zIndex: 11 },
                },
                {
                    name: 'Send',
                    type: 'fullScreenBtn',
                    style: { t: 116, l: 17, w: 56, h: 72, zIndex: 11 },
                },
            ]
        },
        {
            id: 2,
            routes: [
                {
                    name: 'TRUCK',
                    nextId: 3,
                },
            ],
            images: [
                {
                    type: 'fullScreen',
                    src: './simulator/outlook/q4/t4-message.png',
                    style: { t: 0, l: 0, w: 980, h: 727 }
                },
                {
                    type: 'fullScreen',
                    kls: 'dialogShadow',
                    src: './simulator/outlook/q4/dialogue-insert-picture.png',
                    style: { t: 20, l: 20, w: 585, h: 423 }
                }
            ],
            buttons: [
                {
                    name: 'CameraRollFile',
                    type: 'fullScreenBtn',
                    style: { t: 145, l: 190, w: 88, h: 18, zIndex:11 }
                },
                {
                    name: 'PhotosLibrary',
                    type: 'fullScreenBtn',
                    style: { t: 164, l: 190, w: 165, h: 18, zIndex:11 }
                },
                {
                    name: 'SavedPictures',
                    type: 'fullScreenBtn',
                    style: { t: 185, l: 190, w: 99, h: 18, zIndex:11 }
                },
                {
                    name: 'TRUCK',
                    type: 'fullScreenBtn',
                    style: { t: 204, l: 190, w: 53, h: 18, zIndex:11 }
                },
            ],

        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'fullScreen',
                    src: './simulator/outlook/q4/t4-result.png',
                    style: { t: 0, l: 0, w: 980, h: 727 }
                },
            ],
        },
    ],
};