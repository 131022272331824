import React, { useEffect, useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import ls from 'local-storage';

import { FORCED_LOGOUT, ERROR } from '../services/graphql';
import ErrorPage from '../../../components/ErrorPage';
import { AuthContext } from '../../../services/auth/AuthProvider';

const RouteCapture = ({ children }) => {

    const client = useApolloClient();
    
    const { data: fl } = useQuery(FORCED_LOGOUT);
    const { data: clientError } = useQuery(ERROR);

    const { setCurrentUser } = useContext(AuthContext);

    const hostname = window?.location?.hostname;
    const appUrl = () => {
        if (hostname !== "localhost" && !hostname.includes("staging")) {
            return "https://my.cuttingedge.app";
        }
        if (hostname === "localhost") {
            return "http://localhost:3000";
        }
        return "https://staging-my.cuttingedge.app";
    }

    const forceLogout = () => {
        ls.clear();
        client.clearStore();
        setCurrentUser(null);
        window.location.href = appUrl();
    }

    useEffect(() => {
        if (ls('ce_candidate_user') && ls('ce_candidate_user') === 'undefined') {
            forceLogout();
        }
    });

    if (fl && fl.forcedLogout) {
        forceLogout();
    }

    if (clientError && clientError.error) {
        return <ErrorPage />
    }

    return children || null;
}

export default RouteCapture;