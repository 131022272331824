import { SKILLSETS, FINAL_STEP } from '../../utils';
import { BROWSER_BUTTONS } from '../../../components/Emulators/Chrome/button_names';

export const q3 = {
    orderid: 3,
    emulator: { type: 'chrome', name: 'Chrome' },
    skillset: SKILLSETS.chrome,
    instructions: ['On the current webpage open the "Pickup Schedule" link in a new tab.'],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'OpenInNewTab',
                    nextId: 2,
                }
            ],
            buttons: [
                {
                    id: 1,
                    type: 'appbar',
                    name: BROWSER_BUTTONS.newTab,
                    style: { t: 12, l: 248, w: 22, h: 22, radius: '50%' },
                },
                {
                    id: 4,
                    type: 'content',
                    name: 'OrderServices',
                    linkHoverClass: 'q3BorderHover',
                    style: { t: 184, l: 105, w: 160, h: 150 },
                },
                {
                    id: 5,
                    type: 'content',
                    name: 'PickupSchedule',
                    linkHoverClass: 'q3BorderHover',
                    style: { t: 184, l: 390, w: 180, h: 150 },
                    contextMenu: {
                        id: 'PickupScheduleContextMenu',
                        buttons: [
                            {
                                name: 'OpenInNewTab',
                                label: 'Open link in a new tab'
                            },
                            {
                                name: 'OpenInNewWindow',
                                label: 'Open link in a new window'
                            },
                            {
                                name: 'OpenInIncognitoWindow',
                                label: 'Open link in incognito window',
                                showSeparator: true,
                            },
                            {
                                name: 'SaveLinkAs',
                                label: 'Save Link as...'
                            },
                            {
                                name: 'CopyLinkAddress',
                                label: 'Copy link address',
                                showSeparator: true,
                            },
                            {
                                name: 'Inspect',
                                label: 'Inspect'
                            },
                        ],
                    }
                },
                {
                    id: 6,
                    type: 'content',
                    name: 'SchedulePickup',
                    linkHoverClass: 'q3BorderHover',
                    style: { t: 184, l: 688, w: 180, h: 150 },
                },
                {
                    id: 7,
                    type: 'content',
                    name: 'MissedPickup',
                    linkHoverClass: 'q3BorderHover',
                    style: { t: 440, l: 105, w: 160, h: 150 },
                },
                {
                    id: 8,
                    type: 'content',
                    name: 'RecyclingGuide',
                    linkHoverClass: 'q3BorderHover',
                    style: { t: 440, l: 390, w: 180, h: 150 },
                },
                {
                    id: 9,
                    type: 'content',
                    name: 'FindAFacility',
                    linkHoverClass: 'q3BorderHover',
                    style: { t: 440, l: 688, w: 180, h: 150 },
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q3/browser-t3-1.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q3/browser-t3-2.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
            ]
        },
    ],
};