import { SKILLSETS, FINAL_STEP } from '../../utils';
import { FILE_TYPE_BUTTONS } from '../../../components/Emulators/Word/button_names';

export const q1 = {
    orderid: 1,
    emulator: { type: 'word', name: 'Microsoft Word' },
    skillset: SKILLSETS.word,
    instructions: [
        'Without using keyboard shortcuts save a new copy of the current document.',
        'The file should be saved on the desktop with the file name: report-1.docx',
    ],
    attempts: 2,
    copyTexts: [
        { label: 'File name', text: 'report-1' }
    ],
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'Browse', // Browse Btn Click in SaveAs Page
                    nextId: 2,
                },
                {
                    name: 'SaveFile', // Form in SaveAs Page
                    nextId: 3,
                },
            ],
            has_prev_selection: {
                type: 'file_type',
                value: FILE_TYPE_BUTTONS.docx
            },
            images: [
                {
                    type: 'content',
                    src: './simulator/word/shared/content_start.png',
                    style: { t: 0, l: 0, w: 606, h: 490 }
                },
            ],
            form: {
                style: { t: 132, l: 505, w: 320, h: 80 },
                name: 'SaveFile',
                type: 'fileSaveAs',
                inputs: [
                    {
                        check_value: 'report-1',
                        initial_value: 'about-us',
                        name: 'fileName',
                        style: { t: 5, l: 0, w: 320, h: 22 },
                    },
                ],
                button: {
                    name: 'SaveFileBtn',
                    style: { t: 26, l: 342, w: 82, h: 26 },
                },
            },
        },
        {
            id: 2,
            has_prev_selection: {
                type: 'file_type',
                value: FILE_TYPE_BUTTONS.docx
            },
            routes: [
                {
                    name: 'SaveFile',
                    nextId: 3,
                }
            ],
            form: {
                style: { t: 271, l: 119, w: 420, h: 80 },
                name: 'SaveFile',
                type: 'fileSaveAsDialog',
                inputs: [
                    {
                        check_value: 'report-1',
                        initial_value: 'about-us',
                        name: 'fileName',
                        style: { t: 5, l: 0, w: 400, h: 22 },
                    },
                ],
                button: {
                    name: 'SaveFileBtn',
                    style: { t: 143, l: 272, w: 82, h: 22 },
                },
            }
        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'content',
                    src: './simulator/word/shared/content_start.png',
                    style: { t: 100, l: 100, w: 464, h: 157 }
                }
            ],
        },
    ],
};