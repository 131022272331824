import { SKILLSETS, FINAL_STEP } from '../../utils';
import { TABBAR_BUTTONS } from '../../../components/Emulators/Windows/button_names';

export const q1 = {
    orderid: 1,
    emulator: { type: 'windows', name: 'Microsoft Windows' },
    skillset: SKILLSETS.windows,
    instructions: [
        'Without using any keyboard cheats, create a new folder in the current directory.',
        'You should give the folder the name: Press Kit'
    ],
    copyTexts: [
        { label: 'Folder name', text: 'Press Kit' }
    ],
    hasMyComputer: true,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: TABBAR_BUTTONS.newFolder,
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q1/t1-start.png',
                    style: { t: 0, l: 0, w: 980, h: 641 }
                },
            ],
            buttons: [
                {
                    name: 'File 1',
                    style: { t: 53, l: 185, w: 114, h: 150 }
                },
                {
                    name: 'File 2',
                    style: { t: 53, l: 312, w: 114, h: 150 }
                },
                {
                    name: 'File 3',
                    style: { t: 53, l: 444, w: 114, h: 150 }
                },
                {
                    name: 'File 5',
                    style: { t: 53, l: 579, w: 122, h: 150 }
                },
                {
                    name: 'RightClickMenu',
                    style: { t: 203, l: 185, w: 790, h: 400 },
                    kls: 'genericRightClick',
                    contextMenu: {
                        id: 'NewFolderContextMenu',
                        kls: 'q1RightClickMenu',
                        buttons: [
                            {
                                name: 'View',
                                top: -2,
                            },
                            {
                                name: 'SortBy',
                                top: -2,
                            },
                            {
                                name: 'GroupBy',
                                top: -2,
                            },
                            {
                                name: 'Refresh',
                                top: -2,
                            },
                            {
                                name: 'CustomizeFolder',
                                top: 0,
                            },
                            {
                                name: 'New',
                                top: 59,
                                subMenuKls: 'newFolderSubmenu',
                                submenu: [
                                    {
                                        name: TABBAR_BUTTONS.newFolder,
                                        top: -2
                                    },
                                    {
                                        name: 'Shortcut',
                                        top: -2
                                    },
                                    {
                                        name: 'MSAccess',
                                        top: -2
                                    },
                                    {
                                        name: 'BitmapImage',
                                        top: -2
                                    },
                                    {
                                        name: 'Word',
                                        top: -2
                                    },
                                    {
                                        name: 'MsAccess',
                                        top: -2
                                    },
                                    {
                                        name: 'Publisher',
                                        top: -2
                                    },
                                ]
                            },
                            {
                                name: 'Properties',
                                top: 5,
                            },
                        ]
                    }
                },

            ],
        },
        {
            id: 2,
            routes: [
                {
                    name: 'Press Kit',
                    nextId: 3,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q1/t1-form.png',
                    style: { t: 0, l: 0, w: 980, h: 641 }
                },
            ],
            buttons: [
                {
                    name: 'File 1',
                    style: { t: 53, l: 185, w: 114, h: 150 }
                },
                {
                    name: 'File 2',
                    style: { t: 53, l: 312, w: 114, h: 150 }
                },
                {
                    name: 'File 3',
                    style: { t: 53, l: 444, w: 114, h: 150 }
                },
                {
                    name: 'File 4',
                    style: { t: 53, l: 579, w: 122, h: 150 }
                },
            ],
            form: {
                name: 'Press Kit',
                type: 'content',
                style: { t: 161, l: 728, w: 140, h: 26 },
                inputs: [
                    {
                        check_value: 'Press Kit',
                        initial_value: 'New folder',
                        name: 'foldername',
                        style: { t: 3, l: 10, w: 69, h: 17 },
                        hasBlur: true
                    },
                ],
            }
        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q1/t1-result.png',
                    style: { t: 0, l: 0, w: 980, h: 641 }
                }
            ],
        },
    ],
};