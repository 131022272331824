import React, { useState } from 'react';
import styles from './Toast.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Copy } from 'react-feather';

const Toast = ({ copyTexts }) => {

    const [copied, setCopied] = useState(null);

    return (
        <div className={styles.toast}>
            <ul className={styles.copyList}>
                {copyTexts.map((el, i) => {
                    return <li key={`text-${i}`}>
                        <div className={styles.copyListLabel}>{el.label}:</div>
                        <div>
                            {el.text}
                            <CopyToClipboard text={el.text} onCopy={() => setCopied(i)}>
                                <button className='u-m-left-1 jsIgnoreTarget'>{copied === i ? "Copied!" : <Copy size="16" />}</button>
                            </CopyToClipboard>
                        </div>
                    </li>
                })}
            </ul>
        </div>
    );
}

export default Toast;
