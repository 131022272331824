import { SKILLSETS, FINAL_STEP } from '../../utils';
import { fullScreenEmailButtons } from './shared';
import { CONTENT_BUTTONS, } from '../../../components/Emulators/Outlook/button_names';

export const q3 = {
    orderid: 3,
    emulator: { type: 'outlook', name: 'Microsoft Outlook' },
    skillset: SKILLSETS.outlook,
    instructions: ['Compose and send a new email using the details below:'],
    copyTexts: [
        { label: 'To', text: 'sales@republicservices.com' },
        { label: 'Subject', text: 'Latest Figures' },
        { label: 'Message', text: 'Please can you send me the latest sales report?' },
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'Email',
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/shared/email-default.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
            ],
            buttons: [
                {
                    type: 'content',
                    name: CONTENT_BUTTONS.replyPreview,
                    style: { t: 40, l: 826, w: 30, h: 30 }
                },
                {
                    type: 'content',
                    name: CONTENT_BUTTONS.replyAllPreview,
                    style: { t: 40, l: 856, w: 30, h: 30 }
                },
                {
                    type: 'content',
                    name: CONTENT_BUTTONS.fwdPreview,
                    style: { t: 40, l: 886, w: 30, h: 30 }
                },
                {
                    type: 'content',
                    name: "More Dropdown",
                    style: { t: 40, l: 925, w: 30, h: 30 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong1',
                    style: { t: 58, l: 222, w: 265, h: 72 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong2',
                    style: { t: 131, l: 222, w: 265, h: 72 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong3',
                    style: { t: 205, l: 222, w: 265, h: 72 }
                },
            ]
        },
        {
            id: 2,
            routes: [
                {
                    name: 'END',
                    nextId: 3,
                },
            ],
            images: [
                {
                    type: 'fullScreen',
                    src: './simulator/outlook/q3/t3-window-new-mail.png',
                    style: { t: 0, l: 0, w: 980, h: 727 }
                },
            ],
            buttons: [
                ...fullScreenEmailButtons
            ],
            form: {
                style: { t: 116, l: 17, w: 900, h: 26 },
                name: 'END',
                type: 'fullScreenForm',
                inputs: [
                    {
                        check_value: 'sales@republicservices.com',
                        name: 'addressinput',
                        style: { t: 5, l: 160, w: 600, h: 26 },
                    },
                    {
                        check_value: 'Latest Figures',
                        name: 'subject',
                        style: { t: 80, l: 160, w: 600, h: 26 },
                    },
                    {
                        check_value: 'Please can you send me the latest sales report?',
                        name: 'message',
                        type: 'textarea',
                        style: { t: 122, l: 0, w: 600, h: 250 },
                    },
                ],
                button: {
                    style: { t: 0, l: 0, w: 56, h: 72 },
                    name: 'SEND'
                }
            },
        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/shared/email-default.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
            ],
        },
    ],
};