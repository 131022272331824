import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { elStyle } from '../../constants/utils';

const ToolBarDropdown = ({ onElClick, checkPrevSelection, classNames, styles, item }) => {

    const [visible, setVisible] = useState();
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <div ref={ref}>
            <button
                name={item.name}
                style={styles}
                className={classNames}
                onClick={() => setVisible(!visible)}
            ></button>

            <div className={cx('toolbar-dropdown', { 'u-display-none': !visible })} style={elStyle(item.dropdown.image)}>
                {item.dropdown.image && <img className='el-shadow' src={item.dropdown.image.src} alt='' />}
                {item.dropdown.buttons.length > 0 && item.dropdown.buttons.map((button, i) => {
                    return (
                        <div key={`toolbarbtn-dropdown-${i}`}>
                            <button
                                name={button.name}
                                onClick={() => { onElClick(button, checkPrevSelection); setVisible(false) }}
                                className={cx('img-btn')}
                                style={elStyle(button)}
                            ></button>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default ToolBarDropdown;
