import React from 'react';
import PowerPoint from '../../components/Emulators/PowerPoint';
import Word from '../../components/Emulators/Word';
import Excel from '../../components/Emulators/Excel';
import Chrome from '../../components/Emulators/Chrome';
import Outlook from '../../components/Emulators/Outlook';
import Windows from '../../components/Emulators/Windows';

const Emulator = ({ type, step, question, handleForm, handleClick, handleChange }) => {

    const components = {
        excel: Excel,
        powerpoint: PowerPoint,
        word: Word,
        chrome: Chrome,
        outlook: Outlook,
        windows: Windows
    };
    
    const EmulatorComponent = components[type];

    return <EmulatorComponent handleChange={handleChange} handleClick={handleClick} question={question} step={step}  handleForm={handleForm} />

}

export default Emulator;
