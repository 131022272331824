import React, { Fragment, useState, useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/client'
import ls from 'local-storage';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../services/auth/AuthProvider';
import { START_ASSESSMENT_MUTATION } from './services/graphql';
import { LOGGED_IN } from '../Home/services/graphql';

import ImageFadeIn from 'react-image-fade-in';
import logo from '../../images/republic-logo-dark.png';

const Login = () => {

    const [codeInvalid, setCodeInvalid] = useState(false);

    const client = useApolloClient();
    const auth = useContext(AuthContext);

    const [startAssessment, { loading }] = useMutation(START_ASSESSMENT_MUTATION, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.startAssessment.assessment && data.startAssessment.assessment.id && data.startAssessment.token) {
                ls('ce_candidate_token', data.startAssessment.token);
                ls('ce_candidate_user', JSON.stringify(data.startAssessment.assessment.candidate));
                auth.setCurrentUser(data.startAssessment.assessment.candidate);
                client.writeQuery({
                    query: LOGGED_IN,
                    data: {
                        loggedIn: true,
                        forcedLogout: false,
                    }
                });
            }
            if (data.startAssessment.errors && data.startAssessment.errors[0] === "Invalid code") {
                setCodeInvalid(true);
            }
        },
        refetchQueries: () => [{ query: LOGGED_IN }]
    });


    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        const { code } = data;
        startAssessment({
            variables: {
                code
            }
        });
    }

    return (
        <Fragment>
            <div className="introPanel">
                <div className='logo'>
                    <ImageFadeIn width={152} alt="Republic Logo" src={logo} />
                </div>
                <div>
                    <h1 className='h2'>Computer skills test</h1>
                    <p className='u-m-base-3'>Please enter your access code from your invite email.</p>
                    <form className='form' onSubmit={handleSubmit(onSubmit)}>
                        <div className="basic-form__group">
                            <input
                                name="code"
                                placeholder="Your code: e.g. XYFGH123"
                                className="basic-form__text-box"
                                {...register('code',
                                    {
                                        required: 'Please enter a code',
                                        validate: (value) => value.length === 6 || "Must be 6 characters long",
                                    }
                                )}
                            />
                            {errors.code && <p className="basic-form__hint">{errors.code.message}</p>}
                        </div>
                        <button type="submit" className="btn btn-fullWidth u-display-block">{loading ? 'Loading...' : 'Get Started'}</button>
                    </form>
                    {codeInvalid && <p className="u-m-top-2">Your code was not recognized. Please try again.</p>}
                </div>
            </div>
        </Fragment>

    );
}

export default Login;
