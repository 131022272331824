import React, { useState, Fragment } from 'react';
import ToolBarDropdown from '../../ToolBarDropdown';
import { WINDOW_ITEMS } from './Elements/window_items';
import TABBAR_ITEMS from './Elements/tabbar_items';

import AppFrameImg from '../../../images/excel/app_frame.png';
import ImageFadeIn from 'react-image-fade-in';

import { Menu, Item, useContextMenu, Separator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import cx from 'classnames';
import styles from './Excel.module.scss';
import { elStyle } from '../../../constants/utils';

const Excel = ({ step, handleClick }) => {

    const [activeToolbar, setActiveToolbar] = useState(1);

    const onElClick = (btn, trackPrevValues) => {
        handleClick(btn, trackPrevValues);
    }

    const { show } = useContextMenu();
    const displayContextMenu = (e, buttonId) => {
        show(e, {
            id: buttonId,
            position: {
                x: e.pageX,
                y: e.pageY - 50,
            },
        });
    }

    return (
        <div className={styles.app}>
            <ImageFadeIn className={styles.appBg} src={AppFrameImg} opacityTransition={0.1} alt='Appframe' />
            <ul className='appbar-links'>
                {WINDOW_ITEMS.map((button, i) => {
                    return (
                        <li key={`window-link-${i}`} className='appbar-link' >
                            <button
                                name={button.name}
                                onClick={() => onElClick(button)}
                                className={cx(styles.appWindowBtn, 'img-btn')}
                                style={elStyle(button)}
                            ></button>
                        </li>
                    )
                })}
            </ul>
            <div className='toolbar'>
                {
                    TABBAR_ITEMS.map((tab, i) => {
                        return (
                            <Fragment key={`tab-${i}`}>
                                <button
                                    name={tab.name}
                                    onClick={() => { i === 0 ? onElClick(tab) : setActiveToolbar(i) }}
                                    className='img-btn'
                                    style={elStyle(tab)}
                                ></button>
                                <img
                                    key={`toolbar-${i}`}
                                    className={cx('toolbar-bg', { 'u-display-none': i !== activeToolbar })}
                                    src={tab.image}
                                    alt=''
                                />
                            </Fragment>
                        )
                    })
                }

                <div className='toolbar-content'>
                    {
                        TABBAR_ITEMS[activeToolbar].mainButtons && TABBAR_ITEMS[activeToolbar].mainButtons.length > 0 && TABBAR_ITEMS[activeToolbar].mainButtons.map((button, i) => {
                            return (
                                <Fragment key={`toolbarbtn-${i}}`}>
                                    {button.dropdown ? (
                                        <ToolBarDropdown
                                            item={button}
                                            classNames='img-btn'
                                            onElClick={onElClick}
                                            styles={elStyle(button)}
                                        />
                                    ) : <button
                                        name={button.name}
                                        onClick={() => onElClick(button)}
                                        className={button.name === 'FakeButton' ? 'abs-btn-no-hover' :'img-btn'}
                                        style={elStyle(button)}></button>
                                    }
                                </Fragment>
                            )
                        })
                    }
                </div>
            </div>

            {step.images && step.images.filter(img => img.type === 'dialog').map((image, i) => (
                <div key={`dialog-im-${i}`}>
                    <img className='abs-pos el-shadow' style={elStyle(image)} src={image.src} alt='' />
                </div>
            ))}


            <div className={styles.appContentArea}>
                {step.images && step.images.filter(img => img.type === 'content').map((image, i) => (
                    <div key={`start_images-${i}`}>
                        <img style={elStyle(image)} src={image.src} alt='' />
                    </div>
                ))}
                {step.buttons && step.buttons.filter(b => b.type === 'content').map((button, i) => {
                    return (
                        <Fragment key={`step-btn-${i}`}>
                            <button
                                name={button.name}
                                onClick={() => onElClick(button)}
                                className='img-btn'
                                style={elStyle(button)}
                                {...(button.contextMenu && { onContextMenu: (e) => displayContextMenu(e, button.contextMenu.id) })}
                            ></button>
                            {button.contextMenu && button.contextMenu.buttons && (
                                <Menu id={button.contextMenu.id}>
                                    {button.contextMenu.buttons.map((button, i) => {
                                        return (
                                            <Fragment key={`ctx-${i}`}>
                                                <Item onClick={() => onElClick(button)}>{button.label}</Item>
                                                {button.showSeparator && <Separator />}
                                            </Fragment>
                                        )
                                    })}
                                </Menu>
                            )}
                        </Fragment>
                    )
                })}
            </div>
        </div>
    );
}

export default Excel;