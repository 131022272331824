import { SKILLSETS, FINAL_STEP } from '../../utils';
import { SEARCH_BUTTONS } from '../../../components/Emulators/Excel/button_names';

export const q4 = {
    orderid: 4,
    emulator: { type: 'excel', name: 'Microsoft Excel' },
    skillset: SKILLSETS.excel,
    instructions: [
        'You need to find a specific value on this sheet, however there are several hundred rows.',
        'Without using keyboard shortcuts, open the search dialogue so you can begin a text search on the sheet.'
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: SEARCH_BUTTONS.find,
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/excel/q4/t4-start.png',
                    style: { t: 0, l: 0, w: 980, h: 552 }
                },
            ],
            buttons: [
                {
                    type: 'content',
                    name: 'ColumnA',
                    style: { t: 41, l: 25, w: 135, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnB',
                    style: { t: 41, l: 160, w: 98, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnC',
                    style: { t: 41, l: 258, w: 100, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnD',
                    style: { t: 41, l: 358, w: 105, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnE',
                    style: { t: 41, l: 464, w: 65, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnF',
                    style: { t: 41, l: 529, w: 65, h: 18, cursor: 's-resize' },
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'dialog',
                    src: './simulator/excel/q4/t4-dialog.png',
                    style: { t: 50, l: 50, w: 422, h: 203, zIndex: 12 }
                },
                {
                    type: 'content',
                    src: './simulator/excel/q4/t4-start.png',
                    style: { t: 0, l: 0, w: 980, h: 552 }
                },
            ],
        },
    ],
};