import { SKILLSETS, FINAL_STEP } from '../../utils';
import { BROWSER_BUTTONS, BOOKMARK_MENU_TYPES, BOOKMARK_BUTTONS, BOOKMARK_ACTIONS_BUTTONS } from '../../../components/Emulators/Chrome/button_names';

export const q6 = {
    orderid: 6,
    emulator: { type: 'chrome', name: 'Chrome' },
    skillset: SKILLSETS.chrome,
    instructions: ['Without using any keyboard shortcuts, remove the current page from the bookmarks.'],
    attempts: 2,
    steps: [
        {
            id: 1,
            bookMarkType: BOOKMARK_MENU_TYPES.edit,
            buttons: [
                {
                    type: 'appbar',
                    name: BROWSER_BUTTONS.newTab,
                    style: { t: 12, l: 246, w: 22, h: 22, radius: '50%' },
                },
            ],
            routes: [
                {
                    nextId: 5,
                    name: BOOKMARK_BUTTONS.remove,
                },
                {
                    name: BOOKMARK_BUTTONS.bookmarksManager,
                    nextId: 3,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q6/browser-t6-1.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
            ],
            form: {
                style: { t: 58, l: 71, w: 240, h: 100, zIndex: 13 },
                name: 'not used',
                type: 'bookmark',
                inputs: [
                    {
                        check_value: 'Customer Support',
                        initial_value: 'Republic Home',
                        name: 'bookmarkinput',
                        style: { t: 0, l: 0, w: 200, h: 26 },
                    },
                ],
            },
        },
        {
            id: 3,
            routes: [
                {
                    name: BOOKMARK_ACTIONS_BUTTONS.delete,
                    nextId: 4,
                },
            ],
            buttons: [
                {
                    type: 'content',
                    name: 'Gmail',
                    linkHoverClass: 'circleBtn',
                    style: { t: 165, l: 906, w: 28, h: 28 },
                },
                {
                    type: 'content',
                    name: 'Youtube',
                    linkHoverClass: 'circleBtn',
                    style: { t: 202, l: 906, w: 28, h: 28 },
                },
                {
                    type: 'content',
                    name: 'Maps',
                    linkHoverClass: 'circleBtn',
                    style: { t: 240, l: 906, w: 28, h: 28 },
                },
                {
                    type: 'content',
                    name: 'SupportHome',
                    dropdown: {
                        id: 'DropdownSupportHome',
                        style: { t: 279, l: 906, w: 28, h: 28 },
                        image: {
                            src: './simulator/chrome/q6/browser-t6-2-1.png',
                            style: { t: 285, l: 735, w: 197, h: 338 }
                        },
                        mainButtons: [
                            {
                                name: BOOKMARK_ACTIONS_BUTTONS.edit,
                                style: { t: 10, l: 0, w: 197, h: 28 },
                            },
                            {
                                name: BOOKMARK_ACTIONS_BUTTONS.delete,
                                style: { t: 13, l: 0, w: 197, h: 28 },
                            },
                            {
                                name: BOOKMARK_ACTIONS_BUTTONS.cut,
                                style: { t: 32, l: 0, w: 197, h: 28 },
                            },
                            {
                                name: BOOKMARK_ACTIONS_BUTTONS.copy,
                                style: { t: 38, l: 0, w: 197, h: 28 },
                            },
                            {
                                name: BOOKMARK_ACTIONS_BUTTONS.copyUrl,
                                style: { t: 44, l: 0, w: 197, h: 28 },
                            },
                            {
                                name: BOOKMARK_ACTIONS_BUTTONS.paste,
                                style: { t: 48, l: 0, w: 197, h: 28 },
                            },
                            {
                                name: BOOKMARK_ACTIONS_BUTTONS.openInNewTab,
                                style: { t: 65, l: 0, w: 197, h: 28 },
                            },
                            {
                                name: BOOKMARK_ACTIONS_BUTTONS.openInNewWindow,
                                style: { t: 71, l: 0, w: 197, h: 28 },
                            },
                            {
                                name: BOOKMARK_ACTIONS_BUTTONS.openInIncognitoWindow,
                                style: { t: 75, l: 0, w: 197, h: 28 },
                            },

                        ]
                    }
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q6/browser-t6-2.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
                {
                    type: 'preloadOnly',
                    src: './simulator/chrome/q6/browser-t6-2-1.png',
                }
            ],
        },
        {
            name: FINAL_STEP,
            id: 4,
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q6/browser-t6-3.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                }
            ]
        },
        {
            name: FINAL_STEP,
            id: 5,
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q5/browser-t5-1.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                }
            ]
        },
    ],
};