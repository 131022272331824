
import TabHome from '../../../../images/outlook/tabs/tab-home.png';
import TabSend from '../../../../images/outlook/tabs/tab-send-receive.png';

import TabView from '../../../../images/outlook/tabs/tab-view.png';
import TabHelp from '../../../../images/outlook/tabs/tab-help.png';

import { home_buttons } from './home_buttons';
import { send_receive_buttons } from './send_receive_buttons';

const FakeButtonFull = [{
    name: 'FakeButton',
    style: { t: 10, l: 10, w: 940, h: 80 },
}]

const TABBAR_ITEMS = [
    {
        name: 'File',
        style: { t: 36, l: 5, w: 50, h: 24 },
        image: TabHome,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Home',
        style: { t: 36, l: 60, w: 50, h: 24 },
        image: TabHome,
        mainButtons: home_buttons
    },
    {
        name: 'Send',
        style: { t: 36, l: 120, w: 96, h: 24 },
        image: TabSend,
        mainButtons: send_receive_buttons
    },
    {
        name: 'View',
        style: { t: 36, l: 229, w: 40, h: 24 },
        image: TabView,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Help',
        style: { t: 36, l: 282, w: 40, h: 24 },
        image: TabHelp,
        mainButtons: FakeButtonFull
    },
];


export default TABBAR_ITEMS;