import { gql } from '@apollo/client';

export const SAVE_ANSWER_MUTATION = gql`
mutation saveAnswer($assessmentId: ID!, $questionId: ID!, $startedAt: String!, $endedAt: String!, $attempts: Int!, $passed: Boolean!) {
    saveAnswer(assessmentId: $assessmentId, questionId: $questionId, startedAt: $startedAt, endedAt: $endedAt, attempts: $attempts, passed: $passed)  {
        assessment {
            id
            progress
            nextQuestion {
                id
                orderid
                skillset
                description
            }
        }
        errors
    }
}
`;