import TabInsertDropdown from '../../../../images/word/tabs/toolbar_insert_picture.png';
import SlideOptions from '../../../../images/ppt/slide-menu.png'
import DropDown from '../../../../images/ppt/draw-options.png';
import { SLIDE_BUTTONS } from '../../../../components/Emulators/constants';
import { TAB_INSERT_BUTTONS } from '../button_names';
import { HOME_BUTTONS } from '../button_names';
import { DRAW_BUTTONS } from '../button_names';

export const insert_buttons = [
    {
        name: HOME_BUTTONS.slideOptions,
        style: { t: 41, l: 5, w: 48, h: 34 },
        dropdown: {
            image: {
                style: { t: 75, l: 102, w: 330, h: 407 },
                src: SlideOptions
            },
            buttons: [
                {
                    name: SLIDE_BUTTONS.titleSlide,
                    style: { t: 30, l: 4, w: 98, h: 60 },
                },

            ]
        }
    },
    {
        name: SLIDE_BUTTONS.newSlide,
        style: { t: 7, l: 5, w: 48, h: 34 }
    },
    {
        name: 'Table',
        style: { t: 5, l: 60, w: 48, h: 62 },
    },
    {
        name: TAB_INSERT_BUTTONS.text,
        style: { t: 7, l: 660, w: 40, h: 66 },
    },
    {
        name: 'Pictures',
        style: { t: 10, l: 113, w: 50, h: 60 },
        dropdown: {
            image: {
                style: { t: 45, l: 125, w: 174, h: 96 },
                src: TabInsertDropdown
            },
            buttons: [
                {
                    name: TAB_INSERT_BUTTONS.photoBrowser,
                    style: { t: 2, l: 5, w: 170, h: 24 },
                },
                {
                    name: TAB_INSERT_BUTTONS.pictureFromFile,
                    style: { t: 26, l: 5, w: 170, h: 24 },
                },
            ],
        },
    },
    {
        name: HOME_BUTTONS.shapes,
        style: { t: 7, l: 292, w: 40, h: 60 },
        dropdown: {
            image: {
                style: { t: 67, l: 291, w: 250, h: 588 },
                src: DropDown
            },
            buttons: [
                {
                    name: DRAW_BUTTONS.elipse,
                    style: { t: 28, l: 2, w: 18, h: 20 },
                },

            ]
        }
    }
]