import React, { useContext, } from 'react';

import { AuthContext } from '../../../services/auth/AuthProvider';

const LoggedInRouter = ({ children }) => {

    const { currentUser } = useContext(AuthContext);

    if (currentUser && currentUser.id) {
        return (
            <div className='app__layout'>
                {children}
            </div>
        );
    }
}

export default LoggedInRouter;
