import { SLIDESHOW_BUTTONS } from '../button_names';
export const slideshow_buttons = [
    {
        name: SLIDESHOW_BUTTONS.start,
        style: { t: 6, l: 10, w: 57, h: 67 }
    },
    {
        name: SLIDESHOW_BUTTONS.startCurrent,
        style: { t: 6, l: 69, w: 68, h: 67 }
    },
]