import { FILE_TYPE_BUTTONS } from '../button_names';

export const file_type_buttons = [
    {
        name: FILE_TYPE_BUTTONS.docx,
        style: { t: 1, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.docm,
        style: { t: 1, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.doc,
        style: { t: 2, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.dotx,
        style: { t: 2, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.dotm,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.dot,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.pdf,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.xps,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.mht,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.htm,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.htmf,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.rtf,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.txt,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.xml,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.xmlnew,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.docxStrict,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
    {
        name: FILE_TYPE_BUTTONS.odt,
        style: { t: 3, l: 0, w: 336, h: 19 },
    },
]