import React from 'react';
import styles from './Assessment.module.scss';
import doneIcon from '../../images/doneIcon.svg';
import ImageFadeIn from 'react-image-fade-in';
import LogoutButton from '../../components/LogoutButton';

const FinishedAssessment = () => {
    return (
        <div className={styles.finishedAssessment}>
            <div className={styles.finishedAssessmentDoneIcon}>
                <ImageFadeIn src={doneIcon} alt='done' />
            </div>
            <h1>Thank you</h1>
            <p className='u-text-muted u-m-base-3'>One of our team will be in touch with you shortly.</p>
            <LogoutButton classes="btn btn-wide">Log me out</LogoutButton>
        </div>
    );
}

export default FinishedAssessment;
