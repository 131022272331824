import { SKILLSETS, FINAL_STEP } from '../../utils';
import { DELETE_BUTTONS } from '../../../components/Emulators/Excel/button_names';

export const q1 = {
    orderid: 1,
    emulator: { type: 'excel', name: 'Microsoft Excel' },
    skillset: SKILLSETS.excel,
    instructions: ['Select and delete the entire Column C'],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'ColumnC',
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/excel/q1/t1-start.png',
                    style: { t: 0, l: 0, w: 980, h: 552 }
                },
            ],
            buttons: [
                {
                    type: 'content',
                    name: 'ColumnA',
                    style: { t: 41, l: 25, w: 135, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnB',
                    style: { t: 41, l: 160, w: 98, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnC',
                    style: { t: 41, l: 258, w: 100, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnD',
                    style: { t: 41, l: 358, w: 105, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnE',
                    style: { t: 41, l: 464, w: 65, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnF',
                    style: { t: 41, l: 529, w: 65, h: 18, cursor: 's-resize' },
                },
            ]
        },
        {
            name: 'steps2',
            id: 2,
            images: [
                {
                    type: 'content',
                    src: './simulator/excel/q1/t1-2.png',
                    style: { t: 0, l: 0, w: 980, h: 552 }
                }
            ],
            routes: [
                {
                    name: 'DeleteColumn',
                    nextId:3 
                }
            ],
            buttons: [
                {
                    type: 'content',
                    name: 'ColumnC',
                    style: { t: 41, l: 258, w: 100, h: 18 },
                    contextMenu: {
                        id: 'DeleteColumnContextMenu',
                        buttons: [
                            {
                                name: 'cut',
                                label: 'Cut'
                            },
                            {
                                name: 'Copy',
                                label: 'Copy',
                                showSeparator: true,
                            },
                            {
                                name: 'Insert',
                                label: 'Insert'
                            },
                            {
                                name: DELETE_BUTTONS.deleteColumn,
                                label: 'Delete'
                            },
                            {
                                name: 'ClearContents',
                                label: 'Clear Contents',
                                showSeparator: true,
                            },
                            {
                                name: 'FormatCells',
                                label: 'Format Cells'
                            },
                            {
                                name: 'ColumnWidth',
                                label: 'Column Width'
                            },
                            {
                                name: 'Hide',
                                label: 'Hide'
                            },
                            {
                                name: 'Unhide',
                                label: 'Unhide'
                            },
                        ],
                    }
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'content',
                    src: './simulator/excel/q1/task-1-result.jpg',
                    style: { t: 0, l: 0, w: 980, h: 552 }
                }
            ],
        },
    ],
};