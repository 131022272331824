import React from 'react';
import { ANSWER_OPTS as ANSWERS, FINAL_STEP, ALLOWED_ATTEMPTS } from '../../../../constants/utils';
import { Award, XCircle } from 'react-feather';
import styles from './InfoModal.module.scss';

const InfoModal = ({ onReset, qData, onSave, savingAnswer }) => {

    return (
        <div className={styles.infoModalOverlay}>
            <div className={styles.infoModal}>
                {
                    qData.currentStep && qData.currentStep.name === FINAL_STEP ? (
                        <div>
                            <div className='u-m-base-1'><Award size="30" /></div>
                            <h3>Correct</h3>
                            <button disabled={savingAnswer} className='btn btn-white' type='button' onClick={() => onSave(qData)} >{savingAnswer ? 'Saving...' : 'Continue'}</button>
                        </div>
                    ) :
                        <div>
                            <div className='u-m-base-1'><XCircle size="30" /></div>
                            {
                                qData.failedAttempts < ALLOWED_ATTEMPTS && qData.userAnswer === ANSWERS.wrong ? (
                                    <div>
                                        <h3>Please try again</h3>
                                        <h3 className='u-m-base-2'>Attempt {qData.failedAttempts} of {ALLOWED_ATTEMPTS}</h3>
                                        <button className='btn btn-white' type='button' onClick={onReset}>Try again</button>
                                    </div>
                                ) :
                                    <div>
                                        <h3>Incorrect</h3>
                                        <button disabled={savingAnswer} className='btn btn-white' type='button' onClick={() => onSave(qData)}>{savingAnswer ? 'Saving...' : 'Next Question'}</button>
                                    </div>
                            }
                        </div>
                }
            </div>
        </div>
    );
}

export default InfoModal;
