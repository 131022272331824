import React from 'react';
import loader from '../../images/loader.svg';
import styles from './Loader.module.scss';

const Loader = () => {
    return (
        <div className={styles.loader}>
            <img src={loader} alt="Loading" />
        </div>
    );
}

export default Loader;