import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { elStyle } from '../../constants/utils';

const AppBarDropdown = ({ onElClick, classNames, styles, item, checkPrevSelection }) => {

    const [visible, setVisible] = useState();
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <div ref={ref}>
            <button
                name={item.name}
                style={styles}
                className={classNames}
                onClick={() => setVisible(!visible)}
            ></button>

            <div className={cx('appbar-dropdown', { 'u-display-none': !visible })} style={elStyle(item.image)}>
                {item.image && <img className='el-shadow' src={item.image.src} alt='' />}
                {item.mainButtons.length > 0 &&
                    <ul className='appbar-menu-list'>
                        {item.mainButtons.map((button, i) => {
                            return (
                                <li style={elStyle(button)} className={cx('appbar-menu-item', { 'appbar-menu-item-with-hover': button.dropdown })} key={`toolbarbtn-${i}`}>
                                    <button
                                        name={button.name}
                                        className='hover-dropdown-btn'
                                        {...(!button.dropdown && { onClick: () => { onElClick(button, checkPrevSelection); setVisible(false) } })}
                                    ></button>
                                    {button.dropdown && (
                                        <div style={{top: button.dropdown.image.top}} className={cx('appbar-hover-menu', {'appbar-hover-menu-left': button.dropdown.left})}>
                                            <img src={button.dropdown.image.src} style={elStyle(button.dropdown.image)} alt='' />
                                            {button.dropdown.buttons.length > 0 &&
                                                <ul style={elStyle(button.dropdown.image)}>
                                                    {button.dropdown.buttons.map((button, i) => {
                                                        return (
                                                            <li  key={`appbar-hover-item-${i}`}>
                                                                <button
                                                                    name={button.name}
                                                                    onClick={(e) => { onElClick(button, checkPrevSelection); setVisible(false) }}
                                                                    className={cx('img-btn')}
                                                                    style={elStyle(button)}
                                                                ></button>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                        </div>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    );
};

export default AppBarDropdown;
