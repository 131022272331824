
import TabHome from '../../../../images/word/tabs/tab-home.png';
import TabInsert from '../../../../images/word/tabs/tab-insert.png';
import TabDraw from '../../../../images/word/tabs/tab-draw.png';
import TabReferences from '../../../../images/word/tabs/tab-references.png';
import TabDesign from '../../../../images/word/tabs/tab-design.png';
import TabLayout from '../../../../images/word/tabs/tab-layout.png';
import TabMailings from '../../../../images/word/tabs/tab-mailings.png';
import TabReview from '../../../../images/word/tabs/tab-review.png';
import TabView from '../../../../images/word/tabs/tab-view.png';
import TabHelp from '../../../../images/word/tabs/tab-help.png';
import FileWindow from '../../../../images/word/file-window.png';

import { file_buttons } from './file_buttons';
import { home_buttons } from './home_buttons';
import { insert_buttons } from './insert_buttons';

const FakeButtonFull = [{
    name: 'FakeButton',
    style: { t: 10, l: 10, w: 940, h: 80 },
}]

const TABBAR_ITEMS = [
    {
        name: 'File',
        style: { t: 36, l: 5, w: 50, h: 24 },
        image: FileWindow,
        mainButtons: file_buttons
    },
    {
        name: 'Home',
        style: { t: 36, l: 60, w: 50, h: 24 },
        image: TabHome,
        mainButtons: home_buttons
    },
    {
        name: 'Insert',
        style: { t: 36, l: 120, w: 45, h: 24 },
        image: TabInsert,
        mainButtons: insert_buttons
    },
    {
        name: 'Draw',
        style: { t: 36, l: 177, w: 45, h: 24 },
        image: TabDraw,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Design',
        style: { t: 36, l: 235, w: 50, h: 24 },
        image: TabDesign,
        mainButtons: FakeButtonFull

    },
    {
        name: 'Layout',
        style: { t: 36, l: 300, w: 50, h: 24 },
        image: TabLayout,
        mainButtons: FakeButtonFull

    },
    {
        name: 'References',
        style: { t: 36, l: 368, w: 65, h: 24 },
        image: TabReferences,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Mailings',
        style: { t: 36, l: 452, w: 60, h: 24 },
        image: TabMailings,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Review',
        style: { t: 36, l: 525, w: 55, h: 24 },
        image: TabReview,
        mainButtons: FakeButtonFull
    },
    {
        name: 'View',
        style: { t: 36, l: 592, w: 40, h: 24 },
        image: TabView,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Help',
        style: { t: 36, l: 645, w: 40, h: 24 },
        image: TabHelp,
        mainButtons: FakeButtonFull
    },
];


export default TABBAR_ITEMS;