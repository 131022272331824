
import StartMenu from '../../../../images/windows/start-open.png';

import { start_buttons } from './start_buttons';
import { BOTTOM_BAR_BUTTONS } from '../button_names';

const WINDOW_ITEMS = [
    {
        name: BOTTOM_BAR_BUTTONS.start,
        style: { t: 688, l: 0, w: 48, h: 39 },
        dropdown: {
            image: {
                style: { t: 77, l: 0, w: 619, h: 612 },
                src: StartMenu
            },
            buttons: start_buttons
        }
    },
    {
        name: BOTTOM_BAR_BUTTONS.circle,
        style: { t: 688, l: 378, w: 40, h: 39 },
    },
    {
        name: BOTTOM_BAR_BUTTONS.video,
        style: { t: 688, l: 422, w: 40, h: 39 },
    },
    {
        name: BOTTOM_BAR_BUTTONS.folders,
        style: { t: 688, l: 472, w: 40, h: 39 },
    },
    {
        name: BOTTOM_BAR_BUTTONS.chrome,
        style: { t: 688, l: 520, w: 40, h: 39 },
    },
    {
        name: BOTTOM_BAR_BUTTONS.excel,
        style: { t: 688, l: 566, w: 40, h: 39 },
    },
    {
        name: BOTTOM_BAR_BUTTONS.outlook,
        style: { t: 688, l: 612, w: 40, h: 39 },
    },
]

export default WINDOW_ITEMS;