import { SKILLSETS, FINAL_STEP } from '../../utils';

export const q5 = {
    orderid: 5,
    emulator: { type: 'outlook', name: 'Microsoft Outlook' },
    skillset: SKILLSETS.outlook,
    instructions: ['Open the email from "John Pertwee" with the subject line "Project update"'],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'OpenPreview',
                    nextId: 2
                },
                {
                    name: 'OpenFull',
                    nextId: 3,
                },
            ],
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/shared/email-default.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
            ],
            buttons: [
                {
                    type: 'msgThumb',
                    name: 'OpenFull',
                    hasDoubleClick: true,
                    singleClickName: 'OpenPreview',
                    style: { t: 58, l: 222, w: 265, h: 72 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong1',
                    style: { t: 131, l: 222, w: 265, h: 72 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong2',
                    style: { t: 205, l: 222, w: 265, h: 72 }
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/q5/t5-preview.png',
                    style: { t: 0, l: 0, w: 980, h: 727 }
                },
            ],
        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'fullScreen',
                    src: './simulator/outlook/q5/t5-popout.png',
                    style: { t: 0, l: 0, w: 980, h: 727 }
                },
            ],
        },
    ],
};