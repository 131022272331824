
import { SEND_RECEIVE_BUTTONS } from '../button_names';

export const send_receive_buttons = [
    {
        name: SEND_RECEIVE_BUTTONS.sendReceiveAll,
        style: { t: 8, l: 10, w: 174, h: 25 },
    },
    {
        name: SEND_RECEIVE_BUTTONS.sendAll,
        style: { t: 8, l: 186, w: 88, h: 25 },
    },
    {
        name: SEND_RECEIVE_BUTTONS.updateFolder,
        style: { t: 8, l: 278, w: 119, h: 25 },
    },
    {
        name: SEND_RECEIVE_BUTTONS.sendReceiveGroups,
        style: { t: 8, l: 400, w: 170, h: 25 },
    },
    {
        name: SEND_RECEIVE_BUTTONS.showProgress,
        style: { t: 8, l: 582, w: 120, h: 25 },
    },
    {
        name: SEND_RECEIVE_BUTTONS.clearAll,
        style: { t: 8, l: 710, w: 92, h: 25 },
    },

]