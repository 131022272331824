import { gql } from '@apollo/client';

// Cache queries
// Is the user logged in
export const LOGGED_IN = gql`
    {
        loggedIn @client
        error @client
        forcedLogout @client
    }
`;

// Has the user been 401 logged out
export const FORCED_LOGOUT = gql`
    {
        forcedLogout @client
    }
`;

// Has the client thrown an error
export const ERROR = gql`
    {
        error @client
    }
`;


