import FileSaveAs from '../../../../images/word/file-save-as.png';
import DialogSaveBrowse from '../../../../images/word/dialogue-save-browse.png';
import DialogExportPdf from '../../../../images/word/dialogue-export-pdf.png';

import FileExport from '../../../../images/word/file-export.png';
import FileTypeDropdown from '../../../../images/word/file-type-menu.png';
import { FILE_BUTTONS } from '../button_names';
import { file_type_buttons } from './file_type_buttons';

export const file_buttons = [
    {
        name: FILE_BUTTONS.back,
        style: { t: 45, l: 15, w: 25, h: 25 },
    },
    {
        name: FILE_BUTTONS.home,
        style: { t: 84, l: 2, w: 130, h: 32 },
        subMenu: {

        }
    },
    {
        name: FILE_BUTTONS.new,
        style: { t: 120, l: 2, w: 130, h: 32 },
    },
    {
        name: FILE_BUTTONS.open,
        style: { t: 160, l: 2, w: 130, h: 32 },
    },
    {
        name: FILE_BUTTONS.info,
        style: { t: 212, l: 2, w: 130, h: 32 },
    },
    {
        name: FILE_BUTTONS.save,
        style: { t: 250, l: 2, w: 130, h: 32 },
    },
    {
        name: FILE_BUTTONS.saveAs,
        style: { t: 290, l: 2, w: 130, h: 32 },
        subMenu: {
            image: FileSaveAs,
            buttons: [
                {
                    name: FILE_BUTTONS.browse,
                    style: { t: 427, l: 179, w: 100, h: 32 },
                    dialog: {
                        image: {
                            style: {t: 10, l: 20, w: 587, h: 449},
                            src: DialogSaveBrowse
                        },
                        mainButtons: [
                            {
                                name: 'CancelDialog',
                                style: {t: 413, l: 487, w: 82, h: 24},
                            },
                            {
                                name: 'SelectFile',
                                style: { t: 298, l: 121, w: 457, h: 20, zIndex: 11 },
                                dropdown: {
                                    image: {
                                        style: { t: 317, l: 121, w: 338, h: 327 },
                                        src: FileTypeDropdown
                                    },
                                    mainButtons: file_type_buttons
                                }
                            }
                        ]
                    }
                },
                // shared_fileTypeSelectMenu
                {
                    name: 'SelectFile',
                    style: { t: 162, l: 500, w: 337, h: 22, zIndex: 11 },
                    dropdown: {
                        image: {
                            style: { t: 182, l: 501, w: 338, h: 327 },
                            src: FileTypeDropdown
                        },
                        mainButtons: file_type_buttons
                    }
                }
            ]
        }
    },
    {
        name: FILE_BUTTONS.print,
        style: { t: 328, l: 2, w: 130, h: 32 },
    },
    {
        name: FILE_BUTTONS.share,
        style: { t: 365, l: 2, w: 130, h: 32 },
    },
    {
        name: FILE_BUTTONS.export,
        style: { t: 403, l: 2, w: 130, h: 32 },
        subMenu: {
            image: FileExport,
            buttons: [
                {
                    name: FILE_BUTTONS.createPdf,
                    style: { t: 227, l: 500, w: 80, h: 80 },
                    dialog: {
                        image: {
                            style: {t: 10, l: 20, w: 587, h: 449},
                            src: DialogExportPdf
                        },
                        mainButtons: [
                            {
                                name: 'CancelDialog',
                                style: {t: 492, l: 489, w: 82, h: 24},
                            },
                        ]
                    }
                }
            ]
        }
    },

]