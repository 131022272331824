import React, { Fragment, useState } from 'react';
import styles from '../Dashboard.module.scss';
import Practice from './Practice';
import ImageFadeIn from 'react-image-fade-in'
import instructionsImg from '../../../images/practice/instructions.png';


const Intro = ({ onFinishPractice }) => {

    const [showPractice, setShowPractice] = useState(false);

    return (
        <Fragment>
            {showPractice ? <Practice onFinishPractice={onFinishPractice} /> : (
                <div className={styles.intro}>
                    <h1 className='h2'>Info & Practice</h1>
                    <p className='u-text-muted'>You will be asked to undertake a series of tasks. You will be shown the instructions for the task at the start and they will also be shown on a panel to the right of the screen.</p>
                    <p className='u-text-muted u-m-base-3'>On a smaller screen, you can review the instructions by clicking on this tab:</p>
                    <div className='u-m-base-2'>
                        <ImageFadeIn className='u-m-x-auto' src={instructionsImg} alt='instructions' />
                    </div>

                    <p className='u-text-muted u-m-base-2'><strong>Please allow approximately 20 minutes for the assessment.</strong></p>
                    <p className='u-text-muted u-m-base-3'>Let's start with an initial practice question. The assessment will begin after the practice. Click the "Start Practice" button to begin.</p>
                    <div className={styles.introBtnArea}>
                        <button onClick={() => setShowPractice(true)} type="button" className='btn btn-fullWidth u-display-block'>Start practice</button>
                    </div>
                </div>
            )}
        </Fragment>

    );
}

export default Intro;
