import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client'
import { ANSWER_OPTS as ANSWERS } from '../../constants/utils';

import { QUESTIONS } from '../../constants/questions';
import Progress from './components/Progress';
import QuestionTransition from './QuestionTransition';
import FinishedAssessment from './FinishedAssessment';
import { SAVE_ANSWER_MUTATION } from './services/graphql';

const Assessment = ({ progress, qCounter, initialQuestion, assessmentId }) => {

    const [state, setState] = useState({
        nextQuestion: initialQuestion && { ...QUESTIONS.find(q => q.orderid === initialQuestion.orderid && q.skillset === initialQuestion.skillset), id: initialQuestion.id },
        progress: progress,
    });


    const [saveAnswer, { loading }] = useMutation(SAVE_ANSWER_MUTATION, {
        onCompleted: data => {
            if (data.saveAnswer.errors && data.saveAnswer.errors.length) {
                alert('There was an error, please try again')
            } else {
                const { nextQuestion, progress } = data.saveAnswer.assessment;
                setState({
                    nextQuestion: nextQuestion && { ...QUESTIONS.find(q => q.orderid === nextQuestion.orderid && q.skillset === nextQuestion.skillset), id: nextQuestion.id },
                    progress,
                });
            }
        }
    });


    const saveAns = qData => {
        const attempts = (qData.userAnswer === ANSWERS.correct ? 1 : 0) + qData.failedAttempts;
        const d1 = new Date();
        const endedAt = d1.toISOString().slice(0, -5);
        saveAnswer({
            variables: {
                assessmentId,
                questionId: qData.question.id,
                startedAt: qData.startedAt,
                endedAt,
                attempts,
                passed: qData.userAnswer === ANSWERS.correct ? true : false
            }
        });
    }

    return (
        <Fragment>
            <Progress progress={state.progress} qCounter={qCounter} />
            {state.progress >= qCounter ?
                <FinishedAssessment />
                : state.nextQuestion &&
                <QuestionTransition
                    onSaveAnser={saveAns}
                    question={state.nextQuestion}
                    savingAnswer={loading}
                />
            }
        </Fragment>
    );
}

export default Assessment;
