import { SKILLSETS, FINAL_STEP } from '../../utils';
import { DATA_FORMAT_BUTTONS } from '../../../components/Emulators/Excel/button_names';

export const q3 = {
    orderid: 3,
    emulator: { type: 'excel', name: 'Microsoft Excel' },
    skillset: SKILLSETS.excel,
    instructions: [
        'The currently selected column (G) is set to show the total price.',
        'Without editing each indvidual cell, change the format to show the values in US Dollars ($).',
        'Hint: This is also referred to as "Accounting" format',
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: DATA_FORMAT_BUTTONS.accounting,
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/excel/q3/t3-start.png',
                    style: { t: 0, l: 0, w: 980, h: 552 }
                },
            ],
            buttons: [
                {
                    type: 'content',
                    name: 'ColumnA',
                    style: { t: 41, l: 25, w: 135, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnB',
                    style: { t: 41, l: 160, w: 98, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnC',
                    style: { t: 41, l: 258, w: 100, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnD',
                    style: { t: 41, l: 358, w: 105, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnE',
                    style: { t: 41, l: 464, w: 65, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnF',
                    style: { t: 41, l: 529, w: 65, h: 18, cursor: 's-resize' },
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    src: './simulator/excel/q3/task-3-result.jpg',
                    style: { t: 0, l: 0, w: 980, h: 552 }
                }
            ],
        },
    ],
};