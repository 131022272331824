import { SKILLSETS, FINAL_STEP } from '../../utils';
import { START_BUTTONS } from '../../../components/Emulators/Windows/button_names';

export const q4 = {
    orderid: 4,
    emulator: { type: 'windows', name: 'Microsoft Windows' },
    skillset: SKILLSETS.windows,
    instructions: [
        'Open the application Microsoft Word.',
        'You should do this without opening any file, just starting the application.'
    ],
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: START_BUTTONS.word,
                    nextId: 2,
                },
            ],
        },
        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    kls: 'abs-pos',
                    src: './simulator/windows/q4/t4-result.png',
                    style: { t: 200, l: 250, w: 439, h: 282 }
                }
            ],
        },
    ],
};