import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';
import { elStyle } from '../../../../constants/utils';
import styles from '../Chrome.module.scss';

const BookmarkDropdown = ({ onElClick, handleForm, classNames, elStyles, item, step }) => {

    const [visible, setVisible] = useState();
    const [bookmarkMenu, setBookMarkMenu] = useState(null);

    const ref = useRef(null);

    const { register, handleSubmit } = useForm();


    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && !event.target.classList.contains('jsIgnoreTarget')) {
            setVisible(false);
            setBookMarkMenu(null);
        }
    };

    useEffect(() => {
        // q5
        if (step && step.triggerBookMarkMenu) {
            setVisible(true);
            setBookMarkMenu(item.mainButtons[0].dropdown);
        }
    }, [step, setBookMarkMenu, item.mainButtons]);


    const handleBookMarkClick = (button) => {
        if (button.dropdown) {
            setBookMarkMenu(button.dropdown);
        } else {
            onElClick(button);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <div ref={ref}>
            <button
                name={item.name}
                style={elStyles}
                className={classNames}
                onClick={() => { setVisible(!visible); }}
            ></button>

            <div className={cx('appbar-dropdown', { 'u-display-none': !visible })} style={elStyle(item.image)}>
                {item.image && <img className='el-shadow' src={item.image.src} alt='' />}
                {item.mainButtons.length > 0 &&
                    <Fragment>
                        <ul className='appbar-menu-list'>
                            {item.mainButtons.map((button, i) => {
                                return (
                                    <li style={elStyle(button)} className='appbar-menu-item' key={`btns-${i}`}>
                                        <button
                                            name={button.name}
                                            className='hover-dropdown-btn'
                                            onClick={() => handleBookMarkClick(button)}
                                        ></button>
                                    </li>
                                )
                            })}

                            <div className={cx({ 'u-display-none': !bookmarkMenu })}>
                                <img
                                    style={elStyle(item.mainButtons[0].dropdown.image)}
                                    className='el-shadow abs-pos'
                                    src={item.mainButtons[0].dropdown.image.src} alt=''
                                />
                                {bookmarkMenu && (
                                    <ul className={styles.bookmarkOptionsList} style={elStyle(bookmarkMenu.image)}>
                                        {step.form && step.form.type === 'bookmark' && (
                                            <form
                                                onSubmit={handleSubmit(data => handleForm(data, step.form))}
                                                className='form-area'
                                                style={elStyle(step.form)}
                                            >
                                                {step.form.inputs.map((input, i) => (
                                                    <input
                                                        key={`form-input-${i}`}
                                                        type="text"
                                                        style={elStyle(input)}
                                                        placeholder={input.placeholder}
                                                        name={input.name}
                                                        {...register(input.name)}
                                                        className='abs-pos'
                                                        defaultValue={input.initial_value}
                                                        onFocus={(e) => e.target.select()}
                                                    />
                                                ))}
                                                {step.form.button && <button type='submit' name={step.form.button.name} className={cx('img-btn')}
                                                    style={elStyle(step.form.button)}></button>}
                                            </form>
                                        )}
                                        {bookmarkMenu.buttons.map((button, i) => {
                                            return (
                                                <li style={elStyle(button)} className='appbar-menu-item' key={`toolbarbtn-${i}`}>
                                                    <button
                                                        name={button.name}
                                                        className='hover-dropdown-btn'
                                                        onClick={() => onElClick(button)}
                                                    ></button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                )}
                            </div>
                        </ul>
                    </Fragment>
                }
            </div>
        </div>
    );
};

export default BookmarkDropdown;
