import { SKILLSETS, FINAL_STEP } from '../../utils';
import { PICTURE_BUTTONS } from '../../../components/Emulators/Word/button_names';

export const q3 = {
    orderid: 3,
    emulator: { type: 'word', name: 'Microsoft Word' },
    skillset: SKILLSETS.word,
    instructions: [
        'Without using any keyboard shortcuts insert the image "truck.jpg" into the current document.',
        'The text cursor is already in the correct postion in the document ready to insert the image.',
        'The image can be found in the "Pictures" folder.',
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: PICTURE_BUTTONS.thisDevice,
                    nextId: 2,
                }
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/word/q3/start_image.png',
                    style: { t: 100, l: 100, w: 604, h: 484 }
                },
            ]
        },
        {
            id: 2,
            name: 'InsertFileMenu',
            routes: [
                {
                    name: 'truckImageFile',
                    nextId: 3,
                }
            ],
            images: [
                {
                    type: 'dialog',
                    src: './simulator/word/q3/dialogue-insert-image.png',
                    style: { t: 100, l: 100, w: 816, h: 465 },
                },
            ],
            buttons: [
                {
                    name: 'image 1',
                    style: { t: 124, l: 170, w: 88, h: 18 },
                },
                {
                    name: 'image 2',
                    style: { t: 145, l: 170, w: 168, h: 18 },
                },
                {
                    name: 'image 3',
                    style: { t: 164, l: 170, w: 99, h: 18 },
                },
                {
                    name: 'truckImageFile',
                    style: { t: 185, l: 170, w: 60, h: 18 },
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'content',
                    src: './simulator/word/q3/t3-result.png',
                    style: { t: 100, l: 100, w: 604, h: 484 }
                }
            ]
        },
    ],
};