import { gql } from '@apollo/client';

export const ASSESSMENT_QUERY = gql`
query assessment($id: ID!) {
    assessment(id: $id) {
      id
      progress
      questionsCounter
      nextQuestion {
          id
          orderid
          skillset
          description
      }
    }
}
`;