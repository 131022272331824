import React, { Fragment, useState } from 'react';
import { Award, PlayCircle, AlertCircle } from 'react-feather';
import Loader from '../../../components/Loader';
import { useEffectOnce } from 'react-use';
import AppFrameImg from '../../../images/windows/app_frame.png';
import DialogImg from '../../../images/practice/dialogue.png';
import dashStyles from '../Dashboard.module.scss';
import styles from '../../Assessment/Assessment.module.scss';
import cx from 'classnames';

const Practice = ({ onFinishPractice }) => {

    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showSidebar, setShowSidebar] = useState(false);
    const [pulseAnimation, setPulseAnimation] = useState(false);

    const [showInstructions, setShowInstructions] = useState(true);

    const hideModalInstructions = () => {
        setShowInstructions(false);
        setPulseAnimation(true);
    }


    useEffectOnce(() => {
        let count = 0;
        const images = [AppFrameImg, DialogImg];
        images.forEach((imgSrc) => {
            const img = new Image();
            img.src = imgSrc;
            img.onload = () => {
                count = count + 1;
                if (count === images.length) {
                    setLoading(false);
                }
            }
        })
    });

    return (
        <Fragment>
            {
                done ? (
                    <div className={dashStyles.intro}>
                        <Award size="30" />
                        <h1 className='h2 u-m-base-3'> Practice completed - nice job!</h1>
                        <div className={styles.lineSep}></div>
                        <PlayCircle size="30" />
                        <h1 className='h2'> Ready to start?</h1>
                        <p>When you're ready you can start the skills assessment. <br /> It should take you no longer that 20 minutes and should be completed in a single session.</p>
                        <div className={dashStyles.introBtnArea}>
                            <button onClick={onFinishPractice} type="button" className='btn btn-fullWidth u-display-block'>Start now</button>
                        </div>
                    </div>
                ) : loading ? <Loader /> :
                    <div className={dashStyles.practiceWrapper}>
                        <div className={styles.wrapper}>
                            <div className={styles.wrapperMain}>
                                <div className='simWrapper'>
                                    <div className={dashStyles.practice}>
                                        <img className={dashStyles.practiceBg} src={AppFrameImg} alt='Appframe' />
                                        <img className='abs-pos' style={{ top: 200, left: 130 }} src={DialogImg} alt='' />
                                        <button onClick={() => setDone(true)} className='img-btn' style={{ top: 368, left: 710, width: 78, height: 32 }}></button>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.wrapperSide}>
                                <button
                                    onClick={() => setShowSidebar(!showSidebar)}
                                    className={cx({ [styles.wrapperSideBtnExpand]: !showSidebar }, { [styles.wrapperSideBtnExpandPulse]: pulseAnimation }, { [styles.wrapperSideBtnHide]: showSidebar })}>
                                    <AlertCircle />
                                    <span>Show instructions</span>
                                </button>
                                <div className={cx(styles.wrapperSideContent, { [styles.wrapperSideVisible]: showSidebar })}>
                                    <h1 className='u-text-white'><AlertCircle className={styles.wrapperSideIcon} /> Windows</h1>
                                    <h4>Instructions:</h4>
                                    <ul className={cx('u-m-base-3', styles.instructionsText)}>
                                        <li>Your computer has just finished updating.</li>
                                        <li>Please close the currently displayed notification window.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {showInstructions && (
                            <div className={cx({ [styles.instructionsOverlay]: showInstructions }, { 'fadeIn': showInstructions }, { 'fadeOut': !showInstructions })}>
                                <div className={styles.instructionsModal}>
                                    <button className={cx(styles.instructionsModalHideBtn)} onClick={() => hideModalInstructions()}><AlertCircle /></button>
                                    <h2>Windows</h2>
                                    <h3>Instructions</h3>
                                    <ul className={cx('u-m-base-3', styles.instructionsText)}>
                                        <li>Your computer has just finished updating.</li>
                                        <li>Please close the currently displayed notification window.</li>
                                    </ul>
                                    <button className={cx('btn btn-white u-m-top-3', styles.instructionsModalMainBtn)} onClick={() => hideModalInstructions()}>Continue</button>
                                </div>
                            </div>
                        )}
                    </div>
            }
        </Fragment>
    );
}

export default Practice;
