import { SKILLSETS, FINAL_STEP } from '../../utils';
import { MYCOMPUTER_BUTTONS } from '../../../components/Emulators/Windows/button_names';

export const q5 = {
    orderid: 5,
    emulator: { type: 'windows', name: 'Microsoft Windows' },
    skillset: SKILLSETS.windows,
    instructions: [
        'Without using the search option of windows, locate the file ‘Outdoor_Alley’ that was downloaded from the internet.',
        'Your browser is set to save downloads to the default Windows location for downloaded files.'
    ],
    hasMyComputer: true,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: MYCOMPUTER_BUTTONS.downloads,
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q5/t5-start.png',
                    style: { t: 0, l: 0, w: 980, h: 642 }
                }
            ],
            buttons: [
                {
                    name: MYCOMPUTER_BUTTONS.desktop,
                    style: { t: 70, l: 190, w: 100, h: 48 }
                },
                {
                    name: MYCOMPUTER_BUTTONS.documents,
                    style: { t: 131, l: 190, w: 115, h: 48 }
                },
                {
                    name: MYCOMPUTER_BUTTONS.music,
                    style: { t: 192, l: 190, w: 115, h: 48 }
                },
                {
                    name: MYCOMPUTER_BUTTONS.videos,
                    style: { t: 254, l: 190, w: 115, h: 48 }
                },
                {
                    name: MYCOMPUTER_BUTTONS.downloads,
                    style: { t: 70, l: 411, w: 115, h: 48 }
                },
                {
                    name: MYCOMPUTER_BUTTONS.pictures,
                    style: { t: 131, l: 411, w: 115, h: 48 }
                },
                {
                    name: MYCOMPUTER_BUTTONS.project,
                    style: { t: 192, l: 411, w: 135, h: 48 }
                },
            ]
        },
        {
            name: 'second step',
            id: 2,

            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q5/t5-downloads.png',
                    style: { t: 0, l: 0, w: 980, h: 642 }
                }
            ],
            routes: [
                {
                    name: 'Outdoor_Alley',
                    nextId: 3
                }
            ],
            buttons: [
                {
                    name: 'Outdoor_Alley',
                    style: { t: 106, l: 179, w: 121, h: 100 }
                },
                {
                    name: 'File1',
                    style: { t: 106, l: 339, w: 121, h: 100 }
                },
                {
                    name: 'File2',
                    style: { t: 106, l: 500, w: 121, h: 100 }
                },
                {
                    name: 'File3',
                    style: { t: 106, l: 662, w: 121, h: 100 }
                },
                

            ]
        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'content',
                    src: './simulator/windows/q5/t5-result.png',
                    style: { t: 0, l: 0, w: 980, h: 642 }
                }
            ],
        },
    ],
};