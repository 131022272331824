export const fullScreenEmailButtons = [
    {
        name: 'WrongDropdown',
        type: 'fullScreenBtn',
        style: { t: 68, l: 11, w: 48, h: 26, zIndex: 11 }
    },
    {
        name: 'AttachFileDropdown',
        type: 'fullScreenBtn',
        style: { t: 68, l: 686, w: 39, h: 26, zIndex: 11 }
    },
    {
        name: 'WrongDropdown',
        type: 'fullScreenBtn',
        style: { t: 68, l: 790, w: 39, h: 26, zIndex: 11 }
    },
    {
        name: 'FlagDropdown',
        type: 'fullScreenBtn',
        style: { t: 68, l: 842, w: 39, h: 26, zIndex: 11 }
    },
    {
        name: 'MoreDropdown',
        type: 'fullScreenBtn',
        style: { t: 68, l: 897, w: 30, h: 26, zIndex: 11 }
    },
]