import OfficeMenuExpanded from '../../../../images/windows/start-office-expanded.png';
import AppsMenuExpanded from '../../../../images/windows/start-apps-expanded.png';
import { START_BUTTONS } from '../button_names';

export const start_buttons = [
    {
        name: START_BUTTONS.videoEditor,
        style: { t: 43, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.word,
        style: { t: 78, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.ppt,
        style: { t: 112, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.threeDViewer,
        style: { t: 215, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.access,
        style: { t: 285, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.alarms,
        style: { t: 320, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.calculator,
        style: { t: 387, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.calendar,
        style: { t: 422, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.camera,
        style: { t: 456, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.cortana,
        style: { t: 491, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.excel,
        style: { t: 560, l: 53, w: 140, h: 30 },
    },
    {
        name: START_BUTTONS.office,
        style: { t: 47, l: 310, w: 96, h: 96 },
        subMenu: {
            image: OfficeMenuExpanded,
            style: { t: 144, l: 310, w: 296, h: 384 },
            buttons: [
                {
                    name: START_BUTTONS.outlook,
                    style: { t: 164, l: 310, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.word,
                    style: { t: 164, l: 410, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.excel,
                    style: { t: 164, l: 509, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.ppt,
                    style: { t: 264, l: 310, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.oneNote,
                    style: { t: 264, l: 410, w: 96, h: 96 },
                },
            ]
        }
    },
    {
        name: START_BUTTONS.apps,
        style: { t: 47, l: 410, w: 96, h: 96 },
        subMenu: {
            image: AppsMenuExpanded,
            style: { t: 144, l: 310, w: 301, h: 449 },
            buttons: [
                {
                    name: START_BUTTONS.outlook,
                    style: { t: 181, l: 310, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.word,
                    style: { t: 181, l: 410, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.excel,
                    style: { t: 181, l: 509, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.oneDrive,
                    style: { t: 281, l: 310, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.ppt,
                    style: { t: 281, l: 410, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.oneNote,
                    style: { t: 281, l: 509, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.skype,
                    style: { t: 381, l: 310, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.edge,
                    style: { t: 494, l: 310, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.photos,
                    style: { t: 494, l: 410, w: 96, h: 96 },
                },
                {
                    name: START_BUTTONS.disney,
                    style: { t: 494, l: 509, w: 96, h: 96 },
                },
            ]
        }
    },
    {
        name: START_BUTTONS.email,
        style: { t: 47, l: 509, w: 96, h: 96 },
    },
    {
        name: START_BUTTONS.edge,
        style: { t: 146, l: 310, w: 96, h: 96 },
    },
    {
        name: START_BUTTONS.photos,
        style: { t: 146, l: 410, w: 96, h: 96 },
    },
    {
        name: START_BUTTONS.disney,
        style: { t: 146, l: 509, w: 96, h: 96 },
    },

    {
        name: START_BUTTONS.store,
        style: { t: 280, l: 310, w: 96, h: 96 },
    },
    {
        name: START_BUTTONS.weather,
        style: { t: 280, l: 410, w: 96, h: 96 },
    },
    {
        name: START_BUTTONS.redFolder,
        style: { t: 280, l: 509, w: 96, h: 96 },
    },
    {
        name: START_BUTTONS.films,
        style: { t: 380, l: 310, w: 96, h: 96 },
    },
    {
        name: START_BUTTONS.spotify,
        style: { t: 380, l: 410, w: 96, h: 96 },
    },
    {
        name: START_BUTTONS.games,
        style: { t: 380, l: 509, w: 96, h: 96 },
    },





]