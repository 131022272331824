import { HOME_BUTTONS } from '../button_names';
import DropDown from '../../../../images/ppt/draw-options.png';
import SlideOptions from '../../../../images/ppt/slide-menu.png'
import SlideLayout from '../../../../images/ppt/slide-layout.png'
import { DRAW_BUTTONS } from '../button_names';
import { SLIDE_BUTTONS, SLIDE_LAYOUT_BUTTONS } from '../../../../components/Emulators/constants';
export const home_buttons = [
    {
        name: HOME_BUTTONS.shapes,
        style: { t: 7, l: 564, w: 40, h: 60 },
        dropdown: {
            image: {
                style: {t: 70, l: 500, w: 250, h: 588 },
                src: DropDown
            },
                 buttons: [
                     {
                       name: DRAW_BUTTONS.elipse,
                        style: { t: 28, l: 2, w: 18, h: 20 },
                    },
               
               ]
        }
    },
    {
        name: SLIDE_BUTTONS.newSlide,
        style: {t: 7, l: 102, w: 40, h: 34}
    },
    {
        name: HOME_BUTTONS.slideOptions, 
        style: {t: 41, l: 102, w: 40, h: 34},
        dropdown: {
            image: {
                style: {t: 75, l: 102, w: 330, h: 407},
                src: SlideOptions
            },
                 buttons: [
                     {
                       name: SLIDE_BUTTONS.titleSlide,
                        style: { t: 30, l: 4, w: 98, h: 60 },
                    },
               
               ]
        }
    },
    {
        name: HOME_BUTTONS.slideLayout, 
        style: {t: 7, l: 143, w: 30, h: 20},
        dropdown: {
            image: {
                style: {t: 75, l: 102, w: 330, h: 407},
                src: SlideLayout
            },
                 buttons: [
                     {
                       name: SLIDE_LAYOUT_BUTTONS.layoutTitleSlide,
                        style: { t: 30, l: 4, w: 98, h: 60 },
                    },
               
               ]
        }
    }
]