
export const WINDOW_ITEMS = [
    {
        name: 'Minimize',
        style: { t: 3, l: 860, w: 22, h: 22 },
    },
    {
        name: 'Maximize',
        style: { t: 3, l: 903, w: 22, h: 22 },
    },
    {
        name: 'Close',
        style: { t: 3, l: 947, w: 22, h: 22 },
    },
]