export const BOTTOM_BAR_BUTTONS = {
    start: 'Start',
    circle: 'Circle',
    video: 'Video',
    folders: 'Folders',
    chrome: 'Chrome',
    excel: 'Excel',
    outlook: 'Outlook'
}

export const TABBAR_BUTTONS = {
    pin: 'Pin',
    delete: 'Delete',
    newFolder: 'NewFolder',
    newItem: 'NewItem',
    easyAccess: 'EasyAccess',
    properties: 'Properties',
    rename: 'Rename'
}

export const START_BUTTONS = {
    videoEditor: 'VideoEditor',
    word: 'Word',
    ppt: 'Powepoint',
    threeDViewer: '3DViewer',
    access: "Access",
    alarms: 'Alarms',
    calculator: 'Calculator',
    calendar: 'Calendar',
    camera: 'Camera',
    cortana: 'Cortana',
    excel: 'Excel',
    office: 'Office',
    outlook: 'Outlook',
    oneNote: 'OneNote',
    oneDrive: 'OneDrive',
    skype: 'Skype',
    apps: 'Apps',
    email: 'Email',
    edge: 'Edge',
    photos: 'Photos',
    disney: 'Disney',
    store: 'Store',
    weather: 'Weather',
    redFolder: 'RedFolder',
    films: 'Films',
    spotify: 'Spotify',
    games: 'Games',
}

export const MYCOMPUTER_BUTTONS = {
    desktop: 'Desktop',
    downloads: 'Downloads',
    documents: 'Documents',
    pictures: 'Pictures',
    music: 'Music',
    project: 'Projects',
    videos: 'Videos',
    oneDrive: 'OneDrive',
    thisPC: 'ThisPC',
    network: 'Network'
}

