import React, { useState, Fragment } from 'react';

import ToolBarDropdown from '../../ToolBarDropdown';
import FileWindow from './Components/FileWindow';
import { WINDOW_ITEMS } from './Elements/window_items';
import TABBAR_ITEMS from './Elements/tabbar_items';

import AppFrameImg from '../../../images/word/app_frame.jpg';
import ImageFadeIn from 'react-image-fade-in';

import cx from 'classnames';
import styles from './Word.module.scss';
import { elStyle } from '../../../constants/utils';
import { FILE_TYPE_BUTTONS } from './button_names';

const Word = ({ step, handleClick, handleForm }) => {

    const [activeToolbar, setActiveToolbar] = useState(1);

    const [fileType, setFileType] = useState(FILE_TYPE_BUTTONS.docx);

    const onElClick = (btn, trackPrevValues) => {
        handleClick(btn, trackPrevValues);
    }

    return (
        <div className={styles.app}>
            <ImageFadeIn className={styles.appBg} src={AppFrameImg} opacityTransition={0.1} alt='Appframe' />
            <ul className='appbar-links'>
                {WINDOW_ITEMS.map((button, i) => {
                    return (
                        <li key={`window-link-${i}`} className='appbar-link' >
                            <button
                                name={button.name}
                                onClick={() => onElClick(button)}
                                className={cx(styles.appWindowBtn, 'img-btn')}
                                style={elStyle(button)}
                            ></button>
                        </li>
                    )
                })}
            </ul>
            <div className='toolbar'>
                {
                    TABBAR_ITEMS.map((tab, i) => {
                        return (
                            <Fragment key={`tab-${i}`}>
                                <button
                                    name={tab.name}
                                    onClick={() => setActiveToolbar(i)}
                                    className={cx('img-btn')}
                                    style={elStyle(tab)}
                                ></button>
                                <img
                                    key={`toolbar-${i}`}
                                    className={cx('toolbar-bg', { 'toolbar-bg-full-screen': i === 0 }, { 'u-display-none': i !== activeToolbar })}
                                    src={tab.image}
                                    alt=''
                                />
                            </Fragment>
                        )
                    })
                }
                {
                    activeToolbar === 0 ? (
                        <FileWindow
                            fileMenu={TABBAR_ITEMS[0]}
                            setActiveToolbar={setActiveToolbar}
                            handleForm={handleForm}
                            onElClick={onElClick}
                            step={step}
                            fileType={fileType}
                            setFileType={setFileType}
                        />) :
                        <div className='toolbar-content'>
                            {
                                TABBAR_ITEMS[activeToolbar].mainButtons && TABBAR_ITEMS[activeToolbar].mainButtons.length > 0 && TABBAR_ITEMS[activeToolbar].mainButtons.map((button, i) => {
                                    return (
                                        <Fragment key={`toolbarbtn-${i}}`}>
                                            {button.dropdown ? (
                                                <ToolBarDropdown
                                                    item={button}
                                                    classNames='img-btn'
                                                    onElClick={onElClick}
                                                    styles={elStyle(button)}
                                                />
                                            ) : <button
                                                name={button.name}
                                                onClick={() => onElClick(button)}
                                                className={button.name === 'FakeButton' ? 'abs-btn-no-hover' : 'img-btn'}
                                                style={elStyle(button)}></button>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                }

            </div>


            {step.images && step.images.filter(img => img.type === 'dialog').map((image, i) => {
                return <div key={`step-image-${i}`} className={styles.appDialog} style={elStyle(image)} >
                    <img className='el-shadow' src={image.src} alt='' />
                    {step.buttons && step.buttons.length > 0 && step.buttons.map((button, i) => {
                        return <button
                            key={`step-btn-${i}`}
                            name={button.name}
                            onClick={() => onElClick(button)}
                            className={cx('img-btn')}
                            style={elStyle(button)}
                        ></button>
                    })}
                </div>
            })}
            <div className={styles.appContentArea}>
                {step.images && step.images.filter(img => img.type === 'content').map((image, i) => (
                    <div key={`start_images-${i}`}>
                        <img style={elStyle(image)} src={image.src} alt='' />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Word;