import { SKILLSETS, FINAL_STEP } from '../../utils';
import { SEND_RECEIVE_BUTTONS } from '../../../components/Emulators/Outlook/button_names';

export const q1 = {
    orderid: 1,
    emulator: { type: 'outlook', name: 'Microsoft Outlook' },
    skillset: SKILLSETS.outlook,
    instructions: ['Manually check for new mail'],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: SEND_RECEIVE_BUTTONS.sendReceiveAll,
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/shared/email-default.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
            ],
        },
        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/shared/email-default.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
            ],
        },
    ],
};