
import { HOME_BUTTONS, EMAIL_BUTTONS, JUNK_BUTTONS } from '../button_names';
import EmailDropdown from '../../../../images/outlook/tabs/menu-new-email.png';
import MoreDropdown from '../../../../images/outlook/tabs/menu-more.png';
import JunkDropdown from '../../../../images/outlook/tabs/menu-more-junk.png';

export const home_buttons = [
    {

        name: 'EMAIL',
        style: { t: 8, l: 10, w: 110, h: 25 },
        image: {
            style: { t: 33, l: 8, w: 172, h: 228 },
            src: EmailDropdown
        },
        mainButtons: [
            {
                name: EMAIL_BUTTONS.email,
                style: { t: 4, l: 2, w: 167, h: 22 },
            },
            {
                name: EMAIL_BUTTONS.appointment,
                style: { t: 11, l: 2, w: 167, h: 22 },
            },
            {
                name: EMAIL_BUTTONS.meeting,
                style: { t: 16, l: 2, w: 167, h: 22 },
            },
            {
                name: EMAIL_BUTTONS.contact,
                style: { t: 21, l: 2, w: 167, h: 22 },
            },
            {
                name: EMAIL_BUTTONS.task,
                style: { t: 26, l: 2, w: 167, h: 22 },
            },
            {
                name: EMAIL_BUTTONS.emailUsing,
                style: { t: 36, l: 2, w: 167, h: 22 },
            },
            {
                name: EMAIL_BUTTONS.more,
                style: { t: 40, l: 2, w: 167, h: 22 },
            },
            {
                name: EMAIL_BUTTONS.skype,
                style: { t: 48, l: 2, w: 167, h: 22 },
            },
        ]
    },
    {
        name: HOME_BUTTONS.delete,
        style: { t: 8, l: 134, w: 25, h: 25 },
    },
    {
        name: HOME_BUTTONS.folder,
        style: { t: 8, l: 189, w: 25, h: 25 },
    },
    {
        name: HOME_BUTTONS.download,
        style: { t: 8, l: 224, w: 25, h: 25 },
    },
    {
        name: HOME_BUTTONS.reply,
        style: { t: 8, l: 279, w: 25, h: 25 },
    },
    {
        name: HOME_BUTTONS.replyAll,
        style: { t: 8, l: 314, w: 25, h: 25 },
    },
    {
        name: HOME_BUTTONS.forwardFullScreen,
        style: { t: 8, l: 348, w: 25, h: 25 },
    },
    {
        name: 'More',
        style: { t: 8, l: 920, w: 25, h: 25 },
        image: {
            style: { t: 33, l: 770, w: 177, h: 318 },
            src: MoreDropdown
        },
        mainButtons: [
            {
                name: 'OneNote',
                style: { t: 33, l: 2, w: 177, h: 22 },
            },
            {
                name: 'Junk',
                style: { t: 39, l: 2, w: 177, h: 22 },
                dropdown: {
                    left: true,
                    image: {
                        style: { t: 0, l: 0, w: 300, h: 173 },
                        src: JunkDropdown
                    },
                    buttons: [
                        {
                            name: JUNK_BUTTONS.blockSender,
                            style: { t: 5, l: 2, w: 296, h: 22 },
                        },
                        {
                            name: JUNK_BUTTONS.neverBlockSender,
                            style: { t: 32, l: 2, w: 296, h: 22 },
                        },
                        {
                            name: JUNK_BUTTONS.neverBlockDomain,
                            style: { t: 60, l: 2, w: 296, h: 22 },
                        },
                        {
                            name: JUNK_BUTTONS.neverBlockGroup,
                            style: { t: 87, l: 2, w: 296, h: 22 },
                        },
                        {
                            name: JUNK_BUTTONS.notJunk,
                            style: { t: 118, l: 2, w: 296, h: 22 },
                        },
                        {
                            name: JUNK_BUTTONS.junkOptions,
                            style: { t: 145, l: 2, w: 296, h: 22 },
                        },
                    ],
                }
            },
            {
                name: 'Rules',
                style: { t: 44, l: 2, w: 177, h: 22 },
            },
            {
                name: 'Meeting',
                style: { t: 80, l: 2, w: 177, h: 22 },
            },
            {
                name: 'FwdAsAttachment',
                style: { t: 86, l: 2, w: 177, h: 22 },
            },
            {
                name: 'QuickSteps',
                style: { t: 122, l: 2, w: 177, h: 22 },
            },
            {
                name: 'ReadAloud',
                style: { t: 160, l: 2, w: 177, h: 22 },
            },
        ]
    }
]