import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useEffectOnce } from 'react-use';
import ls from 'local-storage';
import Ssl from '../../../utils/Ssl';
import { Router } from '@reach/router';
import LoggedInRouter from './LoggedInRouter';
import { useApolloClient } from '@apollo/client'

import { AuthContext } from '../../../services/auth/AuthProvider';
import { LOGGED_IN } from '../services/graphql';

import WarningSmallScreen from '../../../components/WarningSmallScreen';
import Dashboard from '../../../scenes/Dashboard';
import Login from '../../../scenes/Auth/Login';
import RouteCapture from './RouteCapture';

const RouterAuthQuery = () => {
    const { data, loading, error } = useQuery(LOGGED_IN);
    const [ready, setReady] = useState(false);

    const auth = useContext(AuthContext);

    const client = useApolloClient();

    useEffect(() => {
        if (ls('ce_candidate_token') && !auth.currentUser && ls('ce_candidate_user') && ls('ce_candidate_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('ce_candidate_user')));
        }
    }, [auth]);

    useEffectOnce(() => {
        if (ls('ce_candidate_token') && !auth.currentUser && ls('ce_candidate_user') && ls('ce_candidate_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('ce_candidate_user')));
        }
        setReady(true);
    });

    useEffect(() => {
        if (ls('ce_candidate_user') && auth.currentUser && !auth.currentUser.assessment) {
            ls.clear();
            client.clearStore();
            auth.setCurrentUser(null);
        }
    }, [auth]);


    const renderContent = () => {
        if (error) {
            return <p>{error.message}</p>
        }

        if (loading) {
            return 'Loading'
        }

        if (data && ready) {
            return (
                <Fragment>
                    <Router>
                        <RouteCapture path="/" >
                        {
                            auth.currentUser && auth.currentUser.assessment ? (
                                <LoggedInRouter path='/' default>
                                    <Dashboard path='/' default />
                                </LoggedInRouter>
                            ) : (
                                <Fragment>
                                    <Login path='/' default />
                                </Fragment>
                            )
                        }
                        </RouteCapture>
                    </Router>
                </Fragment>
            )
        }
    }

    return (
        <Fragment>
            <Ssl />
            <div className='u-display-block u-display-lg-none'>
                <WarningSmallScreen />
            </div>
            <div className='u-display-none u-display-lg-block'>
                {renderContent()}
            </div>
        </Fragment>
    );

}

export default RouterAuthQuery;
