
import { chromeQuestions } from './questions/chrome/all';
import { ppQuestions } from './questions/ppt/all';
import { wordQuestions } from './questions/word/all';
import { excelQuestions } from './questions/excel/all';
import { outlookQuestions } from './questions/outlook/all';
import { windowsQuestions } from './questions/windows/all';

export const QUESTIONS = [
    ...windowsQuestions,
    ...outlookQuestions,
    ...chromeQuestions,
    ...wordQuestions,
    ...ppQuestions,
    ...excelQuestions,
]