import React, { useState, useEffect, Fragment } from 'react';
import InfoModal from './components/InfoModal';
import { ANSWER_OPTS as ANSWERS } from '../../constants/utils';
import Toast from './components/Toast';
import Emulator from './Emulator';
import styles from './Assessment.module.scss';
import cx from 'classnames';
import { AlertCircle } from 'react-feather';

const Question = ({ qData, question, setQData, onSave, savingAnswer, setPulseAnimation, handleChange }) => {

    const [showAnswerModal, setShowAnswerModal] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);

    useEffect(() => {
        setShowInstructions(true);
    }, [question]);

    useEffect(() => {
        if (!showInstructions && !qData.startedAt) {
            const d1 = new Date();
            setQData({
                ...qData,
                startedAt: d1.toISOString().slice(0, -5)
            });
        }
    }, [showInstructions, setQData, qData])

    useEffect(() => {
        if ((qData && qData.userAnswer === ANSWERS.wrong) || (qData && qData.currentStep && qData.currentStep.name === 'final')) {
            setShowAnswerModal(true);
        }
        if (qData && qData.userAnswer === null) {
            setShowAnswerModal(false);
        }
    }, [qData]);

    const checkNextStep = (prevValues, nextStep) => {
        // for questions that depend on previous selections (eg. PPT correct slide selected),
        // also check prevValues is correct, otherwise, if there's a next step, return true
        return (
            (nextStep && qData.currentStep.has_prev_selection && qData.currentStep.has_prev_selection.value === prevValues) ||
            (nextStep && prevValues === undefined)
        )
    }

    const onAreaClick = (btn, prevValues) => {
        const nextStep = qData.currentStep.routes.find(r => r.name === btn.name);
        if (checkNextStep(prevValues, nextStep)) {
            setQData({
                ...qData,
                currentStep: question.steps.find(step => step.id === nextStep.nextId),
                userAnswer: question.steps.find(step => step.id === nextStep.nextId).name === 'final' ? ANSWERS.correct : ANSWERS.next
            });

        } else {
            setQData({
                ...qData,
                userAnswer: ANSWERS.wrong,
                failedAttempts: qData.failedAttempts + 1,
            });
        }
    }
 


    const onAreaSubmit = (data, form, prevValues) => {
        const nextStep = qData.currentStep.routes.find(r => r.name === form.name);
        let validInputs = false;
        form.inputs.forEach(input => {
            if (input.check_value.toLowerCase() === data[input.name].toLowerCase()) {
                validInputs = true;

            }
        });
        if (validInputs && checkNextStep(prevValues, nextStep)) {
            setQData({
                ...qData,
                currentStep: question.steps.find(step => step.id === nextStep.nextId),
                userAnswer: ANSWERS.correct,
            });
        } else {
            setQData({
                ...qData,
                userAnswer: ANSWERS.wrong,
                failedAttempts: qData.failedAttempts + 1,
            });
        }
    }


    const onReset = () => {
        setShowAnswerModal(false);
        setQData({
            ...qData,
            currentStep: question.steps[0],
            userAnswer: null,
        });
    }

    const hideModalInstructions = () => {
        setShowInstructions(false);
        setPulseAnimation(true);
    }

    return (
        <Fragment>
            <Emulator
                type={question.emulator.type}
                step={qData.currentStep}
                question={question}
                handleClick={onAreaClick}
                handleForm={onAreaSubmit}
                handleChange={handleChange}
            />
            {showAnswerModal && (
                <InfoModal
                    onReset={onReset}
                    qData={qData}
                    onSave={onSave}
                    savingAnswer={savingAnswer}
                />
            )}
            <div className={cx({ [styles.instructionsOverlay]: showInstructions }, { 'fadeIn': showInstructions }, { 'fadeOut': !showInstructions })}>
                <div className={styles.instructionsModal}>
                <button className={cx( styles.instructionsModalHideBtn)} onClick={() => hideModalInstructions()}><AlertCircle /></button>

                    <h2>{question.emulator.name}</h2>
                    <h3>Instructions</h3>
                    <ul className={cx('u-m-base-3', styles.instructionsText)}>
                        {question.instructions.map((txt, i) => <li key={`ins-${i}`}>{txt}</li>)}
                    </ul>
                    {
                        question.copyTexts && question.copyTexts.length > 0 && (
                            <ul className={styles.copyList}>
                                {question.copyTexts.map((el, i) => {
                                    return <li key={`text-${i}`}>
                                        <div className={styles.copyListLabel}>{el.label}:</div>
                                        <div>{el.text}</div>
                                    </li>
                                })}
                            </ul>
                        )
                    }
                    <button className={cx('btn btn-white u-m-top-3', styles.instructionsModalMainBtn)} onClick={() => hideModalInstructions()}>Continue</button>
                </div>
            </div>
            {!showInstructions && question.copyTexts && question.copyTexts.length > 0 && <Toast copyTexts={question.copyTexts} />}
        </Fragment>
    );
}

export default Question;