import { gql } from '@apollo/client';

// Start Assessment Mutation
export const START_ASSESSMENT_MUTATION = gql`
mutation startAssessment($code: ID!) {
    startAssessment(code: $code) {
        assessment {
            id
            candidate {
                id
                email
                name
                firstName
                lastName
                assessment {
                    id
                }
            }
        }
        token
        errors
    }
}
`;