
export const WINDOW_ITEMS = [
    {
        name: 'Save',
        style: { t: 4, l: 118, w: 24, h: 24 },
    },
    {
        name: 'Undo',
        style: { t: 4, l: 148, w: 28, h: 24 },
    },
    {
        name: 'Minimize',
        style: { t: 5, l: 876, w: 22, h: 22 },
    },
    {
        name: 'Maximize',
        style: { t: 5, l: 912, w: 22, h: 22 },
    },
    {
        name: 'Close',
        style: { t: 5, l: 949, w: 22, h: 22 },
    },
]