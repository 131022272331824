import BookMarkAddMenu from '../../../../images/chrome/taskbar-bookmarks-addmenu.png';
import BookMarkEditMenu from '../../../../images/chrome/taskbar-bookmarks-editmenu.png';

import BookMarkAddedOption from '../../../../images/chrome/taskbar-bookmarks-added.png';
import BookMarkEditOption from '../../../../images/chrome/taskbar-bookmarks-edit.png';
import { BOOKMARK_MENU_TYPES, BOOKMARK_BUTTONS } from '../button_names';

export const BOOKMARK_MENU = [
    {
        type: BOOKMARK_MENU_TYPES.add,
        style: { t: 46, l: 847, w: 24, h: 24, radius: '50%' },
        image: {
            src: BookMarkAddMenu,
            style: { t: 68, l: 705, w: 168, h: 54 }
        },
        mainButtons: [
            {
                name: BOOKMARK_BUTTONS.bookmarkAdd,
                style: { t: 5, l: 0, w: 167, h: 24 },
                dropdown: {
                    image: {
                        src: BookMarkAddedOption,
                        style: { t: 0, l: -148, w: 321, h: 324 }
                    },
                    buttons: [
                        {
                            name: BOOKMARK_BUTTONS.more,
                            style: { t: 155, l: 18, w: 75, h: 32 }
                        },
                        {
                            name: BOOKMARK_BUTTONS.remove,
                            style: { t: 155, l: 230, w: 75, h: 32 }
                        },
                    ]
                }
            },
            {
                name: 'Add to reading list',
                style: { t: 2, l: 0, w: 167, h: 24 }
            },
        ]
    },
    {
        type: BOOKMARK_MENU_TYPES.edit,
        style: { t: 46, l: 848, w: 24, h: 24, radius: '50%' },
        image: {
            src: BookMarkEditMenu,
            style: { t: 68, l: 705, w: 168, h: 54 }
        },
        mainButtons: [
            {
                name: 'etc...',
                style: { t: 5, l: 0, w: 167, h: 24 },
                dropdown: {
                    image: {
                        src: BookMarkEditOption,
                        style: { t: 0, l: -148, w: 321, h: 324 }
                    },
                    buttons: [
                        {
                            name: BOOKMARK_BUTTONS.more,
                            style: { t: 155, l: 16, w: 76, h: 32 }
                        },
                        {
                            name: BOOKMARK_BUTTONS.remove,
                            style: { t: 155, l: 228, w: 76, h: 32 }
                        },
                    ]
                }
            },
            {
                name: 'Add to reading list',
                style: { t: 2, l: 0, w: 167, h: 24 }
            },
        ]
    },

]
