import TabInsertPicture from '../../../../images/word/tabs/toolbar_insert_picture.png';
import TabInsertFooter from '../../../../images/word/tabs/toolbar-insert-footer.png';
import { PICTURE_BUTTONS, FOOTER_BUTTONS } from '../button_names';

export const insert_buttons = [
    {
        name: 'Pages',
        style: { t: 5, l: 5, w: 48, h: 60 },
    },
    {
        name: 'Table',
        style: { t: 5, l: 60, w: 48, h: 62 },
    },
    {
        name: 'Pictures',
        style: { t: 10, l: 113, w: 46, h: 60 },
        dropdown: {
            image: {
                style: { t: 45, l: 125, w: 174, h: 96 },
                src: TabInsertPicture
            },
            buttons: [
                {
                    name: PICTURE_BUTTONS.thisDevice,
                    style: { t: 33, l: 1, w: 143, h: 24 },
                },
                {
                    name: PICTURE_BUTTONS.stockImage,
                    style: { t: 60, l: 1, w: 143, h: 24 },
                },
                {
                    name: PICTURE_BUTTONS.onlinePictures,
                    style: { t: 88, l: 1, w: 143, h: 24 },
                },
            ],
        },
    },
    {
        name: 'Shapes',
        style: { t: 5, l: 160, w: 70, h: 20 },
    },
    {
        name: 'Icons',
        style: { t: 28, l: 160, w: 70, h: 20 },
    },
    {
        name: '3DModels',
        style: { t: 50, l: 160, w: 80, h: 20 },
    },
    {
        name: 'SmartArt',
        style: { t: 5, l: 260, w: 70, h: 20 },
    },
    {
        name: 'Chart',
        style: { t: 28, l: 260, w: 70, h: 20 },
    },
    {
        name: 'Screenshot',
        style: { t: 50, l: 260, w: 90, h: 20 },
    },
    {
        name: 'ReuseFiles',
        style: { t: 5, l: 360, w: 57, h: 70 },
    },
    {
        name: 'AddIns',
        style: { t: 5, l: 425, w: 32, h: 62 },
    },
    {
        name: 'OnlineVideos',
        style: { t: 5, l: 468, w: 50, h: 70 },
    },
    {
        name: 'Links',
        style: { t: 5, l: 530, w: 41, h: 65 },
    },
    {
        name: 'Comment',
        style: { t: 5, l: 580, w: 60, h: 65 },
    },
    {
        name: 'Header',
        style: { t: 5, l: 650, w: 75, h: 22 },
    },
    {
        name: 'Footer',
        style: { t:30, l: 650, w: 75, h: 20 },
        dropdown: {
            image: {
                style: { t: 46, l: 400, w: 403, h: 454 },
                src: TabInsertFooter
            },
            buttons: [
                {
                    name: FOOTER_BUTTONS.blank,
                    style: { t: 33, l: 13, w: 365, h: 95 },
                },
                {
                    name: FOOTER_BUTTONS.number,
                    style: { t: 136, l: 13, w: 365, h: 95 },
                },
                {
                    name: FOOTER_BUTTONS.number, //NEED THIS TWICE!!!
                    style: { t: 240, l: 13, w: 365, h: 95 },
                },
                {
                    name: FOOTER_BUTTONS.more, 
                    style: { t: 345, l: 13, w: 365, h: 22 },
                },
                {
                    name: FOOTER_BUTTONS.edit,
                    style: { t: 372, l: 13, w: 365, h: 22 },
                },
                {
                    name: FOOTER_BUTTONS.remove,
                    style: { t: 400, l: 13, w: 365, h: 22 },
                },
            ],
        },
    },
    {
        name: 'PageNumber',
        style: { t:52, l: 650, w: 110, h: 20 },
    },
]