export const ANSWER_OPTS = {
    wrong: -1,
    next: 99,
    correct: 100,
}

export const SKILLSETS = {
    word: 'Word',
    powerpoint: 'PowerPoint',
    chrome: 'Chrome',
    windows: 'Windows',
    excel: 'Excel',
    outlook: 'Outlook'
}

export const FINAL_STEP = 'final';

export const ALLOWED_ATTEMPTS = 2;

export const elStyle = el => {
    return {
        top: el.style.t,
        left: el.style.l,
        width: el.style.w,
        height: el.style.h,
        ...(el.style.zIndex && { zIndex: el.style.zIndex }),
        ...(el.style.radius && { borderRadius: el.style.radius }),
        ...(el.style.cursor && { cursor: el.style.cursor }),
    }
}


// get question images from steps for preloading
export const imagesToPreload = (question, setEmulatorLoading) => {
    const all = [];
    question.steps.forEach(step => {
        if (step.images && step.images.length > 0) {
            all.push(...step.images);
        }
    });
    const uniqueImages = [...new Map(all.map(img => [img['src'], img])).values()];
    // console.log('uniqueImages', uniqueImages);

    if (uniqueImages && uniqueImages.length > 0) {
        let count = 0;
        uniqueImages.forEach((image) => {
            const img = new Image();
            img.src = image.src;
            img.onload = () => {
                count = count + 1;
                if (count === uniqueImages.length) {
                    setEmulatorLoading(false);
                }
            }
        })
    } else {
        setEmulatorLoading(false);
    }
}