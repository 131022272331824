import { HOME_BUTTONS, BULLETS_BUTTONS } from '../button_names';
import BulletDropdown from '../../../../images/word/tabs/menu-bullet-types.png';

export const home_buttons = [
    {
        name: HOME_BUTTONS.paste,
        style: { t: 7, l: 10, w: 40, h: 60 },
    },
    {
        name: HOME_BUTTONS.copyFormat,
        style: { t: 52, l: 50, w: 22, h: 20 },
    },
    {
        name: HOME_BUTTONS.bold,
        style: { t: 44, l: 86, w: 20, h: 20 },
    },
    {
        name: HOME_BUTTONS.italic,
        style: { t: 44, l: 108, w: 20, h: 20 },
    },
    {
        name: HOME_BUTTONS.underline,
        style: { t: 44, l: 130, w: 20, h: 20 },
    },
    {
        name: HOME_BUTTONS.increaseFont,
        style: { t: 15, l: 235, w: 20, h: 20 },
    },
    {
        name: HOME_BUTTONS.decreaseFont,
        style: { t: 15, l: 255, w: 20, h: 20 },
    },
    {
        name: HOME_BUTTONS.changeCase,
        style: { t: 15, l: 285, w: 24, h: 20 },
    },
    {
        name: HOME_BUTTONS.textEffect,
        style: { t: 44, l: 238, w: 28, h: 20 },
    },
    {
        name: HOME_BUTTONS.textHighlight,
        style: { t: 44, l: 274, w: 29, h: 20 },
    },
    {
        name: HOME_BUTTONS.fontColor,
        style: { t: 44, l: 310, w: 29, h: 20 },
    },
    {
        name: HOME_BUTTONS.alignLeft,
        style: { t: 29, l: 359, w: 22, h: 22 },
    },
    {
        name: HOME_BUTTONS.centerText,
        style: { t: 29, l: 382, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.alignRight,
        style: { t: 29, l: 405, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.justify,
        style: { t: 29, l: 426, w: 20, h: 22 },
    },
    {
        name: HOME_BUTTONS.bullets,
        style: { t: 5, l: 359, w: 22, h: 22 },
    },
    {
        name: 'BulletDropdown',
        style: { t: 5, l: 381, w: 12, h: 22 },
        dropdown: {
            image: {
                style: { t: 30, l: 360, w: 296, h: 368 },
                src: BulletDropdown
            },
            buttons: [
                {
                    name: BULLETS_BUTTONS.disc,
                    style: { t: 34, l: 5, w: 40, h: 40 },
                },
                {
                    name: BULLETS_BUTTONS.none,
                    style: { t: 118, l: 5, w: 40, h: 40 },
                },
                {
                    name: BULLETS_BUTTONS.disc2,
                    style: { t: 118, l: 50, w: 40, h: 40 },
                },
                {
                    name: BULLETS_BUTTONS.circle,
                    style: { t: 118, l: 98, w: 40, h: 40 },
                },
                {
                    name: BULLETS_BUTTONS.square,
                    style: { t: 118, l: 144, w: 40, h: 40 },
                },
                {
                    name: BULLETS_BUTTONS.color,
                    style: { t: 118, l: 190, w: 40, h: 40 },
                },
                {
                    name: BULLETS_BUTTONS.diamond,
                    style: { t: 118, l: 235, w: 40, h: 40 },
                },

                {
                    name: BULLETS_BUTTONS.check,
                    style: { t: 164, l: 5, w: 40, h: 40 },
                },
                {
                    name: BULLETS_BUTTONS.tick,
                    style: { t: 164, l: 50, w: 40, h: 40 },
                },

            ],
        }
    },
  
]