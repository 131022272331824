import React, { Fragment, useState, useContext } from 'react';
import { useQuery } from '@apollo/client'
import { AuthContext } from '../../services/auth/AuthProvider';
import Header from '../../components/Header';
import Intro from './components/Intro';
import Assessment from '../Assessment';
import { ASSESSMENT_QUERY } from './services/graphql';

const Dashboard = () => {

    const [startPractice, setStartPractice] = useState(false);
    const { currentUser } = useContext(AuthContext);

    const { data, error, loading } = useQuery(ASSESSMENT_QUERY, {
        variables: {
            id: currentUser.assessment.id
        },
    });

    const renderContent = () => {
        const { progress, nextQuestion, questionsCounter } = data.assessment;

        if (progress === 0 && !startPractice) {
            return (
                <Fragment>
                    <Header />
                    <Intro onFinishPractice={() => setStartPractice(true)} />
                </Fragment>
            )
        }

        return (
            <Assessment
                assessmentId={currentUser.assessment.id}
                initialQuestion={nextQuestion}
                progress={progress}
                qCounter={questionsCounter}
            />
        )
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <p>Loading...</p>
    }

    if (data && data.assessment) {
        return (
            <Fragment>
                {renderContent()}
            </Fragment>
        )
    }
}

export default Dashboard;
