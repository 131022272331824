import { SKILLSETS, FINAL_STEP } from '../../utils';
import { BROWSER_BUTTONS, BOOKMARK_MENU_TYPES, BOOKMARK_BUTTONS } from '../../../components/Emulators/Chrome/button_names';

export const q5 = {
    orderid: 5,
    emulator: { type: 'chrome', name: 'Chrome' },
    skillset: SKILLSETS.chrome,
    instructions: ['Without using any keyboard shortcuts, add a bookmark for the currently displayed webpage and give it a custom title of: "Customer Support"'],
    attempts: 2,
    copyTexts: [
        { label: 'Bookmark title', text: "Customer Support" }
    ],
    steps: [
        {
            id: 1,
            bookMarkType: BOOKMARK_MENU_TYPES.add,
            buttons: [
                {
                    type: 'appbar',
                    name: BROWSER_BUTTONS.newTab,
                    style: { t: 12, l: 246, w: 22, h: 22, radius: '50%' },
                },
            ],
            routes: [
                {
                    nextId: 2,
                    name: BOOKMARK_BUTTONS.bookmarkTab,
                },
                {
                    name: BOOKMARK_BUTTONS.done,
                    nextId: 3,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q5/browser-t5-1.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
            ],
            form: {
                style: { t: 58, l: 71, w: 240, h: 100, zIndex: 13 },
                name: BOOKMARK_BUTTONS.done,
                type: 'bookmark',
                inputs: [
                    {
                        check_value: 'Customer Support',
                        initial_value: 'Republic Home',
                        name: 'bookmarkinput',
                        style: { t: 0, l: 0, w: 200, h: 26 },
                    },
                ],
                button: {
                    name: 'Done',
                    style: { t: 98, l: 74, w: 75, h: 32 }
                }
            }
        },
        {
            id: 2,
            triggerBookMarkMenu: true,
            routes: [
                {
                    name: BOOKMARK_BUTTONS.done,
                    nextId: 3,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q5/browser-t5-1.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
            ],
            form: {
                style: { t: 58, l: 71, w: 240, h: 100, zIndex: 13 },
                name: BOOKMARK_BUTTONS.done,
                type: 'bookmark',
                inputs: [
                    {
                        check_value: 'Customer Support',
                        initial_value: 'Republic Home',
                        name: 'bookmarkinput',
                        style: { t: 0, l: 0, w: 200, h: 26 },
                    },
                ],
                button: {
                    id: 2,
                    name: BOOKMARK_BUTTONS.done,
                    style: { t: 98, l: 74, w: 75, h: 32 }
                }
            }

        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q5/browser-t5-1.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                }
            ]
        },
    ],
};