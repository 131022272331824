import React from 'react';
import { ApolloProvider } from '@apollo/client/react';
import { client } from '../../utils/client';

import { AuthProvider } from '../../services/auth/AuthProvider';
import RouterAuthQuery from './components/RouterAuthQuery';


const Home = () => {
    return (
            <ApolloProvider client={client} >
                <AuthProvider>
                    <RouterAuthQuery />
                </AuthProvider>
            </ApolloProvider>
    )
}

export default Home;