export const BROWSER_BUTTONS = {
    newTab: 'New Tab',
    closeTab: 'Close Tab',
    refresh: 'Refresh'
}


export const MAINMENU_BUTTONS = {
    newTab: 'NewTab',
    newWindow: 'NewWindow',
    newIncognitoWindow: 'NewIncognitoWindow',
    history: 'History',
    downloads: 'Downloads',
    bookMarks: 'BookMarks',
    print: 'Print',
    cast: 'Cast',
    find: 'Find',
    moreTools: 'MoreTools',
    settings: 'Settings',
    help: 'Help',
    exit: 'Exit',
}

export const HISTORY_BUTTONS = {
    settings: 'Settings',
    history: 'History'
}

export const BOOKMARK_BUTTONS = {
    bookmarkTab: 'Bookmark Tab',
    bookmarkAdd: 'Add Bookmark',
    showBookmarksBar: 'Show Bookmarks bar',
    bookmarksManager: 'Bookmarks Manager',
    importBookmarks: 'Import Bookmarks',
    more: 'More',
    done: 'Done',
    remove: 'Remove'
}

export const BOOKMARK_ACTIONS_BUTTONS = {
    edit: 'Edit',
    delete: 'Delete',
    cut: 'Cut',
    copy: 'Copy URL',
    paste: 'Paste',
    openInNewTab: 'Open in new tab',
    openInNewWindow: 'Open in new window',
    openInIncognitoWindow: 'Open in Incognito window'
}

export const BOOKMARK_MENU_TYPES = {
    add: 'add',
    edit: 'edit'
}

export const TOOLS_BUTTONS = {
    savePageAs: 'Save Page As',
    nameWindow: 'Name Window',
    clearBrowsingData: 'Clear Browsing Data',
    extenstions: 'Extentions',
    taskManager: "Task Manager",
    developerTools: 'Developer tools'
}

export const HELP_BUTTONS = {
    aboutChrome: 'About Google Chrome',
    helpCenter: 'Help Center',
    reportIssue: 'Report an issue'
}