
import { INSERT_BUTTONS } from '../button_names';

export const insert_buttons = [
    {
        name: INSERT_BUTTONS.tables,
        style: { t: 3, l: 9, w: 36, h: 36 },
    },
    {
        name: INSERT_BUTTONS.illustrations,
        style: { t: 3, l: 70, w: 40, h: 36 },
    },
    {
        name: INSERT_BUTTONS.addins,
        style: { t: 3, l: 134, w: 39, h: 36 },
    },
    {
        name: INSERT_BUTTONS.recommendedCharts,
        style: { t: 3, l: 204, w: 45, h: 37 },
    },
    {
        name: INSERT_BUTTONS.column,
        style: { t: 4, l: 269, w: 20, h: 22 },
    },

    {
        name: INSERT_BUTTONS.line,
        style: { t: 28, l: 269, w: 20, h: 20 },
    },
    {
        name: INSERT_BUTTONS.pie,
        style: { t: 52, l: 269, w: 20, h: 20 },
    },


    {
        name: INSERT_BUTTONS.maps,
        style: { t: 3, l: 373, w: 39, h: 38 },
    },
    {
        name: INSERT_BUTTONS.pivotChart,
        style: { t: 3, l: 421, w: 39, h: 38 },
    },
    {
        name: INSERT_BUTTONS.threeDMap,
        style: { t: 3, l: 484, w: 39, h: 38 },
    },
    {
        name: INSERT_BUTTONS.sparklines,
        style: { t: 3, l: 546, w: 39, h: 36 },
    },
    {
        name: INSERT_BUTTONS.filters,
        style: { t: 3, l: 605, w: 39, h: 36 },
    },
    {
        name: INSERT_BUTTONS.link,
        style: { t: 3, l: 660, w: 39, h: 36 },
    },
    {
        name: INSERT_BUTTONS.comment,
        style: { t: 3, l: 722, w: 39, h: 36 },
    },
    {
        name: INSERT_BUTTONS.text,
        style: { t: 3, l: 784, w: 39, h: 36 },
    },
    {
        name: INSERT_BUTTONS.symbols,
        style: { t: 3, l: 838, w: 39, h: 36 },
    },
]