import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { elStyle } from '../../../../constants/utils';

const SelectFileType = ({  classNames, styles, item, setFileType, fileType }) => {

    const [visible, setVisible] = useState();
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <div ref={ref}>
            <button
                name={item.name}
                style={styles}
                className={classNames}
                onClick={() => setVisible(!visible)}
            >{fileType}</button>

            <div className={cx('appbar-dropdown', { 'u-display-none': !visible })} style={elStyle(item.image)}>
                {item.image && <img className='el-shadow' src={item.image.src} alt='' />}
                {item.mainButtons.length > 0 &&
                    <ul className='appbar-menu-list'>
                        {item.mainButtons.map((button, i) => {
                            return (
                                <li style={elStyle(button)} className={cx('appbar-menu-item', { 'appbar-menu-item-with-hover': button.dropdown })} key={`sdfsd-${i}`}>
                                    <button
                                        name={button.name}
                                        className='hover-dropdown-btn'
                                        onClick={() => {setVisible(false); setFileType(button.name)}}
                                    ></button>
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    );
};

export default SelectFileType;
