
import TabHome from '../../../../images/windows/tabs/folder-fs-home-tab.png';
import TabShare from '../../../../images/windows/tabs/folder-fs-share-tab.png';
import TabView from '../../../../images/windows/tabs/folder-fs-view-tab.png';
import { TABBAR_BUTTONS } from '../button_names';


const FakeButtonFull = [{
    id: 1,
    name: 'FakeButton',
    style: { t: 46, l: 10, w: 940, h: 80 },
}]

const TABBAR_ITEMS = [
    {
        name: 'File',
        style: { t: 20, l: 1, w: 50, h: 24 },
        image: TabHome,

    },
    {
        name: 'Home',
        style: { t: 20, l: 55, w: 52, h: 24 },
        image: TabHome,
        mainButtons: [
            {
                name: TABBAR_BUTTONS.pin,
                style: { t: 46, l: 1, w: 67, h: 72 },
            },
            {
                name: TABBAR_BUTTONS.delete,
                style: { t: 46, l: 327, w: 42, h: 66 },
            },
            {
                name: TABBAR_BUTTONS.newFolder,
                style: { t: 46, l: 418, w: 40, h: 66 },
            },
            {
                name: TABBAR_BUTTONS.rename,
                style: { t: 46, l: 370, w: 42, h: 66 },
            },
            {
                name: TABBAR_BUTTONS.newItem,
                style: { t: 48, l: 460, w: 76, h: 20 },
            },
            {
                name: TABBAR_BUTTONS.easyAccess,
                style: { t: 70, l: 460, w: 82, h: 20 },
            },
            {
                name: TABBAR_BUTTONS.properties,
                style: { t: 48, l: 551, w: 52, h: 62 },
            },
        ]
    },

    {
        name: 'Share',
        style: { t: 20, l: 108, w: 53, h: 24 },
        image: TabShare,
        mainButtons: FakeButtonFull
    },
    {
        name: 'View',
        style: { t: 20, l: 162, w: 40, h: 24 },
        image: TabView,
        mainButtons: FakeButtonFull
    },
];


export default TABBAR_ITEMS;