import { SKILLSETS, FINAL_STEP } from '../../utils';
import { SLIDESHOW_BUTTONS } from '../../../components/Emulators/PowerPoint/button_names';

export const pq5 = {
    orderid: 5,
    emulator: { type: 'powerpoint', name: 'Microsoft PowerPoint' },
    skillset: SKILLSETS.powerpoint,
    instructions: ['Start the slide show presentation.'],
    attempts: 2,
    steps: [
        {
            id: 1,
            has_prev_selection: {
                type: 'selected_slide',
                value: 0
            },
            routes: [
                {
                    name: SLIDESHOW_BUTTONS.start,
                    nextId: 2,
                }
            ],
            buttons: [
                {
                    type: 'bottomBar',
                    name: SLIDESHOW_BUTTONS.start,
                    style: { t: 708, l: 752, w: 38, h: 20 },
                }
            ],
            images: [
                {
                    src: './simulator/ppt/shared/start_image_slide_1.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/start_image_slide_2.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ]
        },
        {
            id: 2,
            name: FINAL_STEP,
            images: [
                {
                    src: './simulator/ppt/shared/start_image_slide_1.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ],
        }
    ],
};