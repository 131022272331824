import { SIDEBAR_BUTTONS } from '../button_names';

const SIDEBAR_ITEMS = [
    {
        name: SIDEBAR_BUTTONS.inbox,
        style: { t: 247, l: 7, w: 210, h: 22 },
    },
    {
        name: SIDEBAR_BUTTONS.drafts,
        style: { t: 270, l: 7, w: 210, h: 22 },
    },
    {
        name: SIDEBAR_BUTTONS.sent,
        style: { t: 293, l: 7, w: 210, h: 22 },
    },
    {
        name: SIDEBAR_BUTTONS.deleted,
        style: { t: 316, l: 7, w: 210, h: 22 },
    },
    {
        name: SIDEBAR_BUTTONS.archive,
        style: { t: 339, l: 7, w: 210, h: 22 },
    },
    {
        name: SIDEBAR_BUTTONS.history,
        style: { t: 362, l: 7, w: 210, h: 22 },
    },
    {
        name: SIDEBAR_BUTTONS.junkEmail,
        style: { t: 385, l: 7, w: 210, h: 22 },
    },
    {
        name: SIDEBAR_BUTTONS.outlook,
        style: { t: 408, l: 7, w: 210, h: 22 },
    },
    {
        name: SIDEBAR_BUTTONS.rssFeed,
        style: { t: 431, l: 7, w: 210, h: 22 },
    },
    {
        name: SIDEBAR_BUTTONS.searchFolders,
        style: { t: 454, l: 7, w: 210, h: 22 },
    },
];

export default SIDEBAR_ITEMS;