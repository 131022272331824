import React from 'react';
import { useTransition } from 'react-spring';

import QuestionWrapper from './QuestionWrapper';

const QuestionTransition = ({ onSaveAnser, question, savingAnswer }) => {

    const transition = useTransition(question, {
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    });

    return transition((style, item) => <QuestionWrapper style={style} onSaveAnser={onSaveAnser} question={item} savingAnswer={savingAnswer}  />)
}

export default QuestionTransition;