
export const HOME_BUTTONS = {
    paste: 'Paste',
    copyFormat: 'Copy Format',
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    increaseFont: 'IncreaseFont',
    decreaseFont: 'DecreaseFont',
    changeCase: 'ChangeCase',
    textEffect: 'TextEffect',
    textHighlight: 'TextHighlight',
    fontColor: 'FontColor',
    alignLeft: 'AlignLeft',
    centerText: 'CenterText',
    alignRight: 'AlignRight',
    justify: 'Justify',
    bulletNone: 'BulletNone',
    bulletDisc: 'BulletDisc',
    slideOptions: 'SlideOptions',
    slideLayout: 'SlideLayout'
}

export const TAB_INSERT_BUTTONS = {
    pictureFromFile: 'pictureFromFile',
    photoBrowser: 'PhotoBrowser',
    stockImage: 'StockImage',
    onlinePictures: 'OnlinePictures',
    text: 'TextBox'
}

export const DRAW_BUTTONS = {
    elipse: 'elipse',
}

export const SLIDESHOW_BUTTONS = {
    start: 'Start',
    startCurrent: 'StartCurrent'
}