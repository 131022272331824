import { SKILLSETS, FINAL_STEP } from '../../utils';
import { JUNK_BUTTONS, SIDEBAR_BUTTONS } from '../../../components/Emulators/Outlook/button_names';

const msgRightClickButtons = [
    {
        name: 'Copy',
    },
    {
        name: 'QuickPrint',
        top: 2
    },
    {
        name: 'CtxReply',
        top: 10
    },
    {
        name: 'CtxReplyAll',
        top: 4
    },
    {
        name: 'CtxFwd',
        top: 8
    },
    {
        name: 'CtxMarkUnread',
        top: 10
    },
    {
        name: 'CtxCategorize',
        top: 5
    },
    {
        name: 'CtxFollowUp',
        top: 5
    },
    {
        name: 'CtxFindRelated',
        top: 9
    },
    {
        name: 'CtxQuickSteps',
        top: 9
    },
    {
        name: 'CtxQuickActions',
        top: 5
    },
    {
        name: 'CtxRules',
        top: 5
    },
    {
        name: 'CtxMove',
        top: 6
    },
    {
        name: 'CtxOneNote',
        top: 9
    },
    {
        name: 'CtxIgnore',
        top: 9
    },
    {
        name: 'Junk',
        top: 7,
        submenu: [
            {
                name: JUNK_BUTTONS.blockSender
            },
            {
                name: JUNK_BUTTONS.neverBlockSender,
                top: 3
            },
            {
                name: JUNK_BUTTONS.neverBlockDomain,
                top:5
            },
            {
                name: JUNK_BUTTONS.neverBlockGroup,
                top:7
            },
            {
                name: JUNK_BUTTONS.notJunk,
                top: 9
            },
            {
                name: JUNK_BUTTONS.junkOptions,
                top: 8
            },
        ]
    },
];

export const q6 = {
    orderid: 6,
    emulator: { type: 'outlook', name: 'Microsoft Outlook' },
    skillset: SKILLSETS.outlook,
    instructions: [
        'You’re expecting an email from a supplier called Raul Radu but it isn\'t showing up in your inbox.',
        'Check if the email has been incorrectly labeled as "Junk".',
        'If it has, mark it as "Not Junk" so that any future emails from Raul will be correctly routed to your inbox.'
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: SIDEBAR_BUTTONS.junkEmail,
                    nextId: 2
                },
            ],
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/shared/email-default.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
            ],
            buttons: [
                {
                    type: 'msgThumb',
                    name: 'wrong0',
                    style: { t: 58, l: 222, w: 265, h: 72 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong1',
                    style: { t: 131, l: 222, w: 265, h: 72 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong2',
                    style: { t: 205, l: 222, w: 265, h: 72 }
                },
            ]
        },
        {
            name: 'Junk',
            id: 2,
            routes: [
                {
                    name: JUNK_BUTTONS.notJunk,
                    nextId: 3
                },
            ],
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/q6/t6-junk-folder.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
            ],
            buttons: [
                {
                    type: 'msgThumb',
                    name: 'RightClick',
                    style: { t: 54, l: 222, w: 265, h: 72 },
                    contextMenu: {
                        id: 'MessageContentMenu',
                        kls: 'msgRightClick',
                        buttons: msgRightClickButtons
                    }
                },
            ]
        },
        {
            name: 'Confirm Step',
            id: 3,
            routes: [
                {
                    name: 'OK',
                    nextId: 4,
                }
            ],
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/q6/t6-junk-folder.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
                {
                    type: 'fullScreen',
                    kls: 'dialogShadow',
                    src: './simulator/outlook/q6/t6-dialogue-confirm.png',
                    style: { t: 200, l: 400, w: 258, h: 127 }
                },
            ],
            buttons: [
                {
                    type: 'content',
                    name: 'OK',
                    style: { t: 186, l: 580, w: 71, h: 24, zIndex: 11 }
                }
            ]

        },
        {
            name: FINAL_STEP,
            id: 4,
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/q6/t6-result.png',
                    style: { t: 0, l: 0, w: 980, h: 727 }
                },
            ],
        },
    ],
};