import { SKILLSETS, FINAL_STEP } from '../../utils';
import { DRAW_BUTTONS } from '../../../components/Emulators/PowerPoint/button_names';

export const pq1 = {
    orderid: 1,
    emulator: { type: 'powerpoint', name: 'Microsoft PowerPoint' },
    skillset: SKILLSETS.powerpoint,
    instructions: ['Add a circle to Slide 1 (the current slide). The circle can be any size or color and can be placed anywhere on the slide.'],
    attempts: 2,
    steps: [
        {
            id: 1,
            has_prev_selection: {
                type: 'selected_slide',
                value: 0
            },
            routes: [
                {
                    name: DRAW_BUTTONS.elipse,
                    nextId: 2,
                }
            ],
            images: [
                {
                    src: './simulator/ppt/shared/start_image_slide_1.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/start_image_slide_2.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ]
        },
        {
            id: 2,
            name: FINAL_STEP,
            images: [
                {
                    src: './simulator/ppt/q1/final-image.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ],
        }
    ],
};