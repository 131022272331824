import { SKILLSETS, FINAL_STEP } from '../../utils';
import { TAB_INSERT_BUTTONS } from '../../../components/Emulators/PowerPoint/button_names';
export const pq4 = {
    orderid: 4,
    emulator: { type: 'powerpoint', name: 'Microsoft PowerPoint' },
    skillset: SKILLSETS.powerpoint,
    instructions: ["Insert the following text on the current slide. You can use any text style (title/body) or formatting."],
    copyTexts: [
        { label: 'Text', text: 'Our services' }
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            has_prev_selection: {
                type: 'selected_slide',
                value: 0
            },
            routes: [
                {
                    name: TAB_INSERT_BUTTONS.text,
                    nextId: 2,
                },
            ],
            images: [
                {
                    src: './simulator/ppt/shared/start_image_slide_1.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/start_image_slide_2.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ]
        },
        {
            id: 2,
            routes: [
                {
                    name: 'Text',
                    nextId: 3,
                },
            ],
            images: [
                {
                    src: './simulator/ppt/q4/text-box.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/start_image_slide_2.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ],
            form: {
                style: { t: 273, l: 756, w: 90, h: 80 },
                name: 'Text',
                type: 'content',
                inputs: [
                    {
                        check_value: 'our services',
                        initial_value: '',
                        name: 'textBox',
                        style: { t: 5, l: 7, w: 80, h: 21 },
                        hasBlur: true,
                    },
                ],
            }
        },
        {
            id: 3,
            name: FINAL_STEP,
            images: [
                {
                    src: './simulator/ppt/q4/text-box.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ],
        }
    ],
};