
import TabHome from '../../../../images/ppt/tabs/tab-home.png';
import TabInsert from '../../../../images/ppt/tabs/tab-insert.png';
import TabDraw from '../../../../images/ppt/tabs/tab-draw.png';
import TabDesign from '../../../../images/ppt/tabs/tab-design.png';
import TabTransitions from '../../../../images/ppt/tabs/tab-transitions.png';
import TabAnimations from '../../../../images/ppt/tabs/tab-animations.png';
import TabSlideShow from '../../../../images/ppt/tabs/tab-slide-show.png';
import TabReview from '../../../../images/ppt/tabs/tab-review.png';
import TabView from '../../../../images/ppt/tabs/tab-view.png';
import TabRecording from '../../../../images/ppt/tabs/tab-recording.png';
import TabHelp from '../../../../images/ppt/tabs/tab-help.png';



import { home_buttons } from './home_buttons';
import { insert_buttons } from './insert_buttons';
import { slideshow_buttons } from './slideshow_buttons';

const FakeButtonFull = [{
    id: 1,
    name: 'FakeButton',
    style: { t: 10, l: 10, w: 940, h: 80 },
}]

const TABBAR_ITEMS = [
    {
        name: 'File',
        style: { t: 36, l: 5, w: 50, h: 24 },
        image: TabHome,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Home',
        style: { t: 36, l: 60, w: 52, h: 24 },
        image: TabHome,
        mainButtons: home_buttons
    },
    {
        name: 'Insert',
        style: { t: 36, l: 123, w: 47, h: 24 },
        image: TabInsert,
        mainButtons: insert_buttons
    },
    {
        name: 'Draw',
        style: { t: 36, l: 182, w: 42, h: 24 },
        image: TabDraw,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Design',
        style: { t: 36, l: 239, w: 50, h: 24 },
        image: TabDesign,
        mainButtons: FakeButtonFull

    },
    {
        name: 'Transitions',
        style: { t: 36, l: 304, w: 72, h: 24 },
        image: TabTransitions,
        mainButtons: FakeButtonFull

    },
    {
        name: 'Animations',
        style: { t: 36, l: 392, w: 76, h: 24 },
        image: TabAnimations,
        mainButtons: FakeButtonFull
    },
    {
        name: 'SlideShow',
        style: { t: 36, l: 483, w: 74, h: 24 },
        image: TabSlideShow,
        mainButtons: slideshow_buttons
    },
    {
        name: 'Review',
        style: { t: 36, l: 570, w: 55, h: 24 },
        image: TabReview,
        mainButtons: FakeButtonFull
    },
    {
        name: 'View',
        style: { t: 36, l: 637, w: 40, h: 24 },
        image: TabView,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Recording',
        style: { t: 36, l: 690, w: 70, h: 24 },
        image: TabRecording,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Help',
        style: { t: 36, l: 773, w: 40, h: 24 },
        image: TabHelp,
        mainButtons: FakeButtonFull
    },
];


export default TABBAR_ITEMS;