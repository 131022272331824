import React, { useContext } from 'react';
import ImageFadeIn from 'react-image-fade-in';
import LogoutButton from '../../../../components/LogoutButton';

import { CheckCircle, LogOut } from 'react-feather';
import logo from '../../../../images/republic-logo-white.png';
import styles from './Progress.module.scss';
import { AuthContext } from '../../../../services/auth/AuthProvider';

const Progress = ({ progress, qCounter }) => {

    const { currentUser } = useContext(AuthContext);

    return (
        <header className={styles.progress}>
            <ImageFadeIn width={152} alt="Republic Logo" src={logo} />
            {qCounter === progress ? (
                <div className={styles.progressDone}><CheckCircle size="16" /> Completed</div>
            ) : (
                <div className={styles.progressWrapper}>
                    Assessment progress
                    <div className={styles.progressHolder}>
                        <div className={styles.progressBar} style={{ width: `${(progress / qCounter) * 100}%` }}></div>
                    </div>
                </div>
            )}

            <div className={styles.user}>{currentUser.name} <LogoutButton classes='btn-reset'><LogOut size='16' /></LogoutButton></div>
        </header>
    );
}

export default Progress;
