import { SKILLSETS, FINAL_STEP } from '../../utils';
import { FOOTER_BUTTONS } from '../../../components/Emulators/Word/button_names';

export const q5 = {
    orderid: 5,
    emulator: { type: 'word', name: 'Microsoft Word' },
    skillset: SKILLSETS.word,
    instructions: [
        'On the current document, add a footer that automatically shows the correct page number.',
        'Hint: Select a preset footer that already includes the page number'
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: FOOTER_BUTTONS.number,
                    nextId: 2,
                }
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/word/shared/content_start.png',
                    style: { t: 0, l: 0, w: 606, h: 490 }
                },
            ]
        },

        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    src: './simulator/word/shared/content_start.png',
                    style: { t: 0, l: 0, w: 606, h: 490 }
                }
            ]
        },
    ],
};