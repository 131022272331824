export const HOME_BUTTONS = {
  newEmail: 'NewEmail',
  delete: 'Delete',
  folder: 'Folder',
  download: 'Download',
  reply: 'Reply',
  replyAll: 'Reply All',
  forwardFullScreen: 'Forward',
  categorize: 'Categorize',
  flag: 'Flag'
}

export const CONTENT_BUTTONS = {
  fwdPreview: 'ForwardPreview',
  replyPreview: 'ReplyPreview',
  replyAllPreview: 'ReplyAllPreview',
}

export const MSG_FULL_BUTTONS = {
  deleteFullScreen: 'DeleteFullScreen',
  replyFullScreen: 'ReplyFullScreen',
  replyAllFullScreen: 'ReplyAllFullScreen'
}


export const SIDEBAR_BUTTONS = {
  inbox: 'Inbox',
  drafts: 'Drafts',
  sent: 'Sent',
  deleted: 'Deleted',
  archive: 'Archive',
  history: 'History',
  junkEmail: 'JunkEmail',
  outlook: 'Outlook',
  rssFeed: 'RSSFeed',
  searchFolders: 'SearchFolders'
}

export const EMAIL_BUTTONS = {
    email: 'Email',
    appointment: 'Appointment',
    meeting: 'Meeting',
    contact: 'Contact',
    task: 'Task',
    emailUsing: 'Email message using',
    more: 'More Items',
    skype: 'Skype Message'
}

export const SEND_RECEIVE_BUTTONS = {
  sendReceiveAll: 'SendReceiveAll',
  sendAll: 'Send all',
  updateFolder: 'Update Folder',
  sendReceiveGroups: 'SendReceiveGroups',
  showProgress: 'ShowProgress',
  clearAll: 'ClearAll'
}

export const JUNK_BUTTONS = {
  blockSender: 'BlockSender',
  neverBlockSender: 'NeverBlockSender',
  neverBlockDomain: 'NeverBlockDomain',
  neverBlockGroup: 'NeverBlockGroup',
  notJunk: 'NotJunk',
  junkOptions: 'JunkOptions'
}