
import TabHome from '../../../../images/excel/tabs/tab-home.png';
import TabInsert from '../../../../images/excel/tabs/tab-insert.png';
import TabDraw from '../../../../images/excel/tabs/tab-draw.png';
import TabLayout from '../../../../images/excel/tabs/tab-page-layout.png';
import TabFormulas from '../../../../images/excel/tabs/tab-formulas.png';
import TabData from '../../../../images/excel/tabs/tab-data.png';
import TabReview from '../../../../images/excel/tabs/tab-review.png';
import TabView from '../../../../images/excel/tabs/tab-view.png';
import TabHelp from '../../../../images/excel/tabs/tab-help.png';

import { home_buttons } from './home_buttons';
import { insert_buttons } from './insert_buttons';

const FakeButtonFull = [{
    name: 'FakeButton',
    style: { t: 10, l: 10, w: 940, h: 80 },
}]

const TABBAR_ITEMS = [
    {
        name: 'File',
        style: { t: 36, l: 5, w: 50, h: 24 },
        image: TabHome,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Home',
        style: { t: 36, l: 60, w: 50, h: 24 },
        image: TabHome,
        mainButtons: home_buttons
    },
    {
        name: 'Insert',
        style: { t: 36, l: 120, w: 45, h: 24 },
        image: TabInsert,
        mainButtons: insert_buttons
    },
    {
        name: 'Draw',
        style: { t: 36, l: 177, w: 45, h: 24 },
        image: TabDraw,
        mainButtons: FakeButtonFull
    },
    {
        name: 'PageLayout',
        style: { t: 36, l: 235, w: 85, h: 24 },
        image: TabLayout,
        mainButtons: FakeButtonFull

    },
    {
        name: 'Formulas',
        style: { t: 36, l: 330, w: 65, h: 24 },
        image: TabFormulas,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Data',
        style: { t: 36, l: 408, w: 42, h: 24 },
        image: TabData,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Review',
        style: { t: 36, l: 462, w: 50, h: 24 },
        image: TabReview,
        mainButtons: FakeButtonFull
    },
    {
        name: 'View',
        style: { t: 36, l: 528, w: 40, h: 24 },
        image: TabView,
        mainButtons: FakeButtonFull
    },
    {
        name: 'Help',
        style: { t: 36, l: 580, w: 40, h: 24 },
        image: TabHelp,
        mainButtons: FakeButtonFull
    },
];


export default TABBAR_ITEMS;