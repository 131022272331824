export const HOME_BUTTONS = {
    paste: 'Paste',
    copyFormat: 'Copy Format',
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    increaseFont: 'IncreaseFont',
    decreaseFont: 'DecreaseFont',
    fontColor: 'FontColor',
    alignToTop: 'AlignToTop',
    alignToMiddle: 'AlignToMiddle',
    alignToBottom: 'AlignToBottom',
    wrapText: 'WrapText',
    alignLeft: 'AlignLeft',
    centerText: 'CenterText',
    alignRight: 'AlignRight',
    mergeCentre: 'MergeCentre',
    delete: 'Delete',
    dataFormat: 'DataFormat',
    currencyFormat: 'CurrencyFormat',
    search: 'Search',
    conditionalF: 'ConditionalFormatting',
    formatAsTable: 'FormatAsTable',
    cellStyles: 'CellStyles',
    insert: 'Insert',
    format: 'Format'
}

export const DELETE_BUTTONS = {
    deleteColumn: 'DeleteColumn',
    deleteSheet: 'DeleteSheet'
}


export const SEARCH_BUTTONS = {
    find: 'Find',
    replace: 'Replace',
    goto: 'GoTo',
    gotoSpecial: 'GoToSpecial',
    formulas: 'Formulas',
    notes: 'Notes',
    conditional: 'ConditionalFormatting',
    constants: 'Constants',
    dataValidation: 'DataValidation',
    selectObjects: 'Select Objects',
    selectionPane: 'SelectionPane'
}


export const DATA_FORMAT_BUTTONS = {
    general: 'General',
    number: 'Number',
    currency: 'Currency',
    accounting: 'Accounting',
    shortDate: 'Short Date',
    longDate: 'Long Date',
    time:  'Time',
    percentage: 'Percentage',
    fraction: 'Fraction',
    scientific: 'Scientific',
    text: 'Text',
    pound: 'Pound',
    euro: 'Euro',
    yuan: 'Yuan',
    franc: 'Swiss Franc',
    moreOpts: 'More Accounting Options'
}



export const INSERT_BUTTONS = {
    tables: 'Tables',
    illustrations: 'Illustrations',
    addins: 'Addins',
    recommendedCharts: 'recommendedCharts',
    column: 'Column',
    line: 'Line',
    pie: 'Pie',
    maps: 'Maps',
    pivotChart: 'PivotChart',
    threeDMap: '3Dmap',
    sparklines: 'Sparklines',
    filters: 'Filters',
    link: 'Link',
    comment: 'Comment',
    text: 'Text',
    symbols: 'Symbols'
}

