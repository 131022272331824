import { MYCOMPUTER_BUTTONS } from '../button_names';

const MYCOMPUTER_ITEMS = [
    {
        name: MYCOMPUTER_BUTTONS.desktop,
        style: { t: 124, l: 26, w: 126, h: 18 },
    },
    {
        name: MYCOMPUTER_BUTTONS.downloads,
        style: { t: 146, l: 26, w: 126, h: 18 },
    },
    {
        name: MYCOMPUTER_BUTTONS.documents,
        style: { t: 168, l: 26, w: 126, h: 18 },
    },
    {
        name: MYCOMPUTER_BUTTONS.pictures,
        style: { t: 190, l: 26, w: 126, h: 18 },
    },
    {
        name: MYCOMPUTER_BUTTONS.music,
        style: { t: 214, l: 26, w: 126, h: 18 },
    },
    {
        name: MYCOMPUTER_BUTTONS.project,
        style: { t: 238, l: 26, w: 126, h: 18 },
    },
    {
        name: MYCOMPUTER_BUTTONS.videos,
        style: { t: 260, l: 26, w: 126, h: 18 },
    },
    {
        name: MYCOMPUTER_BUTTONS.oneDrive,
        style: { t: 290, l: 3, w: 126, h: 18 },
    },
    {
        name: MYCOMPUTER_BUTTONS.thisPC,
        style: { t: 321, l: 3, w: 126, h: 18 },
    },
    {
        name: MYCOMPUTER_BUTTONS.network,
        style: { t: 352, l: 3, w: 126, h: 18 },
    },
]

export default MYCOMPUTER_ITEMS;