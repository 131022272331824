
const WINDOW_ITEMS = [
    {
        name: 'SendReceiveAll',
        style: { t: 4, l: 13, w: 26, h: 26 },
    },
    {
        name: 'Minimize',
        style: { t: 5, l: 876, w: 22, h: 22 },
    },
    {
        name: 'Maximize',
        style: { t: 5, l: 912, w: 22, h: 22 },
    },
    {
        name: 'Close',
        style: { t: 5, l: 949, w: 22, h: 22 },
    },
];

export default WINDOW_ITEMS;