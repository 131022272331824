import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';
import { elStyle } from '../../../../../constants/utils';
import SelectFileType from '../SelectFileType';
import styles2 from '../../Word.module.scss';

const Dialog = ({ onElClick, handleForm, classNames, styles, button, step, fileType, setFileType }) => {

    const [visible, setVisible] = useState();
    const ref = useRef(null);

    const { register, handleSubmit } = useForm();

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && !event.target.classList.contains('jsIgnoreTarget')) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <div ref={ref}>
            <button
                name={button.name}
                style={styles}
                className={classNames}
                onClick={() => {onElClick(button); setVisible(!visible)}}
            ></button>

            <div className={cx('appbar-dropdown', { 'u-display-none': !visible })} style={elStyle(button.dialog.image)}>
                {button.dialog.image && <img className='el-shadow' src={button.dialog.image.src} alt='' />}
                {step.form && step.form.type === 'fileSaveAsDialog' && (
                    <form
                        onSubmit={handleSubmit(data => handleForm(data, step.form, step.has_prev_selection && fileType))}
                        className='form-area'
                        style={elStyle(step.form)}
                        name={step.form.type}
                    >
                        {step.form.inputs.map((input, i) => (
                            <input
                                key={`form-input-${i}`}
                                type="text"
                                style={elStyle(input)}
                                placeholder={input.placeholder}
                                name={input.name}
                                className='abs-pos'
                                {...register(input.name)}
                                defaultValue={input.initial_value}
                            />
                        ))}
                        {step.form.button && <button type='submit' name={step.form.button.name} className={cx('img-btn')}
                            style={elStyle(step.form.button)}></button>}
                    </form>
                )}

                {button.dialog.mainButtons.length > 0 &&
                    <ul className=''>
                        {button.dialog.mainButtons.map((button, i) => {
                            return button.dropdown ?
                                <SelectFileType
                                    key={`s-${i}`}
                                    item={button.dropdown}
                                    classNames={cx('img-btn', styles2.selectFileType)}
                                    styles={elStyle(button)}
                                    fileType={fileType}
                                    setFileType={setFileType}
                                /> : (
                                    <li key={`d-btn-${i}`}>
                                        <button
                                            name={button.name}
                                            className='img-btn'
                                            style={elStyle(button)}
                                            onClick={() => { onElClick(button); setVisible(false) }}
                                        ></button>
                                    </li>
                                )
                        })}
                    </ul>
                }
            </div>
        </div>
    );
};

export default Dialog;
