import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { elStyle } from '../../../../constants/utils';
import styles2 from '../Windows.module.scss';

const StartMenuDropDown = ({ onElClick, checkPrevSelection, classNames, styles, item }) => {

    const [visible, setVisible] = useState();
    const [showSubMenu, setShowSubMenu] = useState();

    const handleSubMenuClick = (i, button) => {
        if (button.subMenu) {
            showSubMenu === i ? setShowSubMenu(null) : setShowSubMenu(i);
        } else {
            onElClick(button, checkPrevSelection);
            setVisible(false);
        }
    }
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && !event.target.classList.contains('jsIgnoreTarget')) {
            setVisible(false);
            setShowSubMenu(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <div ref={ref}>
            <button
                name={item.name}
                style={styles}
                className={classNames}
                onClick={() => setVisible(!visible)}
            ></button>

            <div className={cx(styles2.startDropdown, { 'u-display-none': !visible })} style={elStyle(item.dropdown.image)}>
                {item.dropdown.image && <img src={item.dropdown.image.src} alt='' />}
                {item.dropdown.buttons.length > 0 && item.dropdown.buttons.map((button, i) => {
                    return (
                        <div key={`start-dropdown-${i}`}>
                            <button
                                name={button.name}
                                onClick={() => handleSubMenuClick(i, button)}
                                className={cx('img-btn', {[styles2.startBtnExpanded] : showSubMenu === i})}
                                style={elStyle(button)}
                            ></button>
                            <div className={cx(styles2.startMenuExpanded, { 'u-display-none': showSubMenu !== i })}>
                                {button.subMenu && <img className='abs-pos' style={elStyle(button.subMenu)} src={button.subMenu.image} alt='' />}
                                {button.subMenu && button.subMenu.buttons.map((button, i) => {
                                    return (
                                        <button
                                            key={`sub-menu-btn-${i}`}
                                            name={button.name}
                                            style={elStyle(button)}
                                            className='img-btn'
                                            onClick={() => {onElClick(button); setVisible(false)}}
                                        ></button>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default StartMenuDropDown;
