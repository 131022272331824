import MainMenuDropdown from '../../../../images/chrome/main-menu.png';
import MainMenuDropdownHistory from '../../../../images/chrome/main-menu-history.png';
import MainMenuDropdownBookmarks from '../../../../images/chrome/main-menu-bookmarks.png';
import MainMenuDropdownTools from '../../../../images/chrome/main-menu-tools.png';
import MainMenuDropdownHelp from '../../../../images/chrome/main-menu-help.png';

import { MAINMENU_BUTTONS, BROWSER_BUTTONS, HISTORY_BUTTONS, BOOKMARK_BUTTONS, TOOLS_BUTTONS, HELP_BUTTONS } from '../button_names';


export const MAIN_MENU = [
    {
        name: BROWSER_BUTTONS.closeTab,
        style: { t: 12, l: 211, w: 22, h: 22, radius: '50%'},
    },
    {
        name: BROWSER_BUTTONS.refresh,
        style: { t: 45, l: 70, w: 25, h: 25,  radius: '50%' },
    },
    {
        name: 'Main dropdown menu',
        style: { t: 46, l: 948, w: 22, h: 22,  radius: '50%' },
        image: {
            src: MainMenuDropdown,
            style: { t: 68, l: 692, w: 277, h: 399 }
        },
        mainButtons: [
            {
                name: MAINMENU_BUTTONS.newTab,
                style: { t: 5, l: 1, w: 275, h: 22 }
            },
            {
                name: MAINMENU_BUTTONS.newWindow,
                style: { t: 6, l: 1, w: 275, h: 22 }
            },
            {
                name: MAINMENU_BUTTONS.newIncognitoWindow,
                style: { t: 7, l: 1, w: 275, h: 22 }
            },
            {
                name: MAINMENU_BUTTONS.history,
                style: { t: 18, l: 1, w: 275, h: 22 },
                dropdown: {
                    left: true,
                    image: {
                        style: { t: 0, l: 0, w: 288, h: 122 },
                        src: MainMenuDropdownHistory
                    },
                    buttons: [
                        {
                            name: HISTORY_BUTTONS.history,
                            style: { t: 5, l: 6, w: 275, h: 24 },
                        },
                        {
                            name: HISTORY_BUTTONS.settings,
                            style: { t: 62, l: 6, w: 275, h: 24 },
                        },
                    ],
                },
            },
            {
                name: MAINMENU_BUTTONS.downloads,
                style: { t: 20, l: 1, w: 275, h: 22 }
            },
            {
                name: MAINMENU_BUTTONS.bookMarks,
                style: { t: 22, l: 1, w: 275, h: 22 },
                dropdown: {
                    left: true,
                    image: {
                        style: { t: 0, l: 0, w: 320, h: 214 },
                        src: MainMenuDropdownBookmarks
                    },
                    buttons: [
                        {
                            name: BOOKMARK_BUTTONS.bookmarkTab,
                            style: { t: 5, l: 6, w: 310, h: 24 },
                        },
                        {
                            name: BOOKMARK_BUTTONS.showBookmarksBar,
                            style: { t: 62, l: 6, w: 310, h: 24 },
                        },
                        {
                            name: BOOKMARK_BUTTONS.bookmarksManager,
                            style: { t: 85, l: 6, w: 310, h: 24 },
                        },
                        {
                            name: BOOKMARK_BUTTONS.importBookmarks,
                            style: { t: 108, l: 6, w: 310, h: 24 },
                        },
                    ],
                },
            },
            {
                name: MAINMENU_BUTTONS.print,
                style: { t: 57, l: 1, w: 275, h: 22 }
            },
            {
                name: MAINMENU_BUTTONS.cast,
                style: { t: 58, l: 1, w: 275, h: 22 }
            },
            {
                name: MAINMENU_BUTTONS.find,
                style: { t: 59, l: 1, w: 275, h: 22 }
            },
            {
                name: MAINMENU_BUTTONS.moreTools,
                style: { t: 60, l: 1, w: 275, h: 22 },
                dropdown: {
                    left: true,
                    image: {
                        style: { t: 0, l: 0, w: 278, h: 191 },
                        src: MainMenuDropdownTools
                    },
                    buttons: [
                        {
                            name: TOOLS_BUTTONS.savePageAs,
                            style: { t: 5, l: 6, w: 265, h: 24 },
                        },
                        {
                            name: TOOLS_BUTTONS.nameWindow,
                            style: { t: 50, l: 6, w: 265, h: 24 },
                        },
                        {
                            name: TOOLS_BUTTONS.clearBrowsingData,
                            style: { t: 84, l: 6, w: 265, h: 24 },
                        },
                        {
                            name: TOOLS_BUTTONS.extenstions,
                            style: { t: 108, l: 6, w: 265, h: 24 },
                        },
                        {
                            name: TOOLS_BUTTONS.taskManager,
                            style: { t: 130, l: 6, w: 265, h: 24 },
                        },
                        {
                            name: TOOLS_BUTTONS.developerTools,
                            style: { t: 164, l: 6, w: 265, h: 24 },
                        },
                    ],
                },
            },
            {
                name: MAINMENU_BUTTONS.settings,
                style: { t: 96, l: 1, w: 275, h: 22 }
            },
            {
                name: MAINMENU_BUTTONS.help,
                style: { t: 97, l: 1, w: 275, h: 22 },
                dropdown: {
                    left: true,
                    image: {
                        style: { t: 0, l: 0, w: 266, h: 77 },
                        src: MainMenuDropdownHelp
                    },
                    buttons: [
                        {
                            name: HELP_BUTTONS.aboutChrome,
                            style: { t: 5, l: 6, w: 255, h: 24 },
                        },
                        {
                            name: HELP_BUTTONS.helpCenter,
                            style: { t: 28, l: 6, w: 255, h: 24 },
                        },
                        {
                            name: HELP_BUTTONS.reportIssue,
                            style: { t: 51, l: 6, w: 255, h: 24 },
                        },

                    ],
                },
            },
            {
                name: MAINMENU_BUTTONS.exit,
                style: { t: 109, l: 1, w: 275, h: 22 }
            },
        ]
    }
];

