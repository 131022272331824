import { SKILLSETS, FINAL_STEP } from '../../utils';
import { HOME_BUTTONS, CONTENT_BUTTONS, MSG_FULL_BUTTONS } from '../../../components/Emulators/Outlook/button_names';
import { fullScreenEmailButtons } from './shared';

export const q2 = {
    orderid: 2,
    emulator: { type: 'outlook', name: 'Microsoft Outlook' },
    skillset: SKILLSETS.outlook,
    instructions: ['Forward the currently displayed email to the following email address: design@republicservices.com'],
    copyTexts: [
        { label: 'Email', text: 'design@republicservices.com' }
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'OpenFull', //Double Click message
                    nextId: 2,
                },
                {
                    name: HOME_BUTTONS.forwardFullScreen, // CLick on Appbar Fwd Icon
                    nextId: 3
                },
                {
                    name: CONTENT_BUTTONS.fwdPreview, // Fwd from Preview
                    nextId: 4
                }
            ],
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/shared/email-default.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
            ],
            buttons: [
                {
                    type: 'content',
                    name: CONTENT_BUTTONS.replyPreview,
                    style: { t: 40, l: 826, w: 30, h: 30 }
                },
                {
                    type: 'content',
                    name: CONTENT_BUTTONS.replyAllPreview,
                    style: { t: 40, l: 856, w: 30, h: 30 }
                },
                {
                    type: 'content',
                    name: CONTENT_BUTTONS.fwdPreview,
                    style: { t: 40, l: 886, w: 30, h: 30 }
                },
                {
                    type: 'content',
                    name: "More Dropdown",
                    style: { t: 40, l: 925, w: 30, h: 30 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong1',
                    style: { t: 58, l: 222, w: 265, h: 72 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong2',
                    style: { t: 131, l: 222, w: 265, h: 72 }
                },
                {
                    type: 'msgThumb',
                    name: 'OpenFull',
                    hasDoubleClick: true,
                    style: { t: 205, l: 222, w: 265, h: 72 }
                },
            ]
        },
        {
            name: 'Step2',
            id: 2,
            images: [
                {
                    type: 'fullScreen',
                    src: './simulator/outlook/q2/t2-popout-read.png',
                    style: { t: 0, l: 0, w: 980, h: 727 }
                },
            ],
            routes: [
                {
                    name: 'FWD',
                    nextId: 3
                }
            ],
            buttons: [
                {
                    name: 'FWD',  //FWD from topbar
                    type: 'fullScreenBtn',
                    style: { t: 68, l: 220, w: 28, h: 26, zIndex: 11 }
                },
                {
                    name: 'Reply',
                    type: 'fullScreenBtn',
                    style: { t: 146, l: 656, w: 76, h: 30, zIndex: 11 }
                },
                {
                    name: 'ReplyAll',
                    type: 'fullScreenBtn',
                    style: { t: 146, l: 736, w: 88, h: 30, zIndex: 11 }
                },
                {
                    name: 'FWD', //FWD from message 
                    type: 'fullScreenBtn',
                    style: { t: 146, l: 829, w: 88, h: 30, zIndex: 11 }
                },
                {
                    name: MSG_FULL_BUTTONS.deleteFullScreen,
                    type: 'fullScreenBtn',
                    style: { t: 68, l: 10, w: 24, h: 26, zIndex: 11 }
                },
                {
                    name: MSG_FULL_BUTTONS.replyFullScreen,
                    type: 'fullScreenBtn',
                    style: { t: 68, l: 154, w: 26, h: 26, zIndex: 11 }
                },
                {
                    name: MSG_FULL_BUTTONS.replyAllFullScreen,
                    type: 'fullScreenBtn',
                    style: { t: 68, l: 186, w: 26, h: 26, zIndex: 11 }
                },
            ]
        },
        {
            name: HOME_BUTTONS.forwardFullScreen,
            id: 3,
            images: [
                {
                    type: 'fullScreen',
                    src: './simulator/outlook/q2/t2-popout-forward.png',
                    style: { t: 0, l: 0, w: 980, h: 727 }
                },
            ],
            routes: [
                {
                    name: FINAL_STEP, // Name of Form
                    nextId: 5
                },
            ],
            buttons: [
                ...fullScreenEmailButtons
            ],
            form: {
                style: { t: 116, l: 17, w: 900, h: 26 },
                name: FINAL_STEP,
                type: 'fullScreenForm',
                inputs: [
                    {
                        check_value: 'design@republicservices.com',
                        name: 'addressinput',
                        style: { t: 5, l: 160, w: 600, h: 26 },
                    },
                ],
                button: {
                    style: { t: 0, l: 0, w: 56, h: 72 },
                    name: 'FWDFIELD'
                }
            },
        },
        {
            name: 'Step 4',
            id: 4,
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/q2/t2-preview-forward.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                },
            ],
            routes: [
                {
                    name: FINAL_STEP,
                    nextId: 5
                },
            ],
            form: {
                style: { t: 36, l: 538, w: 300, h: 200 },
                name: FINAL_STEP,
                type: 'content',
                inputs: [
                    {
                        id: 1,
                        check_value: 'design@republicservices.com',
                        initial_value: '',
                        placeholder: '',
                        name: 'addressinput',
                        style: { t: 5, l: 160, w: 264, h: 26 },
                    },
                ],
                button: {
                    style: { t: 0, l: 0, w: 56, h: 72 },
                    name: 'FWDFIELD'
                }
            },
            buttons: [
                {
                    type: 'msgThumb',
                    name: 'wrong1',
                    style: { t: 58, l: 222, w: 265, h: 72 }
                },
                {
                    type: 'msgThumb',
                    name: 'wrong2',
                    style: { t: 131, l: 222, w: 265, h: 72 }
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 5,
            images: [
                {
                    type: 'content',
                    kls: 'messagesBg',
                    src: './simulator/outlook/shared/email-default.png',
                    style: { t: 0, l: 0, w: 757, h: 598 }
                }
            ],
        },
    ],
};