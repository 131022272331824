import { SKILLSETS, FINAL_STEP } from '../../utils';
import { BROWSER_BUTTONS } from '../../../components/Emulators/Chrome/button_names';

export const q7 = {
    orderid: 7,
    emulator: { type: 'chrome', name: 'Chrome' },
    skillset: SKILLSETS.chrome,
    instructions: ['Without using any keyboard shortcuts, refresh the current web page'],
    attempts: 2,
    steps: [
        {
            id: 1,
            buttons: [
                {
                    type: 'appbar',
                    name: BROWSER_BUTTONS.newTab,
                    style: { t: 12, l: 246, w: 22, h: 22, radius: '50%' },
                },
            ],
            routes: [
                {
                    name: BROWSER_BUTTONS.refresh,
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/shared/browser-initial.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/shared/browser-initial.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                }
            ]
        },
    ],
};