
export const FILE_BUTTONS = {
    back: 'Back',
    new: 'New',
    open: 'Open',
    info: 'Info',
    save: 'Save',
    saveAs: 'Save As',
    print: 'Print',
    share: 'Share',
    export: 'Export',
    browse: 'Browse',
    createPdf: 'Create PDF'
}


export const HOME_BUTTONS = {
    paste: 'Paste',
    copyFormat: 'Copy Format',
    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    increaseFont: 'IncreaseFont',
    decreaseFont: 'DecreaseFont',
    changeCase: 'ChangeCase',
    textEffect: 'TextEffect',
    textHighlight: 'TextHighlight',
    fontColor: 'FontColor',
    alignLeft: 'AlignLeft',
    centerText: 'CenterText',
    alignRight: 'AlignRight',
    justify: 'Justify',
    bullets: 'Bullets'
}

export const PICTURE_BUTTONS = {
    thisDevice: 'This Device',
    stockImage: 'StockImage',
    onlinePictures: 'OnlinePictures'
}

export const FOOTER_BUTTONS = {
    blank: 'Blank',
    number: 'Number',
    more: 'More',
    edit: 'Edit',
    remove: 'Remove'
}

export const BULLETS_BUTTONS = {
    none: 'None',
    disc: 'Disc',
    disc2: 'Disc',
    circle: 'Circle',
    square: 'Square',
    color: 'Color',
    diamond: 'Diamond',
    check: 'Check',
    tick: 'Tick'
}

export const FILE_TYPE_BUTTONS = {
    docx: 'Word Document (*.docx)',
    docm: 'Word Macro-Enabled Document (*.docm)',
    doc: 'Word 97-2003 Document (*.doc)',
    dotx: 'Word Template (*.dotx)',
    dotm: 'Word Macro-Enabled Template (*.dotm)',
    dot: 'Word 97-2003 Template (*.dot)',
    pdf: 'PDF (*.pdf)',
    xps: 'XPS Document (*.xps)',
    mht: 'Single File WebPage (*.mht, *.mhtml)',
    htm: 'Web Page (*.htm, *.html)',
    htmf: 'Web Page, Filtered (*.htm, *.html)',
    rtf: 'Rich Text Format (*.rtf)',
    txt: 'Plain Text (*.txt)',
    xml: 'Word XML Document (*.xml)',
    xmlnew: 'Word 2003 XML Document (*.xml)',
    docxStrict: 'Strict Open XML Document (*.docx)',
    odt: 'Open Document Text (*.odt)'
}