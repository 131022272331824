import React from 'react';
import ImageFadeIn from 'react-image-fade-in';
import logo from '../../images/republic-logo-dark.png';

const WarningSmallScreen = () => {
    return (
        <div className='introPanel'>
            <div className='logo'>
                <ImageFadeIn width={152} alt="Republic Logo" src={logo} />
            </div>
            <div>
                <h1 className='h2'>Please use a device with a larger screen</h1>
                <p className='u-m-base-3'>We are looking to review your skills for desktop computing.</p>
                <p>Please take this short assessment on a computer with a screen resolution of at least 1024x768</p>
            </div>
        </div>
    );
}

export default WarningSmallScreen;
