import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { animated } from 'react-spring';
import { AlertCircle, Copy } from 'react-feather';
import Loader from '../../components/Loader';

import Question from './Question';
import { ANSWER_OPTS as ANSWERS, imagesToPreload } from '../../constants/utils';

import cx from 'classnames';
import styles from './Assessment.module.scss';

const QuestionWrapper = ({ question, onSaveAnser, savingAnswer, style }) => {

    const [qData, setQData] = useState(null);
    const [showSidebar, setShowSidebar] = useState(false);
    const [copyText, setCopyText] = useState(null);
    const [imagesLoading, setImagesLoading] = useState(true);
    const [pulseAnimation, setPulseAnimation] = useState(false);

    const [inputChangeVal, setInputChangeVal] = useState(null);

    useEffect(() => {
        setQData({
            currentStep: question.steps[0],
            failedAttempts: 0,
            userAnswer: null,
            startedAt: null,
            question: question
        });
        setInputChangeVal(null);
    }, [question]);

    useEffect(() => {
        imagesToPreload(question, setImagesLoading);
    }, [question]);


    // manually click on SubmitAnswer in the sidebar
    const onCheckAnswer = (qData) => {
        const inputStep = qData.currentStep.form && qData.currentStep.form.inputs[0];
        if (inputStep && inputStep.hasBlur && inputChangeVal && (inputStep.check_value.toLowerCase() === inputChangeVal.toLowerCase())) {
            setQData({
                ...qData,
                failedAttempts: qData.failedAttempts + 1,
                userAnswer: ANSWERS.correct,
                currentStep: {...qData.currentStep, name: 'final'}
            });
            return
        }

        if (qData.userAnswer !== ANSWERS.correct) {
            setQData({
                ...qData,
                failedAttempts: qData.failedAttempts + 1,
                userAnswer: ANSWERS.wrong
            });
        }
    }

    const onInputChange = (currVal) => {
        setInputChangeVal(currVal);
    }

    return (
        <animated.div style={style}>
            {imagesLoading ? <Loader /> :
                <div className={styles.wrapper}>
                    <div className={styles.wrapperMain}>
                        <div className='simWrapper'>
                            <Question
                                setQData={setQData}
                                question={question}
                                qData={qData}
                                onSave={onSaveAnser}
                                savingAnswer={savingAnswer}
                                setPulseAnimation={setPulseAnimation}
                                handleChange={onInputChange}
                            />
                        </div>
                    </div>
                    <div className={cx(styles.wrapperSide)}>
                        <button
                            onClick={() => setShowSidebar(!showSidebar)}
                            className={cx('jsIgnoreTarget', { [styles.wrapperSideBtnExpand]: !showSidebar }, { [styles.wrapperSideBtnExpandPulse]: pulseAnimation }, { [styles.wrapperSideBtnHide]: showSidebar })}>
                            <AlertCircle />
                            <span>Show instructions</span>
                        </button>

                        <div className={cx(styles.wrapperSideContent, { [styles.wrapperSideVisible]: showSidebar })}>
                            <h1 className='u-text-white'><AlertCircle className={styles.wrapperSideIcon} /> {question.emulator.name}</h1>
                            <h4>Instructions:</h4>
                            <ul className={cx('u-m-base-3', styles.instructionsText)}>
                                {question.instructions.map((txt, i) => <li key={`ins-${i}`}>{txt}</li>)}
                            </ul>
                            {
                                question.copyTexts && question.copyTexts.length > 0 && (
                                    <ul className={styles.copyList}>
                                        {question.copyTexts.map((el, i) => {
                                            return <li key={`text-${i}`}>
                                                <div className={styles.copyListLabel}>{el.label}:</div>
                                                <div>
                                                    {el.text}
                                                    <CopyToClipboard text={el.text} onCopy={() => setCopyText(i)}>
                                                        <button className='u-m-left-1 jsIgnoreTarget'>{copyText === i ? "Copied!" : <Copy size="16" />}</button>
                                                    </CopyToClipboard>
                                                </div>
                                            </li>
                                        })}
                                    </ul>
                                )
                            }
                            <button
                                disabled={qData && !qData.userAnswer}
                                onClick={() => onCheckAnswer(qData)}
                                className='btn btn-white u-m-top-3'>
                                {savingAnswer ? 'Saving...' : 'Submit answer'}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </animated.div>
    );
}

export default QuestionWrapper;