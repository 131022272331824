import React from 'react';
import ImageFadeIn from 'react-image-fade-in';

import logo from '../../images/republic-logo-white.png';
import styles from './Header.module.scss';

const Header = ({ currentUser }) => {
    return (
        <header className={styles.header}>
            <ImageFadeIn width={152}  alt="Republic Logo" src={logo} />
            {
                currentUser && (
                  <p>{currentUser.name}</p>
                )
            }
        </header>
    );
}

export default Header;
