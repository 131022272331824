import { SKILLSETS, FINAL_STEP } from '../../utils';

export const q5 = {
    orderid: 5,
    emulator: { type: 'excel', name: 'Microsoft Excel' },
    skillset: SKILLSETS.excel,
    instructions: ['Add a second worksheet to the current spreadsheet.'],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'NewSheet',
                    nextId: 2,
                },
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/excel/q5/t5-start.png',
                    style: { t: 0, l: 0, w: 980, h: 552 }
                },
            ],
            buttons: [
                {
                    type: 'content',
                    name: 'NewSheet',
                    style: { t: 533, l: 165, w: 18, h: 18, radius: '50%' },
                },
                {
                    type: 'content',
                    name: 'ColumnA',
                    style: { t: 41, l: 25, w: 135, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnB',
                    style: { t: 41, l: 160, w: 98, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnC',
                    style: { t: 41, l: 258, w: 100, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnD',
                    style: { t: 41, l: 358, w: 105, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnE',
                    style: { t: 41, l: 464, w: 65, h: 18, cursor: 's-resize' },
                },
                {
                    type: 'content',
                    name: 'ColumnF',
                    style: { t: 41, l: 529, w: 65, h: 18, cursor: 's-resize' },
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    src: './simulator/excel/q5/t5-2.png',
                    style: { t: 0, l: 0, w: 980, h: 552 }
                },
            ],
        },
    ],
};