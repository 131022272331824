let endpoint = "https://api.cuttingedge.app";

const hostname = window && window.location && window.location.hostname;

if (hostname.includes('onrender') && !hostname.includes('staging')) {
    endpoint = "https://cutting-edge-production-api.onrender.com";
}

if (hostname === "localhost" || hostname.includes("staging")) {
    endpoint = "https://staging-api.cuttingedge.app";
} 

export const API_ENDPOINT = endpoint;

