import React, { useState, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ToolBarDropdown from '../../ToolBarDropdown';
import { Menu, Item, useContextMenu, Separator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import TABBAR_ITEMS from './Elements/tabbar_items';

import AppFrameImg from '../../../images/ppt/app_frame.png';
import ImageFadeIn from 'react-image-fade-in';

import cx from 'classnames';
import styles from './PowerPoint.module.scss';
import { elStyle } from '../../../constants/utils';


const PowerPoint = ({ step, handleClick, handleForm, handleChange }) => {

    const [activeToolbar, setActiveToolbar] = useState(1);

    const [currentSlide, setCurrentSlide] = useState(0);
    const [checkPrevSelection, setCheckPrevSelection] = useState(null);

    const { show } = useContextMenu();
    const displayContextMenu = (e, buttonId) => {
        show(e, {
            id: buttonId,
            position: {
                x: e.pageX,
                y: e.pageY - 50,
            },
        });
    }

    const onElClick = (btn, trackPrevValues) => {
        handleClick(btn, trackPrevValues);
    }

    const { register, handleSubmit } = useForm();


    useEffect(() => {
        if (step && step.set_active_slide) {
            setCurrentSlide(step.set_active_slide);
        }
    }, [step])

    useEffect(() => {
        step.has_prev_selection &&
            step.has_prev_selection.type === 'selected_slide' &&
            setCheckPrevSelection(currentSlide);
    }, [currentSlide, step.has_prev_selection]);



    return (
        <div className={styles.app}>
            <ImageFadeIn className={styles.appBg} src={AppFrameImg} opacityTransition={0.1} alt='Appframe' />
            <div className='toolbar'>
                {
                    TABBAR_ITEMS.map((tab, i) => {
                        return (
                            <Fragment key={`tab-${i}`}>
                                <button
                                    name={tab.name}
                                    onClick={() => { i === 0 ? onElClick(tab) : setActiveToolbar(i) }}
                                    className={cx('img-btn')}
                                    style={elStyle(tab)}
                                ></button>
                                <img
                                    key={`toolbar-${i}`}
                                    className={cx('toolbar-bg', { 'u-display-none': i !== activeToolbar })}
                                    src={tab.image}
                                    alt=''
                                />
                            </Fragment>
                        )
                    })
                }
                {
                    <div className='toolbar-content'>
                        {
                            TABBAR_ITEMS[activeToolbar].mainButtons && TABBAR_ITEMS[activeToolbar].mainButtons.length > 0 && TABBAR_ITEMS[activeToolbar].mainButtons.map((button, i) => {
                                return (
                                    <Fragment key={`toolbarbtn-${i}}`}>
                                        {button.dropdown ? (
                                            <ToolBarDropdown
                                                item={button}
                                                classNames='img-btn'
                                                checkPrevSelection={checkPrevSelection}
                                                onElClick={onElClick}
                                                styles={elStyle(button)}
                                            />
                                        ) : <button
                                            name={button.name}
                                            onClick={() => onElClick(button, checkPrevSelection)}
                                            className={button.name === 'FakeButton' ? 'abs-btn-no-hover' : 'img-btn'}
                                            style={elStyle(button)}></button>
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </div>
                }
            </div>

            {step.buttons && step.buttons.length > 0 && step.buttons.filter(b => b.type === 'bottomBar').map((button, i) => {
                return <button
                    key={`step-btn-${i}`}
                    name={button.name}
                    onClick={() => onElClick(button)}
                    className={cx('img-btn')}
                    style={elStyle(button)}
                ></button>
            })}


            {step.form && step.form.type === 'content' && step.form.inputs && step.form.inputs.length > 0 && (
                <form
                    onSubmit={handleSubmit(data => handleForm(data, step.form))}
                    className='form-area'
                    name={step.form.type}
                    style={elStyle(step.form)}
                >
                    {step.form.inputs.map((input, i) => (
                        <input
                            key={`form-input-${i}`}
                            type="text"
                            style={elStyle(input)}
                            name={input.name}
                            {...register(input.name, {
                                onChange: (e) => input.hasBlur && handleChange(e.target.value),
                                onBlur: (e) => {
                                    if (input.hasBlur) {
                                        if (!e.relatedTarget || (e.relatedTarget && !e.relatedTarget.classList.contains('jsIgnoreTarget'))) {
                                            handleSubmit(data => handleForm(data, step.form))();
                                        }
                                    }
                                }
                            })}
                            className='abs-pos'
                            defaultValue={input.initial_value}
                            onKeyDown={(e) => {
                                if (input.hasBlur && e.keyCode === 13) {
                                    e.target.blur();
                                }
                            }}
                        />
                    ))}
                </form>
            )}

            <div className={styles.pptContentArea}>
                <div className={styles.pptContentArealeft}>
                    {step.images && step.images.filter(slide => slide.type === 'thumb').map((slide, i) => {
                        return (
                            <div className={styles.slideThumb} key={`thumb-${i}`}>
                                <span className={styles.slideThumbNo}> {i + 1}</span> <img className='abs-pos' style={elStyle(slide)} src={slide.src} alt='' />
                                <button
                                    className={cx('abs-pos', { [styles.borderHighlight]: currentSlide === i })}
                                    style={elStyle(slide)}
                                    onClick={(e) => setCurrentSlide(i)}></button>
                            </div>
                        );
                    })}
                    {step.buttons && step.buttons.length > 0 && step.buttons.filter(btn => btn.type === 'content').map((button, i) => {
                        return (
                            <Fragment key={`content-btn-${i}`}>
                                <button
                                    name={button.name}
                                    onClick={() => onElClick(button)}
                                    className={cx('abs-link-no-hover', styles[button.linkHoverClass])}
                                    style={elStyle(button)}
                                    {...(button.contextMenu && { onContextMenu: (e) => displayContextMenu(e, button.contextMenu.id) })}
                                ></button>
                                {button.contextMenu && button.contextMenu.buttons && (
                                    <Menu id={button.contextMenu.id}>
                                        {button.contextMenu.buttons.map((button, i) => {
                                            return (
                                                <Fragment key={`ctx-${i}`}>
                                                    <Item onClick={() => onElClick(button)}>{button.label}</Item>
                                                    {button.showSeparator && <Separator />}
                                                </Fragment>
                                            )
                                        })}
                                    </Menu>
                                )}
                            </Fragment>
                        );
                    })}
                </div>
                <div className={styles.pptContentAreaMain}>
                    {step.images && step.images.filter(slide => slide.type === 'slide').map((slide, i) => {
                        return i === currentSlide ? (
                            <div style={elStyle(slide)} className='abs-pos' key={`start_images-${i}`}>
                                <ImageFadeIn opacityTransition={0} src={slide.src} alt='' />
                            </div>) : null;
                    })}
                </div>
            </div>

        </div>
    );
}

export default PowerPoint;