import React, { useContext } from 'react';
import { navigate } from "@reach/router";
import { AuthContext } from '../../services/auth/AuthProvider';
import { useApolloClient } from '@apollo/client';
import ls from 'local-storage';

const LogoutButton = ({ children, classes }) => {
    
    const client =  useApolloClient();
    const { setCurrentUser } = useContext(AuthContext);

    return (
        <button
            className={classes}
            onClick={() => {
                ls.clear();
                setCurrentUser(null);
                client.clearStore();
                navigate('/');
            }}
        >
            {children}
        </button>
    );
}

export default LogoutButton;
