import { q1 } from './q1';
import { q2 } from './q2';
import { q3 } from './q3';
import { q4 } from './q4';
import { q5 } from './q5';
import { q6 } from './q6';
import { q7 } from './q7';

export const chromeQuestions = [
    q1, q2, q3, q4, q5, q6, q7
]