import { SKILLSETS, FINAL_STEP } from '../../utils';

export const pq2 = {
    orderid: 2,
    emulator: { type: 'powerpoint', name: 'Microsoft PowerPoint' },
    skillset: SKILLSETS.powerpoint,
    instructions: ["Without using the keyboard delete key, delete Slide 2 from the current slide deck."],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: 'DeleteSlide',
                    nextId: 2,
                },
            ],
            buttons: [
                {
                    type: 'content',
                    name: 'SlideOptions',
                    linkHoverClass: 'q3BorderHover',
                    style: { t: 108, l: 40, w: 99, h: 58 },
                    contextMenu: {
                        id: 'SlideOptionsContextMenu',
                        buttons: [
                            {
                                name: 'CutSlide',
                                label: 'Cut'
                            },
                            {
                                name: 'CopySlide',
                                label: 'Copy Slide'
                            },
                            {
                                name: 'PasteOptions',
                                label: 'Paste Options',
                                showSeparator: true,
                            },
                            {
                                name: 'NewSlide',
                                label: 'New Slide'
                            },
                            {
                                name: 'DuplicateSlide',
                                label: 'Duplicate Slide'
                            },
                            {
                                name: 'DeleteSlide',
                                label: 'Delete Slide'
                            },
                            {
                                name: 'AddSection',
                                label: 'Add Section',
                                showSeparator: true,
                            },
                            {
                                name: 'Layout',
                                label: 'Layout'
                            },
                            {
                                name: 'ResetSlide',
                                label: 'Reset Slide'
                            },
                            {
                                name: 'FormatBackground',
                                label: 'Format Background',
                                showSeparator: true,
                            },
                            {
                                name: 'PhotoAlbum',
                                label: 'Photo Album'
                            },
                            {
                                name: 'HideSlide',
                                label: 'Hide Slide',
                                showSeparator: true
                            },
                            {
                                name: 'NewComment',
                                label: 'New Comment'
                            },
                        ],
                    }
                },
                {
                    type: 'content',
                    name: 'SlideOptionsWrong',
                    linkHoverClass: 'q3BorderHover',
                    style: { t: 0, l: 40, w: 99, h: 58 },
                    contextMenu: {
                        id: 'SlideOptionsContextMenuWrong',
                        buttons: [
                            {
                                name: 'CutSlide',
                                label: 'Cut'
                            },
                            {
                                name: 'CopySlide',
                                label: 'Copy Slide'
                            },
                            {
                                name: 'PasteOptions',
                                label: 'Paste Options',
                                showSeparator: true,
                            },
                            {
                                name: 'NewSlide',
                                label: 'New Slide'
                            },
                            {
                                name: 'DuplicateSlide',
                                label: 'Duplicate Slide'
                            },
                            {
                                name: 'DeleteSlideWrong',
                                label: 'Delete Slide'
                            },
                            {
                                name: 'AddSection',
                                label: 'Add Section',
                                showSeparator: true,
                            },
                            {
                                name: 'Layout',
                                label: 'Layout'
                            },
                            {
                                name: 'ResetSlide',
                                label: 'Reset Slide'
                            },
                            {
                                name: 'FormatBackground',
                                label: 'Format Background',
                                showSeparator: true,
                            },
                            {
                                name: 'PhotoAlbum',
                                label: 'Photo Album'
                            },
                            {
                                name: 'HideSlide',
                                label: 'Hide Slide',
                                showSeparator: true
                            },
                            {
                                name: 'NewComment',
                                label: 'New Comment'
                            },
                        ],
                    }
                },
            ],
            images: [
                {
                    src: './simulator/ppt/shared/start_image_slide_1.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/start_image_slide_2.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ]
        },
        {
            id: 2,
            name: FINAL_STEP,
            images: [
                {
                    src: './simulator/ppt/shared/start_image_slide_1.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                }
            ]
        }
    ],
};