import { SKILLSETS, FINAL_STEP } from '../../utils';
import { BROWSER_BUTTONS } from '../../../components/Emulators/Chrome/button_names';

export const q4 = {
    orderid: 4,
    emulator: { type: 'chrome', name: 'Chrome' },
    skillset: SKILLSETS.chrome,
    instructions: ['Using the address bar in Google Chrome, navigate to the Republic Services website.'],
    copyTexts: [
        { label: 'The URL is', text: 'republicservices.com' }
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            buttons: [
                {
                    type: 'appbar',
                    name: BROWSER_BUTTONS.newTab,
                    style: { t: 12, l: 246, w: 22, h: 22, radius: '50%' },
                },
            ],
            routes: [
                {
                    name: 'ENTER',
                    nextId: 2,
                }
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q4/browser-t4-1.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
            ],
            form: {
                style: { t: 44, l: 135, w: 700, h: 26 },
                name: 'ENTER',
                type: 'content',
                inputs: [
                    {
                        check_value: 'republicservices.com',
                        placeholder: 'Search or type a URL',
                        onBlurHandler: false,
                        name: 'addressinput',
                        style: { t: 0, l: 0, w: 700, h: 26 },
                    },
                ],
            }
        },

        {
            name: FINAL_STEP,
            id: 2,
            images: [
                {
                    type: 'content',
                    src: './simulator/chrome/q4/browser-t4-2.jpg',
                    style: { t: 0, l: 0, w: 980, h: 728 }
                },
            ]
        },
    ],
};