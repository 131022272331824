import { SKILLSETS, FINAL_STEP } from '../../utils';
import { SLIDE_LAYOUT_BUTTONS, SLIDE_BUTTONS } from '../../../components/Emulators/constants';

export const pq3 = {
    orderid: 3,
    emulator: { type: 'powerpoint', name: 'Microsoft PowerPoint' },
    skillset: SKILLSETS.powerpoint,
    instructions: [
        'Add a new Slide that uses the template "Title Slide" in between Slides 1 and 2. This should be done without needing to move the newly added slide.' ,
        'Hint: Slides are added after the currently selected slide'
    ],
    attempts: 2,
    steps: [
        {
            id: 1,
            has_prev_selection: {
                type: 'selected_slide',
                value: 0
            },
            routes: [
                {
                    name: SLIDE_BUTTONS.titleSlide,
                    nextId: 2,
                },
                {
                    name: SLIDE_BUTTONS.newSlide,
                    nextId: 3,
                },
            ],
            images: [
                {
                    src: './simulator/ppt/shared/start_image_slide_1.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/start_image_slide_2.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ],
        },
        {
            id: 3,
            set_active_slide: 1,
            has_prev_selection: {
                type: 'selected_slide',
                value: 1
            },
            routes: [
                {
                    name: SLIDE_LAYOUT_BUTTONS.layoutTitleSlide,
                    nextId: 2,
                },
            ],
            images: [
                {
                    src: './simulator/ppt/shared/start_image_slide_1.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/q3/thumb_slide_3.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/q3/wrong_slide_3.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/start_image_slide_2.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ]
        },
        {
            name: FINAL_STEP,
            id: 2,
            set_active_slide: 1,
            images: [
                {
                    src: './simulator/ppt/shared/start_image_slide_1.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_1.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/q3/thumb_slide_3.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
                {
                    src: './simulator/ppt/q3/slide_result.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/start_image_slide_2.png',
                    type: 'slide',
                    style: { t: -5, l: 167, w: 789, h: 452 }
                },
                {
                    src: './simulator/ppt/shared/thumb_slide_2.png',
                    type: 'thumb',
                    style: { t: 0, l: 20, w: 99, h: 58 }
                },
            ],
        },
    ],
};