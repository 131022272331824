import React, { useState, Fragment } from 'react';
import AppBarDropdown from '../../AppBarDropdown';
import { useForm } from 'react-hook-form';

import WINDOW_ITEMS from './Elements/window_items';
import TABBAR_ITEMS from './Elements/tabbar_items';
import SIDEBAR_ITEMS from './Elements/sidebar_items';

import AppFrameImg from '../../../images/outlook/app_frame.png';
import ImageFadeIn from 'react-image-fade-in';
import { useDoubleClick } from '@zattoo/use-double-click';

import { Menu, Item, useContextMenu, Submenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import cx from 'classnames';
import styles from './Outlook.module.scss';
import { elStyle } from '../../../constants/utils';

const Outlook = ({ step, handleClick, handleForm }) => {

    const [activeToolbar, setActiveToolbar] = useState(1);

    const onElClick = (btn, trackPrevValues) => {
        handleClick(btn, trackPrevValues);
    }

    const { register, handleSubmit } = useForm();

    const { show } = useContextMenu();
    const displayContextMenu = (e, buttonId) => {
        show(e, {
            id: buttonId,
            position: {
                x: e.pageX,
                y: e.pageY - 70,
            },
        });
    }

    const ButtonMessage = ({ button }) => {
        const hybridClick = useDoubleClick(
            () => { onElClick(button) },
            () => {
                if (button.singleClickName) {
                    const btn = { ...button, name: button.singleClickName };
                    onElClick(btn);
                }
            },
        );
        return (
            <button
                name={button.name}
                onClick={button.hasDoubleClick ? hybridClick : () => onElClick(button)}
                className='img-btn'
                style={elStyle(button)}
                {...(button.contextMenu && { onContextMenu: (e) => displayContextMenu(e, button.contextMenu.id) })}
            ></button>
        )
    }

    const StepForm = ({ step }) => {
        return (
            <form
                onSubmit={handleSubmit(data => handleForm(data, step.form))}
                className='form-area'
                style={elStyle(step.form)}
            >
                {step.form.inputs.map((input, i) => {
                    return input.type === 'textarea' ?
                        <textarea
                            key={`form-input-${i}`}
                            style={elStyle(input)}
                            name={input.name}
                            {...register(input.name)}
                            className='abs-pos'
                        ></textarea> :
                        <input
                            key={`form-input-${i}`}
                            type="text"
                            {...register(input.name)}
                            style={elStyle(input)}
                            name={input.name}
                            className='abs-pos'
                        />
                })}
                {step.form.button && <button type='submit' name={step.form.button.name} className='img-btn'
                    style={elStyle(step.form.button)}></button>}
            </form>
        );
    }

    return (
        <div className={styles.app}>
            <ImageFadeIn className={styles.appBg} src={AppFrameImg} opacityTransition={0.1} alt='Appframe' />
            <ul className='appbar-links'>
                {WINDOW_ITEMS.map((button, i) => {
                    return (
                        <li key={`window-link-${i}`} className='appbar-link' >
                            <button
                                name={button.name}
                                onClick={() => onElClick(button)}
                                className={cx(styles.appWindowBtn, 'img-btn')}
                                style={elStyle(button)}
                            ></button>
                        </li>
                    )
                })}
            </ul>
            <div className='toolbar'>
                {
                    TABBAR_ITEMS.map((tab, i) => {
                        return (
                            <Fragment key={`tab-${i}`}>
                                <button
                                    name={tab.name}
                                    onClick={() => { i === 0 ? onElClick(tab) : setActiveToolbar(i) }}
                                    className='img-btn'
                                    style={elStyle(tab)}
                                ></button>
                                <img
                                    key={`toolbar-${i}`}
                                    className={cx('toolbar-bg toolbar-bg-outlook', { 'u-display-none': i !== activeToolbar })}
                                    src={tab.image}
                                    alt=''
                                />
                            </Fragment>
                        )
                    })
                }

                <div className='toolbar-content'>
                    {
                        TABBAR_ITEMS[activeToolbar].mainButtons && TABBAR_ITEMS[activeToolbar].mainButtons.length > 0 && TABBAR_ITEMS[activeToolbar].mainButtons.map((button, i) => {
                            return (
                                <Fragment key={`toolbarbtn-${i}}`}>
                                    {button.mainButtons ? (
                                        <AppBarDropdown
                                            item={button}
                                            classNames='img-btn'
                                            onElClick={onElClick}
                                            styles={elStyle(button)}
                                        />
                                    ) : <button
                                        name={button.name}
                                        onClick={() => onElClick(button)}
                                        className={button.name === 'FakeButton' ? 'abs-btn-no-hover' : 'img-btn'}
                                        style={elStyle(button)}></button>
                                    }
                                </Fragment>
                            )
                        })
                    }
                </div>
            </div>

            <div>
                {SIDEBAR_ITEMS.map((button, i) => (
                    <button
                        key={`sidebar-btn-${i}`}
                        name={button.name}
                        onClick={() => onElClick(button)}
                        className='img-btn'
                        style={elStyle(button)}></button>
                ))}
            </div>


            {step.images && step.images.filter(img => img.type === 'fullScreen').map((image, i) => (
                <div className={cx('abs-pos', styles.fullScreenMsg, styles[image.kls])} style={elStyle(image)} key={`full_screen-${i}`}>
                    <img style={elStyle(image)} src={image.src} alt='' />
                </div>
            ))}


            {step.buttons && step.buttons.filter(b => b.type === 'fullScreenBtn').map((button, i) => {
                return (
                    <Fragment key={`step-btn-fx-${i}`}> {
                        button.dropdown ?
                            <AppBarDropdown
                                item={button.dropdown}
                                classNames='img-btn'
                                onElClick={onElClick}
                                styles={elStyle(button.dropdown)}
                            /> :
                            <button
                                name={button.name}
                                onClick={() => handleClick(button)}
                                className='img-btn'
                                style={elStyle(button)}
                            ></button>
                    }
                    </Fragment>)
            })}

            {step.form && step.form.type === 'fullScreenForm' && step.form.inputs && step.form.inputs.length > 0 && (
                <StepForm step={step} />
            )}

            <div className={styles.appContentArea}>
                {step.images && step.images.filter(img => img.type === 'content').map((image, i) => (
                    <div className={cx(styles[image.kls])} key={`start_images-${i}`}>
                        <img style={elStyle(image)} src={image.src} alt='' />
                    </div>
                ))}

                {step.buttons && step.buttons.filter(b => b.type === 'msgThumb').map((button, i) => {
                    return (
                        <Fragment key={`step-btn-${i}`} >
                            <ButtonMessage button={button} i={i} />
                            {button.contextMenu && button.contextMenu.buttons && (
                                <Menu className={cx(styles[button.contextMenu.kls])} id={button.contextMenu.id}>
                                    {button.contextMenu.buttons.map((button, i) => {
                                        return (
                                            <Fragment key={`ctx-${i}`}>
                                                {button.submenu ? (
                                                    <Submenu className='outLookSubmenu' label=''>
                                                        {button.submenu.map((button, i) => (
                                                            <Item key={`ctx-sub-${i}`} style={{ marginTop: `${button.top}px` }} className='outLookSubItem' onClick={() => onElClick(button)}></Item>
                                                        ))}
                                                    </Submenu>
                                                ) : (
                                                    <Item style={{ marginTop: `${button.top}px` }} className='outLookItem' onClick={() => onElClick(button)}></Item>
                                                )}
                                            </Fragment>
                                        )
                                    })}
                                </Menu>
                            )}
                        </Fragment>
                    )
                })}

                {step.form && step.form.type === 'content' && step.form.inputs && step.form.inputs.length > 0 && (
                    <StepForm step={step} />
                )}

                {step.buttons && step.buttons.filter(b => b.type === 'content').map((button, i) => {
                    return <button
                        key={`cnt-btn-fx-${i}`}
                        name={button.name}
                        onClick={() => handleClick(button)}
                        className='img-btn'
                        style={elStyle(button)}
                    ></button>
                })}
            </div>
        </div>
    );
}

export default Outlook;