import { SKILLSETS, FINAL_STEP } from '../../utils';
import { HOME_BUTTONS, BULLETS_BUTTONS } from '../../../components/Emulators/Word/button_names';

export const q4 = {
    orderid: 4,
    emulator: { type: 'word', name: 'Microsoft Word' },
    skillset: SKILLSETS.word,
    instructions: ['Turn the currently selected text into a bullet list that uses a "tick" as the bullet character.'],
    attempts: 2,
    steps: [
        {
            id: 1,
            routes: [
                {
                    name: BULLETS_BUTTONS.tick,
                    nextId: 3,
                },
                {
                    name: HOME_BUTTONS.bullets,
                    nextId: 2
                }
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/word/q4/start_image.png',
                    style: { t: 100, l: 100, w: 606, h: 352 }
                },
            ]
        },
        {
            id: 2,
            name: HOME_BUTTONS.bullets,
            routes: [
                {
                    name: BULLETS_BUTTONS.tick,
                    nextId: 3,
                }
            ],
            images: [
                {
                    type: 'content',
                    src: './simulator/word/q4/t4-disc-bullet.png',
                    style: { t: 100, l: 100, w: 606, h: 352 }
                },
            ],
       
        },
        {
            name: FINAL_STEP,
            id: 3,
            images: [
                {
                    type: 'content',
                    src: './simulator/word/q4/t4-result.png',
                    style: { t: 100, l: 100, w: 606, h: 352 }
                }
            ]
        },
    ],
};